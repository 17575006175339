import axios from "axios";
import { site } from "../../api/url_helper";

const DownloadExportExcel = async (type, lan, list_id) => {
  try {
    const response = await axios.post(
      `${site}/api/users/dashboard/excel/export-data`,
      { type, lan, list_id },
      { withCredentials: true }
    );

    if (response && response.data) {
      try {
        const result = await axios.get(`${site}/${response.data.file}`, {
          responseType: "blob",
        });

        const urlObject = new URL(`${site}/${response.data.file}`);

        // Lấy đường dẫn pathname của URL, được chia thành các phần bởi dấu '/'
        const pathParts = urlObject.pathname.split("/");

        // Lấy phần cuối cùng của mảng pathParts, đó là tên file với phần đuôi
        const fileNameWithExtension = pathParts[pathParts.length - 1];
        const url = window.URL.createObjectURL(new Blob([result.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileNameWithExtension);
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export default DownloadExportExcel;
