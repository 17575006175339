import React, { useState, useEffect, useContext } from 'react';
import img from '../../assets/images/login/header-img.png';
import actived from '../../assets/images/active/check.png';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import { useTranslation } from 'react-i18next';

import { DataContext } from '../../contexts/data/DataProvider';

import * as URL from '../../api/url_helper';
import axios from 'axios';

// //redux
// import { getKeyLicenseId } from "store/actions"

function License() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { t } = useTranslation();

  // const [key, setKey] = useState('');
  const [label, setLabel] = useState('');
  const [timeLeft, setTimeLeft] = useState(3);
  const [start, setStart] = useState(false);
  const [dataKey, setDataKey] = useState(null);
  const { keyLicense, setKeyLicense } = useContext(DataContext);

  // step
  const [activeIndex, setActiveIndex] = useState(0);
  const itemRenderer = (item, itemIndex) => {
    const isActiveItem = activeIndex === itemIndex;
    const backgroundColor = isActiveItem
      ? 'var(--primary-color)'
      : 'var(--surface-b)';
    const textColor = isActiveItem
      ? 'var(--surface-b)'
      : 'var(--text-color-secondary)';

    return (
      <div className='flex flex-column'>
        <div className='flex flex-column'>
          <span
            className='inline-flex align-items-center justify-content-center align-items-center border-circle border-primary border-1 h-3rem w-3rem z-1 cursor-pointer'
            style={{
              backgroundColor: backgroundColor,
              color: textColor,
              marginTop: '-25px',
            }}
            onClick={() => setActiveIndex(itemIndex)}>
            <i className={`${item.icon} text-xl`} />
          </span>
        </div>
      </div>
    );
  };

  const items = [
    {
      icon: activeIndex == 0 ? 'pi pi-spin pi-key' : 'pi pi-key',
      label: t('Enter key'),
      template: (item) => itemRenderer(item, 0),
    },
    {
      icon: activeIndex == 1 ? 'pi pi-spin pi-hourglass' : 'pi pi-hourglass',
      label: t('Enter key'),
      template: (item) => itemRenderer(item, 1),
    },
    {
      icon: activeIndex == 2 ? 'pi pi-check bx-tada' : 'pi pi-check',
      label: t('Enter key'),
      template: (item) => itemRenderer(item, 2),
    },
  ];

  const handleCheckKey = () => {
    if (keyLicense != '') {
      // stepperRef.current.nextCallback()
      setActiveIndex(activeIndex + 1);
      setTimeout(async () => {
        try {
          await axios
            .post(URL.VERIFY_KEY_LICENSE, { key_license_name: keyLicense })
            .then((response) => {
              setDataKey(response.data);
              // setKeyLicense(key);
            });
        } catch (e) {
          setLabel('Error ', e);
        }
      }, 2000);
    } else {
      setLabel(t('Please enter key'));
    }
  };

  useEffect(() => {
    if (start) {
      if (timeLeft > 0) {
        const timerId = setTimeout(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearTimeout(timerId);
      }
      navigate(
        `/register?key_license_id=${dataKey.data.key_license_id}&key=${keyLicense}`,
        { state: { key: 123 } }
      );
      console.log('done');
    }
  }, [timeLeft, start]);

  useEffect(() => {
    if (dataKey != null) {
      if (!dataKey.success) {
        setLabel(t('Key is incorrect'));
        setActiveIndex(activeIndex - 1);
      } else {
        if (dataKey.data.active) {
          setActiveIndex(activeIndex - 1);
          setLabel(t('Key has been activated'));
        } else {
          setStart(true);
          setActiveIndex(activeIndex + 1);
        }
      }
    }
  }, [dataKey]);

  return (
    <div className='flex align-items-center justify-content-center'>
      <div
        className='w-12 md:w-8 lg:w-8 xl:w-6 mt-8'
        style={{ minWidth: '700px' }}>
        <div className='shadow-1 surface-50 border-round-sm'>
          <div className='flex justify-content-between bg-primary-subtle border-round-top-sm'>
            <div className='align-content-center ml-5'>
              <div className='font-bold text-2xl mb-2'>
                {t('Activate license')}
              </div>
              <div className=''>{t('Enter key to activate license')}</div>
            </div>
            <div>
              <img
                src={img}
                height={120}
                alt=''
              />
            </div>
          </div>

          <div className='p-3'>
            <div className='card'>
              <Steps
                model={items}
                activeIndex={activeIndex}
                readOnly={true}
                className='m-2 pt-4'
                pt={{
                  menu: 'pl-0',
                }}
              />

              {activeIndex == 0 && (
                <div>
                  <div className='flex flex-column h-12rem'>
                    <div className='surface-border border-round  flex-column flex justify-content-center align-items-center font-medium'>
                      <div className='card flex justify-content-center mt-5'>
                        <span className='p-float-label'>
                          <InputText
                            id='key'
                            value={keyLicense}
                            onChange={(e) => {
                              setKeyLicense(e.target.value);
                            }}
                            style={{ minWidth: '300px' }}
                          />
                          <label htmlFor='key'>{t('Enter key here')}</label>
                        </span>
                      </div>
                      <p
                        className='fw-bold'
                        style={{ color: 'red' }}>
                        {label}
                      </p>
                    </div>
                  </div>

                  <div className='flex pt-4 justify-content-center'>
                    <Button
                      label={t('Check')}
                      icon='pi pi-arrow-right'
                      iconPos='right'
                      // onClick={() => stepperRef.current.nextCallback()}
                      onClick={handleCheckKey}
                    />
                  </div>
                </div>
              )}

              {activeIndex == 1 && (
                <div>
                  <div className='flex flex-column h-12rem'>
                    <div className='surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium'>
                      <ProgressSpinner
                        style={{ width: '50px', height: '50px' }}
                        strokeWidth='8'
                        fill='var(--surface-ground)'
                        animationDuration='.5s'
                      />
                    </div>
                  </div>
                  <div className='flex pt-4 justify-content-between'>
                    <Button
                      label={t('Back')}
                      severity='secondary'
                      icon='pi pi-arrow-left'
                      // onClick={() => stepperRef.current.prevCallback()}
                    />
                  </div>
                </div>
              )}

              {activeIndex == 2 && (
                <div>
                  <div className='flex flex-column h-12rem'>
                    <div className='surface-border border-round surface-ground flex-column flex justify-content-center align-items-center font-medium'>
                      <img
                        src={actived}
                        height={60}
                        alt=''
                      />
                      <h3>{t('Check done')}</h3>
                      <p>
                        {t(
                          'You will be redirected to the following registration page'
                        )}{' '}
                        {timeLeft}
                      </p>
                    </div>
                  </div>
                  <div className='flex pt-4 justify-content-start'>
                    <Button
                      label={t('Back')}
                      severity='secondary'
                      icon='pi pi-arrow-left'
                      // onClick={() => stepperRef.current.prevCallback()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default License;
