import {
  GET_COUNTRY_ALL,
  GET_COUNTRY_ALL_SUCCESS,
  GET_COUNTRY_ALL_FAIL,
  GET_COUNTRY_ID,
  GET_COUNTRY_ID_SUCCESS,
  GET_COUNTRY_ID_FAIL,
  SET_COUNTRY,
  SET_COUNTRY_SUCCESS,
  SET_COUNTRY_FAIL,
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAIL,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  datas: [],
  dataId: [],
  data: {},
  loading: false,
};

const Country = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNTRY_ALL:
      return {
        ...state,
        loading: true,
      };
    case GET_COUNTRY_ALL_SUCCESS:
      return {
        ...state,
        datas: action.payload,
        loading: false,
      };

    case GET_COUNTRY_ALL_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_COUNTRY_ID:
      return {
        ...state,
        data: action.payload,
        loading: true,
      };

    case GET_COUNTRY_ID_SUCCESS:
      return {
        ...state,
        dataId: action.payload,
      };
    case GET_COUNTRY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case SET_COUNTRY:
      return {
        ...state,
        data: action.payload,
      };
    case SET_COUNTRY_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case SET_COUNTRY_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_COUNTRY:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_COUNTRY_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case DELETE_COUNTRY_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Country;
