export const GET_INTERN_BY_ID = "GET_INTERN_BY_ID";
export const GET_INTERN_BY_ID_SUCCESS = "GET_INTERN_BY_ID_SUCCESS";
export const GET_INTERN_BY_ID_FAIL = "GET_INTERN_BY_ID_FAIL";

export const GET_INTERN_BY_USER_ID = "GET_INTERN_BY_USER_ID";
export const GET_INTERN_BY_USER_ID_SUCCESS = "GET_INTERN_BY_USER_ID_SUCCESS";
export const GET_INTERN_BY_USER_ID_FAIL = "GET_INTERN_BY_USER_ID_FAIL";

export const GET_SEARCH_INTERN = "GET_SEARCH_INTERN";
export const GET_SEARCH_INTERN_SUCCESS = "GET_SEARCH_INTERN_SUCCESS";
export const GET_SEARCH_INTERN_FAIL = "GET_SEARCH_INTERN_FAIL";

export const GET_INTERN_BY_KEY_ID = "GET_INTERN_BY_KEY_ID";
export const GET_INTERN_BY_KEY_ID_SUCCESS = "GET_INTERN_BY_KEY_ID_SUCCESS";
export const GET_INTERN_BY_KEY_ID_FAIL = "GET_INTERN_BY_KEY_ID_FAIL";

export const SET_INTERN = "SET_INTERN";
export const SET_INTERN_SUCCESS = "SET_INTERN_SUCCESS";
export const SET_INTERN_FAIL = "SET_INTERN_FAIL";

export const UPDATE_INTERN = "UPDATE_INTERN";
export const UPDATE_INTERN_SUCCESS = "UPDATE_INTERN_SUCCESS";
export const UPDATE_INTERN_FAIL = "UPDATE_INTERN_FAIL";

export const DELETE_INTERN = "DELETE_INTERN";
export const DELETE_INTERN_SUCCESS = "DELETE_INTERN_SUCCESS";
export const DELETE_INTERN_FAIL = "DELETE_INTERN_FAIL";

export const GET_TIME_LINE = "GET_TIME_LINE";
export const GET_TIME_LINE_SUCCESS = "GET_TIME_LINE_SUCCESS";
export const GET_TIME_LINE_FAIL = "GET_TIME_LINE_FAIL";

export const GET_LIST_CARD = "GET_LIST_CARD";
export const GET_LIST_CARD_SUCCESS = "GET_LIST_CARD_SUCCESS";
export const GET_LIST_CARD_FAIL = "GET_LIST_CARD_FAIL";

export const SET_DATA_NULL = "SET_DATA_NULL";
