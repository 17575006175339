import React, { useState, useRef, useContext } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";
import { MultiSelect } from "primereact/multiselect";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { site } from "./../../api/url_helper";
import { ObjectType } from "../../components/Interface";
import { useEffect } from "react";

import { Toast } from "primereact/toast";
import {
  setAnnounced,
  getListTarget,
  getListIntern,
  getListSyndication,
  getListFactory,
  getListDispatch,
  getListInternTarget,
  setDataNull,
} from "../../store/announced/actions";
import axios from "axios";

import { DataContext } from "./../../contexts/data/DataProvider";
import { ProgressBar } from "primereact/progressbar";
const ModalCreateNotify = ({ visible, setVisible }) => {
  const {
    listTarget,
    listSyndication,
    listFactory,
    listDispatch,
    listIntern,
    listInternTarget,
    announcedInsert,
    loading,
    error,
    loadingInsert,
    errorInsert,
  } = useSelector(
    (state) => ({
      listTarget: state.Announced.listTarget,
      listSyndication: state.Announced.listSyndication,
      listFactory: state.Announced.listFactory,
      listDispatch: state.Announced.listDispatch,
      listIntern: state.Announced.listIntern,
      listInternTarget: state.Announced.listInternTarget,
      loading: state.Announced.loading,
      error: state.Announced.error,
      announcedInsert: state.Announced.announcedInsert,
      loadingInsert: state.Announced.loadingInsert,
      errorInsert: state.Announced.errorInsert,
    }),
    shallowEqual
  );
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const { selectedLang } = useContext(DataContext);
  const admin = JSON.parse(localStorage.getItem("object_type"));
  const [target, setTarget] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [title, setTitle] = useState("");

  const [listIdReceiver, setListIdReceiver] = useState([]);

  //th1: chọn tất cả (intern || syndication || factory || dispatch)
  const [targetSelected, setTargetSelected] = useState(t(target[0]));

  //th2: chọn receiver của factory || dispatch
  const [receiver, setReceiver] = useState([]);

  //th3 : option cụ thể của intern
  const [factoryTarget, setFactoryTarget] = useState([]);
  const [dispatchingTarget, setDispatchingTarget] = useState([]);
  const [syndicationTarget, setSyndicationTarget] = useState([]);

  const [idToGetIntern, setIdToGetIntern] = useState(null);

  const [text, setText] = useState("");

  console.log(listInternTarget?.data);
  console.log(listIdReceiver);

  useEffect(() => {
    switch (admin) {
      case "syndication":
        setTarget([t("Factory"), t("Dispatch"), t("Intern")]);
        setTargetSelected(t(target[0]));
        break;
      case "receiving_factory":
        setTarget([t("Syndication"), t("Dispatch"), t("Intern")]);
        setTargetSelected(t(target[0]));
        break;
      case "dispatching_company":
        setTarget([t("Syndication"), t("Factory"), t("Intern")]);
        setTargetSelected(t(target[0]));
        break;
      default:
        break;
    }
  }, [admin, selectedLang, visible]);

  const convertTarget = (target) => {
    if (target === t("Syndication")) {
      return "syndication";
    } else if (target === t("Factory")) {
      return "receiving_factory";
    } else if (target === t("Dispatch")) {
      return "dispatching_company";
    } else if (target === t("Intern")) {
      return "intern";
    }
  };

  // call api
  useEffect(() => {
    if (targetSelected && targetSelected !== t("Intern")) {
      dispatch(
        getListTarget({
          s_object_type: convertTarget(t(targetSelected)),
        })
      );
    } else if (targetSelected === t("Intern")) {
      dispatch(getListIntern({ s_object_type: "intern" }));
      dispatch(getListSyndication({ s_object_type: "syndication" }));
      dispatch(getListFactory({ s_object_type: "receiving_factory" }));
      dispatch(getListDispatch({ s_object_type: "dispatching_company" }));
    }
  }, [targetSelected]);

  useEffect(() => {
    if (visible) {
      setListIdReceiver(listTarget.map((item) => item.id));
    }
  }, [dispatch, listTarget, targetSelected, visible]);

  useEffect(() => {
    switch (t(targetSelected)) {
      case t("Intern"):
        setListIdReceiver([]);
        if (factoryTarget.length !== 0 || dispatchingTarget.length !== 0 || syndicationTarget.length !== 0) {
          let newListIdReceiver = [...factoryTarget, ...dispatchingTarget, ...syndicationTarget];
          setIdToGetIntern(newListIdReceiver.map((item) => item.id));
          break;
        }
        setListIdReceiver(listIntern.map((item) => item.id));
        break;
      case t("Factory"):
        setListIdReceiver([]);
        if (receiver.length !== 0) {
          setListIdReceiver(receiver.map((item) => item.id));
          break;
        }
        setListIdReceiver(listTarget.map((item) => item.id));
        break;
      case t("Dispatch"):
        setListIdReceiver([]);
        if (receiver.length !== 0) {
          setListIdReceiver(receiver.map((item) => item.id));
          break;
        }
        setListIdReceiver(listTarget.map((item) => item.id));
        break;
      case t("Syndication"):
        setListIdReceiver([]);
        if (receiver.length !== 0) {
          setListIdReceiver(receiver.map((item) => item.id));
          break;
        }
        setListIdReceiver(listTarget.map((item) => item.id));
        break;

      default:
        break;
    }
  }, [targetSelected, receiver, listTarget, listIntern, syndicationTarget, factoryTarget, dispatchingTarget, t]);

  useEffect(() => {
    if (
      (factoryTarget.length === 0 && dispatchingTarget.length === 0 && syndicationTarget.length === 0) ||
      receiver.length === 0
    ) {
      setText(` ${t("All")} ${targetSelected.toLowerCase()}`);
    }
    if (
      factoryTarget.length !== 0 ||
      dispatchingTarget.length !== 0 ||
      syndicationTarget.length !== 0 ||
      receiver.length !== 0
    ) {
      setText("");
    }
  }, [listInternTarget, receiver, targetSelected]);

  // upload file to server and get a link to display in the content
  const onTemplateSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const uploadFile = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);
        const response = await axios.post(`${site}/api/announced/test`, formData);
        let data = editorContent;
        if (selectedFile.type.includes("image")) {
          data += `<img src="${site}${response.data.url}" alt="" width="200px" />`;
        } else {
          data += `<a href="${site}${response.data.url}" download>${selectedFile.name}</a>`;
        }
        setEditorContent(data);
      } catch (error) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Upload file failed",
          life: 3000,
        });
      }
    }
  };

  useEffect(() => {
    if (selectedFile) {
      uploadFile();
    }
  }, [selectedFile]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  //reset form
  const resetForm = () => {
    setVisible(false);
    setReceiver([]);
    setEditorContent("");
    setTitle("");
    setFactoryTarget([]);
    setDispatchingTarget([]);
    setSyndicationTarget([]);
  };

  useEffect(() => {
    if (idToGetIntern?.length > 0) {
      dispatch(
        getListInternTarget({
          s_user_ids: idToGetIntern,
        })
      );
    }
  }, [idToGetIntern]);

  const handleSendNotify = () => {
    if (listInternTarget) {
      dispatch(
        setAnnounced({
          title: title,
          content: editorContent,
          receiver_list: listInternTarget.data.map((item) => item.id),
        })
      );
    }
    if (listIdReceiver.length > 0) {
      dispatch(
        setAnnounced({
          title: title,
          content: editorContent,
          receiver_list: listIdReceiver,
        })
      );
    }
  };

  useEffect(() => {
    if (announcedInsert) {
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: "Create notification success",
        life: 3000,
      });
      setTimeout(() => {
        dispatch(setDataNull());
        resetForm();
      }, 1000);
    }
  }, [announcedInsert]);

  useEffect(() => {
    if (errorInsert) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Create notification fail. ${errorInsert.message}`,
        life: 3000,
      });
    }
  }, [errorInsert]);

  // tools in editor
  const renderHeader = () => {
    return (
      <div>
        <span className="ql-formats">
          <select className="ql-font" aria-label="font"></select>
          <select className="ql-size" aria-label="font"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic" aria-label="Italic"></button>
          <button className="ql-underline" aria-label="Underline"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          <button className="ql-indent" value="-1"></button>
          <button className="ql-indent" value="+1"></button>
        </span>
        <span className="ql-formats">
          <select className="ql-color"></select>
          <select className="ql-background"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-script" value="sub"></button>
          <button className="ql-script" value="super"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-header" value="1"></button>
          <button className="ql-header" value="2"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-blockquote"></button>
          <button className="ql-code-block"></button>
          <select className="ql-align"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-link"></button>
          <button className="ql-formula"></button>
        </span>
      </div>
    );
  };

  // =================================================== footer dialog =================================================
  const footerDialog = (
    <div className="flex justify-content-between">
      <div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="*"
          multiple
          onChange={onTemplateSelect}
        />
        <Button
          label={t("Attachment")}
          icon="pi pi-paperclip"
          severity="secondary"
          style={{ height: "35px" }}
          iconPos="right"
          onClick={handleButtonClick}
        />
      </div>
      <div>
        <Button label={t("Cancel")} text severity="secondary" style={{ height: "35px" }} onClick={resetForm} />
        <Button
          icon="pi pi-send"
          label={t("Send")}
          severity="primary"
          iconPos="right"
          className="m-0"
          style={{ height: "35px" }}
          disabled={title === "" || editorContent === "" ? true : false}
          onClick={() => {
            handleSendNotify();
          }}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      header={t("Create notification")}
      visible={visible}
      style={{ width: "75vw" }}
      onHide={resetForm}
      footer={footerDialog}
    >
      <div className="flex flex-column gap-2">
        <Toast ref={toast} />

        {/* ================================================== Buttons navigate =============================================*/}
        <p>
          {t("Send to")}: {text}
        </p>
        <div className="flex gap-2">
          <div className="flex w-5 gap-2">
            {target.map((item) => (
              <Button
                key={item}
                label={item}
                severity="secondary"
                className={`${t(targetSelected) === t(item) ? "bg-primary text-white" : ""},flex-1, w-4`}
                style={{ height: "35px" }}
                onClick={() => {
                  setTargetSelected(t(item));
                  if (t(targetSelected) !== t(item)) {
                    setReceiver([]);
                    setFactoryTarget([]);
                    setDispatchingTarget([]);
                    setSyndicationTarget([]);
                  }
                }}
              />
            ))}
          </div>
          <div className="w-7 flex gap-2">
            <MultiSelect
              value={receiver}
              onChange={(e) => setReceiver(e.value)}
              options={listTarget}
              optionLabel="name_en"
              filter
              display="chip"
              style={{ height: "35px" }}
              placeholder={`${t("Select")} ${t(targetSelected.toLowerCase())}`}
              className={`${targetSelected === t("Intern") ? "hidden " : ""} flex-1 `}
            />
            <MultiSelect
              value={syndicationTarget}
              onChange={(e) => setSyndicationTarget(e.value)}
              options={listSyndication}
              optionLabel="name_en"
              filter
              style={{ height: "35px" }}
              display="chip"
              placeholder={t("Select syndication")}
              className={`w-4 flex-1 ${
                targetSelected !== t("Intern") || admin === ObjectType.SYNDICATION ? "hidden disable" : ""
              }`}
            />
            <MultiSelect
              value={factoryTarget}
              onChange={(e) => setFactoryTarget(e.value)}
              display="chip"
              options={listFactory}
              optionLabel="name_en"
              filter
              style={{ height: "35px" }}
              placeholder={t("Select factory")}
              className={`w-4 flex-1 ${
                targetSelected !== t("Intern") || admin === ObjectType.RECEIVING_FACTORY ? "hidden disable" : ""
              }`}
            />
            <MultiSelect
              value={dispatchingTarget}
              onChange={(e) => setDispatchingTarget(e.value)}
              options={listDispatch}
              optionLabel="name_en"
              filter
              style={{ height: "35px" }}
              display="chip"
              placeholder={t("Select dispatch")}
              className={`w-4 flex-1 ${
                targetSelected !== t("Intern") || admin === ObjectType.DISPATCHING_COMPANY ? "hidden disable" : ""
              }`}
            />
          </div>
        </div>

        {/* ================================================== title of notify =================================================*/}
        <div className="my-3 flex flex-column gap-2 ">
          <div className="flex flex-column gap-2">
            <label htmlFor="title">{t("Title")}</label>
            <InputText
              id="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-column gap-2">
            <label htmlFor="content">{t("Content")}</label>
            <Editor
              value={editorContent}
              onTextChange={(e) => setEditorContent(e.htmlValue)}
              headerTemplate={renderHeader()}
              style={{ height: "320px" }}
            />
          </div>
        </div>
      </div>
      {loadingInsert ? <ProgressBar mode="indeterminate" style={{ height: "5px" }}></ProgressBar> : <div></div>}
    </Dialog>
  );
};

export default ModalCreateNotify;
