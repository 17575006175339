import { all, fork } from 'redux-saga/effects';

import CountrySaga from './country/saga';
import StateSaga from './state/saga';
import DistrictSaga from './district/saga';
import WardSaga from './ward/saga';

import CareerSaga from './career/saga';
import UserSaga from './auth/saga';
import StatusSaga from './status/saga';
import ViolateTypeSaga from './violate_type/saga';
import SyndicationSaga from './syndication/saga';
import DispatchingCompanySaga from './dispatching_company/saga';
import ReceivingFactorySaga from './receiving_factory/saga';
import InternSaga from './intern/saga';
import AddressSaga from './address/saga';
import ViolateSaga from './violate/saga';
import AnnouncedSaga from './announced/saga';
import TicketSaga from './ticket/saga';
import DashboardSaga from './dashboard/saga';
import NotifySaga from './notify/saga';

export default function* rootSaga() {
  yield all([
    fork(CountrySaga),
    fork(StateSaga),
    fork(DistrictSaga),
    fork(WardSaga),
    fork(CareerSaga),
    fork(UserSaga),
    fork(StatusSaga),
    fork(ViolateTypeSaga),
    fork(SyndicationSaga),
    fork(DispatchingCompanySaga),
    fork(ReceivingFactorySaga),
    fork(InternSaga),
    fork(AddressSaga),
    fork(ViolateSaga),
    fork(AnnouncedSaga),
    fork(TicketSaga),
    fork(DashboardSaga),
    fork(NotifySaga),
  ]);
}
