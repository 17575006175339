import {
  GET_INTERN_BY_ID,
  GET_INTERN_BY_ID_SUCCESS,
  GET_INTERN_BY_ID_FAIL,
  GET_INTERN_BY_USER_ID,
  GET_INTERN_BY_USER_ID_SUCCESS,
  GET_INTERN_BY_USER_ID_FAIL,
  GET_INTERN_BY_KEY_ID,
  GET_INTERN_BY_KEY_ID_SUCCESS,
  GET_INTERN_BY_KEY_ID_FAIL,
  SET_INTERN,
  SET_INTERN_SUCCESS,
  SET_INTERN_FAIL,
  UPDATE_INTERN,
  UPDATE_INTERN_SUCCESS,
  UPDATE_INTERN_FAIL,
  DELETE_INTERN,
  DELETE_INTERN_SUCCESS,
  DELETE_INTERN_FAIL,
  SET_DATA_NULL,
  GET_SEARCH_INTERN,
  GET_SEARCH_INTERN_SUCCESS,
  GET_SEARCH_INTERN_FAIL,
  GET_TIME_LINE,
  GET_TIME_LINE_SUCCESS,
  GET_TIME_LINE_FAIL,
  GET_LIST_CARD,
  GET_LIST_CARD_SUCCESS,
  GET_LIST_CARD_FAIL,
} from "./actionTypes";

export const getInternById = (id) => ({
  type: GET_INTERN_BY_ID,
  payload: id,
});

export const getInternByIdSuccess = (data) => ({
  type: GET_INTERN_BY_ID_SUCCESS,
  payload: data,
});

export const getInternByIdFail = (error) => ({
  type: GET_INTERN_BY_ID_FAIL,
  payload: error,
});

export const getInternByUserId = (data) => ({
  type: GET_INTERN_BY_USER_ID,
  payload: data,
});

export const getInternByUserIdSuccess = (data) => ({
  type: GET_INTERN_BY_USER_ID_SUCCESS,
  payload: data,
});

export const getInternByUserIdFail = (error) => ({
  type: GET_INTERN_BY_USER_ID_FAIL,
  payload: error,
});

//
export const getSearchIntern = (query) => ({
  type: GET_SEARCH_INTERN,
  query,
});

export const getSearchInternSuccess = (data) => ({
  type: GET_SEARCH_INTERN_SUCCESS,
  payload: data,
});

export const getSearchInternFail = (error) => ({
  type: GET_SEARCH_INTERN_FAIL,
  payload: error,
});

//

export const getInternByKeyId = () => ({
  type: GET_INTERN_BY_KEY_ID,
});
export const getInternByKeyIdSuccess = (data) => ({
  type: GET_INTERN_BY_KEY_ID_SUCCESS,
  payload: data,
});
export const getInternByKeyIdFail = (error) => ({
  type: GET_INTERN_BY_KEY_ID_FAIL,
  payload: error,
});

export const setIntern = (values, file) => ({
  type: SET_INTERN,
  payload: { values, file },
});

export const setInternSuccess = (data) => ({
  type: SET_INTERN_SUCCESS,
  payload: data,
});

export const setInternFail = (error) => ({
  type: SET_INTERN_FAIL,
  payload: error,
});

export const updateIntern = (values, file) => ({
  type: UPDATE_INTERN,
  payload: { values, file },
});

export const updateInternSuccess = (data) => ({
  type: UPDATE_INTERN_SUCCESS,
  payload: data,
});

export const updateInternFail = (error) => ({
  type: UPDATE_INTERN_FAIL,
  payload: error,
});

export const deleteIntern = (id) => ({
  type: DELETE_INTERN,
  payload: id,
});

export const deleteInternSuccess = (data) => ({
  type: DELETE_INTERN_SUCCESS,
  payload: data,
});

export const deleteInternFail = (error) => ({
  type: DELETE_INTERN_FAIL,
  payload: error,
});

export const getTimeLine = (params) => ({
  type: GET_TIME_LINE,
  payload: params,
});

export const getTimeLineSuccess = (data) => ({
  type: GET_TIME_LINE_SUCCESS,
  payload: data,
});

export const getTimeLineFail = (error) => ({
  type: GET_TIME_LINE_FAIL,
  payload: error,
});

export const getListCard = (params) => ({
  type: GET_LIST_CARD,
  payload: params,
});

export const getListCardSuccess = (data) => ({
  type: GET_LIST_CARD_SUCCESS,
  payload: data,
});

export const getListCardFail = (error) => ({
  type: GET_LIST_CARD_FAIL,
  payload: error,
});

export const setDataNull = () => ({
  type: SET_DATA_NULL,
});
