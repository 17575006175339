
export const GET_STATUS_BY_ID = "GET_STATUS_BY_ID";
export const GET_STATUS_BY_ID_SUCCESS = "GET_STATUS_BY_ID_SUCCESS";
export const GET_STATUS_BY_ID_FAIL = "GET_STATUS_BY_ID_FAIL";

export const GET_STATUS_BY_USER_ID = "GET_STATUS_BY_USER_ID";
export const GET_STATUS_BY_USER_ID_SUCCESS = "GET_STATUS_BY_USER_ID_SUCCESS";
export const GET_STATUS_BY_USER_ID_FAIL = "GET_STATUS_BY_USER_ID_FAIL";

export const SET_STATUS = "SET_STATUS";
export const SET_STATUS_SUCCESS = "SET_STATUS_SUCCESS";
export const SET_STATUS_FAIL = "SET_STATUS_FAIL";

export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_FAIL = "UPDATE_STATUS_FAIL";

export const DELETE_STATUS = "DELETE_STATUS";
export const DELETE_STATUS_SUCCESS = "DELETE_STATUS_SUCCESS";
export const DELETE_STATUS_FAIL = "DELETE_STATUS_FAIL";

export const SET_DATA_NULL = 'SET_DATA_NULL';