import React from 'react';

const CircleImage = ({ src, size = 100, altImage = 'Image' }) => {
    const styles = {
        circle: {
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: '50%',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    };

    return (
        <div style={styles.circle}>
            <img src={src} alt={altImage} style={styles.image} />
        </div>
    );
};

export default CircleImage;
