import {
  GET_ANNOUNCED,
  GET_ANNOUNCED_SUCCESS,
  GET_ANNOUNCED_FAIL,
  SET_ANNOUNCED,
  SET_ANNOUNCED_SUCCESS,
  SET_ANNOUNCED_FAIL,
  GET_LIST_TARGET,
  GET_LIST_TARGET_SUCCESS,
  GET_LIST_TARGET_FAIL,
  GET_LIST_SYNDICATION,
  GET_LIST_SYNDICATION_SUCCESS,
  GET_LIST_SYNDICATION_FAIL,
  GET_LIST_FACTORY,
  GET_LIST_FACTORY_SUCCESS,
  GET_LIST_FACTORY_FAIL,
  GET_LIST_DISPATCH,
  GET_LIST_DISPATCH_SUCCESS,
  GET_LIST_DISPATCH_FAIL,
  GET_LIST_INTERN,
  GET_LIST_INTERN_SUCCESS,
  GET_LIST_INTERN_FAIL,
  GET_LIST_INTERN_TARGET,
  GET_LIST_INTERN_TARGET_SUCCESS,
  GET_LIST_INTERN_TARGET_FAIL,
  SET_DATA_NULL,
} from './actionTypes';

export const getAnnounced = (query) => ({
  type: GET_ANNOUNCED,
  payload: query,
});

export const getAnnouncedSuccess = (data) => ({
  type: GET_ANNOUNCED_SUCCESS,
  payload: data,
});

export const getAnnouncedFail = (error) => ({
  type: GET_ANNOUNCED_FAIL,
  payload: error,
});

export const setAnnounced = (data) => ({
  type: SET_ANNOUNCED,
  payload: data,
});

export const setAnnouncedSuccess = (data) => ({
  type: SET_ANNOUNCED_SUCCESS,
  payload: data,
});

export const setAnnouncedFail = (error) => ({
  type: SET_ANNOUNCED_FAIL,
  payload: error,
});

export const getListTarget = (query) => ({
  type: GET_LIST_TARGET,
  payload: query,
});

export const getListTargetSuccess = (data) => ({
  type: GET_LIST_TARGET_SUCCESS,
  payload: data,
});

export const getListTargetFail = (error) => ({
  type: GET_LIST_TARGET_FAIL,
  payload: error,
});

export const getListSyndication = (query) => ({
  type: GET_LIST_SYNDICATION,
  payload: query,
});

export const getListSyndicationSuccess = (data) => ({
  type: GET_LIST_SYNDICATION_SUCCESS,
  payload: data,
});

export const getListSyndicationFail = (error) => ({
  type: GET_LIST_SYNDICATION_FAIL,
  payload: error,
});

export const getListFactory = (query) => ({
  type: GET_LIST_FACTORY,
  payload: query,
});

export const getListFactorySuccess = (data) => ({
  type: GET_LIST_FACTORY_SUCCESS,
  payload: data,
});

export const getListFactoryFail = (error) => ({
  type: GET_LIST_FACTORY_FAIL,
  payload: error,
});

export const getListDispatch = (query) => ({
  type: GET_LIST_DISPATCH,
  payload: query,
});

export const getListDispatchSuccess = (data) => ({
  type: GET_LIST_DISPATCH_SUCCESS,
  payload: data,
});

export const getListDispatchFail = (error) => ({
  type: GET_LIST_DISPATCH_FAIL,
  payload: error,
});

export const getListIntern = (query) => ({
  type: GET_LIST_INTERN,
  payload: query,
});

export const getListInternSuccess = (data) => ({
  type: GET_LIST_INTERN_SUCCESS,
  payload: data,
});

export const getListInternFail = (error) => ({
  type: GET_LIST_INTERN_FAIL,
  payload: error,
});

export const getListInternTarget = (data) => ({
  type: GET_LIST_INTERN_TARGET,
  payload: data,
});

export const getListInternTargetSuccess = (data) => ({
  type: GET_LIST_INTERN_TARGET_SUCCESS,
  payload: data,
});

export const getListInternTargetFail = (error) => ({
  type: GET_LIST_INTERN_TARGET_FAIL,
  payload: error,
});

export const setDataNull = () => ({
  type: SET_DATA_NULL,
});
