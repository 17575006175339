import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Menu } from 'primereact/menu';
import { Avatar } from 'primereact/avatar';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

import { DataContext } from '../contexts/data/DataProvider';
import { useContext } from 'react';

const SelectLanguage = () => {
  const menuLanguage = useRef(null);
  const { t } = useTranslation();

  const { selectedLang, setSelectedLang } = useContext(DataContext);

  const languages = useMemo(
    () => [
      {
        label: 'Tiếng Việt',
        lang: 'vi',
        flag: '/images/flag/vn.png',
      },
      {
        label: '日本語',
        lang: 'ja',
        flag: '/images/flag/jp.png',
      },
      {
        label: 'English',
        lang: 'en',
        flag: '/images/flag/uk.png',
      },
      // Thêm ngôn ngữ khác ở đây
    ],
    []
  );

  const flagItems = useMemo(
    () =>
      languages.map((lang) => ({
        label: lang.label,
        icon: 'pi pi-image',
        command: () => {
          changeLanguageAction(lang.lang);
        },
        template: (item, option) => {
          return (
            <div
              className='flex align-items-center gap-2 cursor-pointer hover:surface-100 py-2 px-4'
              onClick={(event) => {
                event.stopPropagation(); // Ngăn menu không chuyển đổi khi click
                changeLanguageAction(lang.lang);
                menuLanguage.current?.toggle(event);
              }}>
              <img
                src={lang.flag}
                alt={lang.label}
                width='22px'
              />
              <span>{lang.label}</span>
            </div>
          );
        },
      })),
    [languages]
  );

  const [flagSrc, setFlagSrc] = useState('');

  useEffect(() => {
    const currentLanguage = localStorage.getItem('I18N_LANGUAGE');
    if (currentLanguage) {
      setSelectedLang(currentLanguage);
      i18n.changeLanguage(currentLanguage);
      const selectedLanguage = languages.find(
        (lang) => lang.lang === currentLanguage
      );
      if (selectedLanguage) {
        setFlagSrc(selectedLanguage.flag);
      }
    }
  }, [languages]);

  useEffect(() => {
    const selectedLanguage = languages.find(
      (lang) => lang.lang === selectedLang
    );
    if (selectedLanguage) {
      setFlagSrc(selectedLanguage.flag);
    }
  }, [selectedLang, languages]);

  const changeLanguageAction = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('I18N_LANGUAGE', lang);
    setSelectedLang(lang);
  };

  return (
    <div
      className='p-3 cursor-pointer'
      onClick={(event) => menuLanguage.current.toggle(event)}>
      <Menu
        model={flagItems}
        popup
        ref={menuLanguage}
        className='w-auto'
        pt={{ menuitem: 'mx-2' }}
      />
      <Avatar
        image={flagSrc}
        aria-controls='popup_menu_right'
        aria-haspopup
        style={{ height: '2rem', width: '2rem', borderRadius: '5px' }}
      />
    </div>
  );
};

export default SelectLanguage;
