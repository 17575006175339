import {
  GET_VIOLATE_BY_ID,
  GET_VIOLATE_BY_ID_SUCCESS,
  GET_VIOLATE_BY_ID_FAIL,
  GET_VIOLATE_BY_USER_ID,
  GET_VIOLATE_BY_USER_ID_SUCCESS,
  GET_VIOLATE_BY_USER_ID_FAIL,
  SET_VIOLATE,
  SET_VIOLATE_SUCCESS,
  SET_VIOLATE_FAIL,
  UPDATE_VIOLATE,
  UPDATE_VIOLATE_SUCCESS,
  UPDATE_VIOLATE_FAIL,
  DELETE_VIOLATE,
  DELETE_VIOLATE_SUCCESS,
  DELETE_VIOLATE_FAIL,
  SET_DATA_NULL,
  HANDLE_VIOLATE,
  HANDLE_VIOLATE_SUCCESS,
  HANDLE_VIOLATE_FAIL,
} from "./actionTypes";

export const getViolateById = (query) => ({
  type: GET_VIOLATE_BY_ID,
  payload: query,
});

export const getViolateByIdSuccess = (data) => ({
  type: GET_VIOLATE_BY_ID_SUCCESS,
  payload: data,
});

export const getViolateByIdFail = (error) => ({
  type: GET_VIOLATE_BY_ID_FAIL,
  payload: error,
});

export const getViolateByUserId = (query) => ({
  type: GET_VIOLATE_BY_USER_ID,
  payload: query,
});

export const getViolateByUserIdSuccess = (data) => ({
  type: GET_VIOLATE_BY_USER_ID_SUCCESS,
  payload: data,
});

export const getViolateByUserIdFail = (error) => ({
  type: GET_VIOLATE_BY_USER_ID_FAIL,
  payload: error,
});

export const setViolate = (values, files) => ({
  type: SET_VIOLATE,
  payload: values,
  files,
});

export const setViolateSuccess = (data) => ({
  type: SET_VIOLATE_SUCCESS,
  payload: data,
});

export const setViolateFail = (error) => ({
  type: SET_VIOLATE_FAIL,
  payload: error,
});

export const handleViolate = (values, files) => ({
  type: HANDLE_VIOLATE,
  payload: values,
  files,
});

export const handleViolateSuccess = (data) => ({
  type: HANDLE_VIOLATE_SUCCESS,
  payload: data,
});

export const handleViolateFail = (error) => ({
  type: HANDLE_VIOLATE_FAIL,
  payload: error,
});

export const updateViolate = (values, files) => ({
  type: UPDATE_VIOLATE,
  payload: values,
  files,
});

export const updateViolateSuccess = (data) => ({
  type: UPDATE_VIOLATE_SUCCESS,
  payload: data,
});

export const updateViolateFail = (error) => ({
  type: UPDATE_VIOLATE_FAIL,
  payload: error,
});

export const deleteViolate = (id) => ({
  type: DELETE_VIOLATE,
  payload: id,
});

export const deleteViolateSuccess = (data) => ({
  type: DELETE_VIOLATE_SUCCESS,
  payload: data,
});

export const deleteViolateFail = (error) => ({
  type: DELETE_VIOLATE_FAIL,
  payload: error,
});

export const setDataNull = () => ({
  type: SET_DATA_NULL,
});
