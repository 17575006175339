/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-dupe-keys */
import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getReceivingFactoryGetList } from "../../store/receiving_factory/actions";
import { getSyndicationGetList } from "../../store/syndication/actions";
import { getDispatchingCompanyGetList } from "../../store/dispatching_company/actions";
import { getCareerByUserId } from "../../store/career/actions";
import {
    getBranchReceiving,
    getBranchSyndication,
    getBranchDispatching,
} from "../../store/address/actions";
// import { residenceItems } from '../../contexts/data/defaultData';
import {
    dropdownSelected,
    dropdownOption,
} from "../../assets/templete/dropdownTemplete";
import CircleImage from "../../components/CircleImage";
import { setDataNull, setIntern } from "../../store/intern/actions";
import { ObjectType } from "../../components/Interface";
import moment from "moment";
import {
    getCodeRandom,
    getPasswordRandom,
    getUsernameRandom,
} from "../../ultis/GetRandomCode";

import ActionMenu from "../../components/ActionMenu";
import AddressDetail from "../../components/AddressDetail";

const AddInternDialog = ({ visible, onHide, toast }) => {
    const [listAddress, setListAddress] = useState([]);
    const { t } = useTranslation();
    const [selectReceivingFactory, setSelectReceivingFactory] = useState(null);
    const [selectSyndication, setSelectSyndication] = useState(null);
    const [selectDispaching, setSelectDispatching] = useState(null);
    const [isLogin, setIsLogin] = useState(false);
    const dispatch = useDispatch();
    const [selectCountry, setSelectCountry] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const admin = JSON.parse(localStorage.getItem("object_type"));
    const adminId = JSON.parse(localStorage.getItem("object_id"));
    const avata = "./images/avatas/logo-intern-null.svg";
    const fileInputRef = useRef(null);
    const [showAvata, setShowAvata] = useState(avata);
    const [cardList, setCardList] = useState([]);
    const [cardDialog, setCardDialog] = useState(false);
    const [editingCard, setEditingCard] = useState(null);

    const callBackData = (data) => {
        setListAddress(data);
    };

    const openDialog = () => {
        setCardDialog(true);
        setEditingCard(null);
    };

    const {
        dataCountry,
        dataStatus,
        dataReceiving,
        dataBranchReceiving,
        dataSydication,
        dataBranchSyndication,
        dataDispatching,
        dataBranchDispatching,
        dataCareer,
        errorInsert,
        dataInsert,
    } = useSelector(
        (state) => ({
            dataCountry: state.Country.datas,
            dataStatus: state.Status.dataByUserId,
            dataReceiving: state.ReceivingFactory.dataGetList,
            dataBranchReceiving: state.Address.dataBranchReceving,
            dataSydication: state.Syndication.dataGetList,
            dataBranchSyndication: state.Address.dataBranchSyndication,
            dataDispatching: state.DispatchingCompany.dataGetList,
            dataBranchDispatching: state.Address.dataBranchDispatching,
            dataCareer: state.Career.dataByUserId,
            errorInsert: state.Intern.errorInsert,
            dataInsert: state.Intern.dataInsert,
        }),
        shallowEqual
    );
    // Lấy dữ liệu
    const getListAddress = (data) => {
        setListAddress(data);
    };

    // Lấy danh sách thành phố
    useEffect(() => {
        // dispatch(getCountryAll());
        // dispatch(getStatusByUserId());
        dispatch(getCareerByUserId());
        dispatch(getSyndicationGetList());
        dispatch(getReceivingFactoryGetList());
        dispatch(getDispatchingCompanyGetList());
    }, [dispatch]);

    // Lấy danh sách branch
    useEffect(() => {
        if (selectReceivingFactory) {
            const data = {
                object_id: selectReceivingFactory.id,
                object_type: selectReceivingFactory.object_type,
            };
            dispatch(getBranchReceiving(data));
        }
    }, [selectReceivingFactory]);

    useEffect(() => {
        if (selectSyndication) {
            const data = {
                object_id: selectSyndication.id,
                object_type: selectSyndication.object_type,
            };
            dispatch(getBranchSyndication(data));
        }
    }, [selectSyndication]);

    useEffect(() => {
        if (selectDispaching) {
            const data = {
                object_id: selectDispaching.id,
                object_type: selectDispaching.object_type,
            };
            dispatch(getBranchDispatching(data));
        }
    }, [selectDispaching]);

    // Lấy dữ liệu ban đầu từ local để list branch
    useEffect(() => {
        if (admin === ObjectType.RECEIVING_FACTORY) {
            const data = {
                object_id: adminId,
                object_type: admin,
            };
            dispatch(getBranchReceiving(data));
        } else if (admin === ObjectType.SYNDICATION) {
            const data = {
                object_id: adminId,
                object_type: admin,
            };
            dispatch(getBranchSyndication(data));
        } else {
            const data = {
                object_id: adminId,
                object_type: admin,
            };
            dispatch(getBranchDispatching(data));
        }
    }, []);

    const genderItems = [
        {
            value: "Male",
            name: t("male"),
        },
        {
            value: "Female",
            name: t("female"),
        },
    ];

    const residenceItems = [
        {
            code: 1,
            name: t("Intern training (i)"),
            value: "Intern training (i)",
        },
        {
            code: 2,
            name: t("Intern training (ii) (2nd year)"),
            value: "Intern training (ii) (2nd year)",
        },
        {
            code: 3,
            name: t("Intern training (ii) (3nd year)"),
            value: "Intern training (ii) (3nd year)",
        },
        {
            code: 4,
            name: t("Technical Intern training No. (iii) (4th year)"),
            value: "Technical Intern training No. (iii) (4th year)",
        },
        {
            code: 5,
            name: t("Technical Intern training No. (iii) (5th year)"),
            value: "Technical Intern training No. (iii) (5th year)",
        },
        {
            code: 6,
            name: t("Specification"),
            value: "Specification",
        },
    ];

    // Thay đổi file
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setShowAvata(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // tao schema khi khong tao tk
    const withoutAccountSchema = Yup.object().shape({
        // last_name_jp: Yup.string().required(t("This value is required")),
        // first_name_jp: Yup.string().required(t("This value is required")),
        country: Yup.string().required(t("This value is required")),
        gender: Yup.string().required(t("This value is required")),
        dob: Yup.date()
            .max(new Date(), t("This value is required"))
            .required(t("This value is required")),
        passport_code: Yup.string().required(t("This value is required")),
        passport_license_date: Yup.string().required(
            t("This value is required")
        ),
        passport_expiration_date: Yup.string().required(
            t("This value is required")
        ),
        // phone_domestically: Yup.string().required(t('This value is required')),
        syndication_id: Yup.string().required(t("This value is required")),
        receiving_factory_id: Yup.string().required(
            t("This value is required")
        ),
        dispatching_company_id: Yup.string().required(
            t("This value is required")
        ),
    });

    const withAccountSchema = Yup.object().shape({
        // last_name_jp: Yup.string().required(t("This value is required")),
        // first_name_jp: Yup.string().required(t("This value is required")),
        country: Yup.string().required(t("This value is required")),
        gender: Yup.string().required(t("This value is required")),
        dob: Yup.date()
            .max(new Date(), t("This value is required"))
            .required(t("This value is required")),
        passport_code: Yup.string().required(t("This value is required")),
        passport_license_date: Yup.string().required(
            t("This value is required")
        ),
        passport_expiration_date: Yup.string().required(
            t("This value is required")
        ),
        // phone_domestically: Yup.string().required(t('This value is required')),
        syndication_id: Yup.string().required(t("This value is required")),
        receiving_factory_id: Yup.string().required(
            t("This value is required")
        ),
        dispatching_company_id: Yup.string().required(
            t("This value is required")
        ),
        password: Yup.string()
            .min(6, t("This value is required"))
            .required(t("This value is required")),
        username: Yup.string().required(t("This value is required")),
    });

    // check login
    const getValidationSchema = () => {
        if (isLogin) {
            return withAccountSchema;
        } else {
            return withoutAccountSchema;
        }
    };

    const formik = useFormik({
        initialValues: {
            avata: "",
            username: getUsernameRandom(),
            password: process.env.REACT_APP_PASSWORD_DEFAULT,
            is_login: "",
            first_name_jp: "",
            middle_name_jp: "",
            last_name_jp: "",
            first_name_en: "",
            middle_name_en: "",
            last_name_en: "",
            country: "",
            gender: "",
            dob: "",
            passport_code: "", // Mã hộ chiếu
            passport_license_date: "",
            passport_expiration_date: "",
            phone_domestically: "", // số điện thoại trong nước
            phone_abroad: "", // Số điẹn thoại nước ngoài
            email: "",
            description: "",
            status_ids: [], // Trạng thái thanh 1 mang
            receiving_factory_id:
                admin === ObjectType.RECEIVING_FACTORY ? adminId : "",
            receiving_factory_branch_id: "", // chi nhanh cua xi nghiep
            dispatching_company_id:
                admin === ObjectType.DISPATCHING_COMPANY ? adminId : "",
            dispatching_company_branch_id: null,
            syndication_id: admin === ObjectType.SYNDICATION ? adminId : "",
            syndication_branch_id: "",
            entry_date: "", // Ngày nhập cảnh
            status_of_residence: null, // Nơi cư trú trạng thái
            card_number: "", // Thẻ ngoại kiều
            card_license_date: "", // Ngày cấp thẻ
            card_expiration_date: "", // Ngày hết hạn
            career_id: "",
            alien_registration_cards: [],
        },
        validationSchema: getValidationSchema(),
        onSubmit: async (values) => {
            const data = {
                ...values,
                is_login: isLogin,
                addresses: listAddress,
                dob: moment(values.dob).utcOffset(+9).format("YYYY-MM-DD"),
                passport_license_date: moment(values.passport_license_date)
                    .utcOffset(+9)
                    .format("YYYY-MM-DD"),
                passport_expiration_date: moment(
                    values.passport_expiration_date
                )
                    .utcOffset(+9)
                    .format("YYYY-MM-DD"),
                entry_date: values.entry_date
                    ? moment(values.entry_date)
                          .utcOffset(+9)
                          .format("YYYY-MM-DD")
                    : "",
            };

            dispatch(setIntern(data, selectedFile));
        },
    });

    const statusValueTemplate = (option, props) => {
        console.log("lua chon", option);
        if (option) {
            return <div>{t(option)}</div>;
        }
        return <span>{props.placeholder}</span>;
    };

    const statusItemTemplate = (option) => {
        return <div>{t(option.name)}</div>;
    };

    // format quốc gia
    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img
                        alt={option.CountryName_vi}
                        src={`${option.Avatar}`}
                        className={`mr-2 }`}
                        style={{ width: "18px" }}
                    />
                    <div>{option.CountryName_vi}</div>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img
                    alt={option.CountryName_vi}
                    src={`${option.Avatar}`}
                    className={`mr-2 }`}
                    style={{ width: "18px" }}
                />
                <div>{option.CountryName_vi}</div>
            </div>
        );
    };

    const handleAddCard = () => {
        if (editingCard) {
            // Update existing card
            const updatedCardList = cardList.map((card) =>
                card.id === editingCard.id
                    ? {
                          ...card,
                          card_number: formik.values.card_number,
                          status_of_residence:
                              formik.values.status_of_residence,
                          card_license_date: formik.values.card_license_date,
                          card_expiration_date:
                              formik.values.card_expiration_date,
                          description: formik.values.description,
                      }
                    : card
            );
            setCardList(updatedCardList);
            formik.setFieldValue("alien_registration_cards", updatedCardList);
            setEditingCard(null);
        } else {
            // Add new card
            const newCard = {
                id: new Date().getTime(), // Unique ID
                card_number: formik.values.card_number,
                status_of_residence: formik.values.status_of_residence,
                card_license_date: formik.values.card_license_date,
                card_expiration_date: formik.values.card_expiration_date,
                description: formik.values.description,
            };
            setCardList((prevCardList) => [...prevCardList, newCard]);
            formik.setFieldValue("alien_registration_cards", [
                ...formik.values.alien_registration_cards,
                newCard,
            ]);
        }
        setCardDialog(false);

        // Reset form values
        formik.setFieldValue("card_number", "");
        formik.setFieldValue("status_of_residence", "");
        formik.setFieldValue("card_license_date", null);
        formik.setFieldValue("card_expiration_date", null);
        formik.setFieldValue("description", "");
    };

    //Card Templete
    const cardTemplete = (rowData) => {
        return (
            <div className="flex flex-column">
                <span className="font-bold">{rowData.card_number}</span>
                <span className="text-green-600">
                    {t(rowData.status_of_residence)}
                </span>
            </div>
        );
    };

    const CardAddHeader = () => {
        return (
            <div
                onClick={openDialog}
                style={{
                    cursor: "pointer",
                    backgroundColor: "#10B981",
                    color: "white",
                    padding: "5px",
                    borderRadius: "5px",
                }}
            >
                {t("Add New")}
            </div>
        );
    };

    useEffect(() => {
        if (errorInsert) {
            toast.current?.show({
                severity: "error",
                summary: "Error",
                detail: `Add Intern Error: ${errorInsert.data.message}`,
                life: 5000,
            });
            dispatch(setDataNull());
        }
        if (dataInsert) {
            toast.current?.show({
                severity: "success",
                summary: "Success",
                detail: "Add Intern Success",
                life: 5000,
            });
            formik.resetForm();
            dispatch(setDataNull());
            setShowAvata(avata);
            onHide();
        }
        // eslint-disable-next-line
    }, [errorInsert, dataInsert]);

    const handleEdit = (card) => {
        setCardDialog(true);
        setEditingCard(card);
        formik.setValues({
            ...formik.values,
            card_number: card.card_number,
            status_of_residence: card.status_of_residence,
            card_license_date: card.card_license_date,
            card_expiration_date: card.card_expiration_date,
            description: card.description,
        });
    };

    const handleDelete = (cardId) => {
        setCardList((prevCardList) =>
            prevCardList.filter((card) => card.id !== cardId)
        );
        formik.setFieldValue(
            "alien_registration_cards",
            formik.values.alien_registration_cards.filter(
                (card) => card.id !== cardId
            )
        );
    };

    //Menu hành động
    const actionBodyTemplate = (dataListCard) => {
        return (
            <ActionMenu
                onEdit={() => handleEdit(dataListCard)}
                onDelete={() => handleDelete(dataListCard.id)}
            />
        );
    };

    return (
        <>
            <Dialog
                visible={visible}
                onHide={onHide}
                header={t("Add Intern")}
                modal
                style={{ width: "90vw", height: "90vh" }}
                footer={
                    <div>
                        <Button
                            label={t("Cancel")}
                            onClick={onHide}
                            severity="secondary"
                            className="p-button-text"
                        />
                        <Button
                            label={t("Save")}
                            type="submit"
                            onClick={formik.handleSubmit}
                        />
                    </div>
                }
                pt={{
                    content: "surface-100 p-3",
                    footer: "surface-100",
                    header: "py-3 pl-4",
                }}
            >
                <div className="flex flex-row flex-wrap">
                    <div className="w-2 p-2">
                        <div className="bg-white p-3 border-round-md h-full">
                            <div className="flex justify-content-center">
                                <div className="flex flex-column">
                                    <CircleImage
                                        src={showAvata}
                                        size={150}
                                        altImage="Avatar"
                                    />
                                    <div className="my-2 text-center">
                                        <Button
                                            name="icon"
                                            type="file"
                                            label={t("Upload Photo")}
                                            size="small"
                                            severity="secondary"
                                            outlined
                                            onClick={() => {
                                                fileInputRef.current?.click();
                                            }}
                                        />
                                        <input
                                            onChange={handleFileChange}
                                            multiple={false}
                                            ref={fileInputRef}
                                            type="file"
                                            accept="image/*"
                                            hidden
                                            name="icon"
                                            id="icon"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6">
                                <div className="flex align-items-center justify-content-center mb-3">
                                    <InputSwitch
                                        checked={isLogin}
                                        onChange={(e) => setIsLogin(e.value)}
                                    />
                                    <label className="font-bold ml-2">
                                        {t("Allow login")}
                                    </label>
                                </div>
                                {isLogin && (
                                    <div>
                                        <InputText
                                            name="username"
                                            placeholder={t("Username")}
                                            className="w-full mb-1"
                                            value={formik.values.username}
                                            onChange={formik.handleChange}
                                            invalid={
                                                formik.touched.username &&
                                                formik.errors.username
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {formik.touched.username &&
                                        formik.errors.username ? (
                                            <div style={{ color: "red" }}>
                                                {formik.errors.username.toString()}
                                            </div>
                                        ) : null}
                                        <InputText
                                            name="password"
                                            placeholder={t("Password")}
                                            className="w-full mt-1 mb-1"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            invalid={
                                                formik.touched.password &&
                                                formik.errors.password
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {formik.touched.password &&
                                        formik.errors.password ? (
                                            <div style={{ color: "red" }}>
                                                {formik.errors.password.toString()}
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Content 1 */}
                    <div className="w-5 p-2">
                        <div className="bg-white border-round-md p-3 h-full">
                            <div className="flex flex-row">
                                <div className="w-4 flex flex-column px-2 mt-2">
                                    <FloatLabel>
                                        <InputText
                                            id="last_name_jp"
                                            className="w-full border-noround-bottom"
                                            value={formik.values.last_name_jp}
                                            onChange={formik.handleChange}
                                            // invalid={
                                            //     formik.touched.last_name_jp &&
                                            //     formik.errors.last_name_jp
                                            //         ? true
                                            //         : false
                                            // }
                                        />
                                        <label htmlFor="last_name_jp">
                                            <span className="mr-2">
                                                {t("Last Name")}
                                            </span>
                                            {/* <span className="bg-yellow-100 px-1 border-round-sm">
                                                {t("Required")}
                                            </span> */}
                                        </label>
                                    </FloatLabel>
                                </div>
                                <div className="w-4 flex flex-column px-2 mt-2">
                                    <FloatLabel>
                                        <InputText
                                            id="middle_name_jp"
                                            className="w-full border-noround-bottom"
                                            onChange={formik.handleChange}
                                            value={formik.values.middle_name_jp}
                                            // invalid={
                                            //     formik.touched.middle_name_jp &&
                                            //     formik.errors.middle_name_jp
                                            //         ? true
                                            //         : false
                                            // }
                                        />
                                        <label htmlFor="middle_name_jp">
                                            {t("Middle Name")}
                                        </label>
                                    </FloatLabel>
                                </div>
                                <div className="w-4 flex flex-column px-2 mt-2">
                                    <FloatLabel>
                                        <InputText
                                            id="first_name_jp"
                                            className="w-full border-noround-bottom"
                                            onChange={formik.handleChange}
                                            value={formik.values.first_name_jp}
                                            // invalid={
                                            //     formik.touched.first_name_jp &&
                                            //     formik.errors.first_name_jp
                                            //         ? true
                                            //         : false
                                            // }
                                        />
                                        <label htmlFor="first_name_jp">
                                            <span className="mr-2">
                                                {t("First Name")}
                                            </span>
                                            {/* <span className="bg-yellow-100 px-1 border-round-sm">
                                                {t("Required")}
                                            </span> */}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="px-2 w-4">
                                    <InputText
                                        id="last_name_en"
                                        placeholder={t("Last Name (English)")}
                                        className="w-full border-noround-top border-top-none"
                                        onChange={formik.handleChange}
                                        value={formik.values.last_name_en}
                                        invalid={
                                            formik.touched.last_name_en &&
                                            formik.errors.last_name_en
                                                ? true
                                                : false
                                        }
                                    />
                                    {formik.touched.last_name_en &&
                                    formik.errors.last_name_en ? (
                                        <span
                                            style={{
                                                color: "red",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {formik.errors.last_name_en.toString()}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="px-2 w-4">
                                    <InputText
                                        id="middle_name_en"
                                        placeholder={t("Middle Name (English)")}
                                        className="w-full border-noround-top border-top-none"
                                        onChange={formik.handleChange}
                                        value={formik.values.middle_name_en}
                                        invalid={
                                            formik.touched.middle_name_en &&
                                            formik.errors.middle_name_en
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                <div className="px-2 w-4">
                                    <InputText
                                        id="first_name_en"
                                        placeholder={t("First Name (English)")}
                                        className="w-full border-noround-top border-top-none"
                                        onChange={formik.handleChange}
                                        value={formik.values.first_name_en}
                                        invalid={
                                            formik.touched.first_name_en &&
                                            formik.errors.first_name_en
                                                ? true
                                                : false
                                        }
                                    />
                                    {formik.touched.first_name_en &&
                                    formik.errors.first_name_en ? (
                                        <span
                                            style={{
                                                color: "red",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {formik.errors.first_name_en.toString()}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex flex-row mt-4">
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataCountry.filter(
                                                (country) =>
                                                    country.CountryName_en !=
                                                    "Japan"
                                            )}
                                            name="country"
                                            value={formik.values.country}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "country",
                                                    e.value
                                                );
                                                setSelectCountry(e.value);
                                            }}
                                            filter
                                            optionLabel="CountryName_en"
                                            optionValue="CountryName_en"
                                            valueTemplate={
                                                selectedCountryTemplate
                                            }
                                            itemTemplate={countryOptionTemplate}
                                            emptyFilterMessage
                                            placeholder={t("Country")}
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched.country &&
                                                formik.errors.country
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="country">
                                            <span className="mr-2">
                                                {t("Country")}
                                            </span>
                                            <span className="bg-yellow-100 px-1 border-round-sm">
                                                {t("Required")}
                                            </span>
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.country &&
                                    formik.errors.country ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.country.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <Dropdown
                                            id="gender"
                                            options={genderItems}
                                            value={formik.values.gender}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "gender",
                                                    e.value
                                                )
                                            }
                                            optionLabel="name"
                                            optionValue="value"
                                            placeholder={t("Gender")}
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched.gender &&
                                                formik.errors.gender
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="gender">
                                            <span className="mr-2">
                                                {t("Gender")}
                                            </span>
                                            <span className="bg-yellow-100 px-1 border-round-sm">
                                                {t("Required")}
                                            </span>
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.gender &&
                                    formik.errors.gender ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.gender.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <Calendar
                                            id="birthday"
                                            value={formik.values.dob}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "dob",
                                                    e.value
                                                )
                                            }
                                            showIcon
                                            dateFormat="yy/mm/dd"
                                            invalid={
                                                formik.touched.dob &&
                                                formik.errors.dob
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="birthday">
                                            <span className="mr-2">
                                                {t("Date of Birth")}
                                            </span>
                                            <span className="bg-yellow-100 px-1 border-round-sm">
                                                {t("Required")}
                                            </span>
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.dob && formik.errors.dob ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.dob.toString()}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex flex-row mt-4">
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <InputText
                                            id="passport_code"
                                            className="w-full"
                                            onChange={formik.handleChange}
                                            value={formik.values.passport_code}
                                            invalid={
                                                formik.touched.passport_code &&
                                                formik.errors.passport_code
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="passport_code">
                                            <span className="mr-2">
                                                {t("Passport Number")}
                                            </span>
                                            <span className="bg-yellow-100 px-1 border-round-sm">
                                                {t("Required")}
                                            </span>
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.passport_code &&
                                    formik.errors.passport_code ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.passport_code.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <Calendar
                                            id="passport_license_date"
                                            value={
                                                formik.values
                                                    .passport_license_date
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "passport_license_date",
                                                    e.value
                                                )
                                            }
                                            showIcon
                                            dateFormat="yy/mm/dd"
                                            invalid={
                                                formik.touched
                                                    .passport_license_date &&
                                                formik.errors
                                                    .passport_license_date
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="passport_license_date">
                                            <span className="mr-2">
                                                {t("Date of Issue")}
                                            </span>
                                            <span className="bg-yellow-100 px-1 border-round-sm">
                                                {t("Required")}
                                            </span>
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.passport_license_date &&
                                    formik.errors.passport_license_date ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.passport_license_date.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <Calendar
                                            value={
                                                formik.values
                                                    .passport_expiration_date
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "passport_expiration_date",
                                                    e.value
                                                )
                                            }
                                            showIcon
                                            dateFormat="yy/mm/dd"
                                            invalid={
                                                formik.touched
                                                    .passport_expiration_date &&
                                                formik.errors
                                                    .passport_expiration_date
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="passport_expiration_date">
                                            <span className="mr-2">
                                                {t("Date of Expiration")}
                                            </span>
                                            <span className="bg-yellow-100 px-1 border-round-sm">
                                                {t("Required")}
                                            </span>
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.passport_expiration_date &&
                                    formik.errors.passport_expiration_date ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.passport_expiration_date.toString()}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex flex-row mt-4">
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <InputText
                                            id="phone_domestically"
                                            className="w-full"
                                            onChange={formik.handleChange}
                                            value={
                                                formik.values.phone_domestically
                                            }
                                            invalid={
                                                formik.touched
                                                    .phone_domestically &&
                                                formik.errors.phone_domestically
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label
                                            htmlFor="phone_domestically"
                                            className="overflow-hidden white-space-nowrap "
                                            style={{ width: "80%" }}
                                        >
                                            {t("Domestic Phone Number")}
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.phone_domestically &&
                                    formik.errors.phone_domestically ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.phone_domestically.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <InputText
                                            id="phone_abroad"
                                            className="w-full"
                                            onChange={formik.handleChange}
                                            value={formik.values.phone_abroad}
                                            invalid={
                                                formik.touched.phone_abroad &&
                                                formik.errors.phone_abroad
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="phone2">
                                            {t("Foreign Phone Number")}
                                        </label>
                                    </FloatLabel>
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <InputText
                                            id="email"
                                            className="w-full"
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            invalid={
                                                formik.touched.email &&
                                                formik.errors.email
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="email">
                                            {t("Email")}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className="mt-4 px-2">
                                <FloatLabel>
                                    <InputTextarea
                                        rows={2}
                                        id="description"
                                        className="w-full"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                    />
                                    <label htmlFor="description">
                                        {t("Description")}
                                    </label>
                                </FloatLabel>
                            </div>
                            <div className="mt-4 px-2">
                                <FloatLabel>
                                    <MultiSelect
                                        value={formik.values.status_ids}
                                        options={dataStatus?.filter(
                                            (status) =>
                                                status.name !==
                                                    "Return to the country on time" &&
                                                status.name !==
                                                    "Return to the country not on time"
                                        )}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                "status_ids",
                                                e.value
                                            )
                                        }
                                        optionLabel="name"
                                        optionValue="id"
                                        // valueTemplate={statusValueTemplate}
                                        // selectedItemsLabel="name"
                                        // selectedItemTemplate={statusValueTemplate}
                                        itemTemplate={statusItemTemplate}
                                        placeholder=""
                                        showClear
                                        className="w-full"
                                        display="chip"
                                        pt={{ header: "hidden" }}
                                        invalid={
                                            formik.touched.status_ids &&
                                            formik.errors.status_ids
                                                ? true
                                                : false
                                        }
                                    />
                                    <label htmlFor="status">
                                        {t("Status")}
                                    </label>
                                </FloatLabel>
                            </div>
                        </div>
                    </div>

                    <div className="w-5 p-2">
                        <div className="bg-white border-round-md p-4 h-full">
                            <div className={"flex flex-row"}>
                                <div className={"w-6"}>
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataSydication}
                                            value={dataSydication?.find(
                                                (e) =>
                                                    e.id ===
                                                    formik.values.syndication_id
                                            )}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "syndication_id",
                                                    e.value.id
                                                );
                                                setSelectSyndication(e.value);
                                            }}
                                            filter
                                            optionLabel="name_jp"
                                            // optionValue="id"
                                            disabled={
                                                admin === ObjectType.SYNDICATION
                                            }
                                            valueTemplate={dropdownSelected}
                                            itemTemplate={dropdownOption}
                                            emptyFilterMessage
                                            placeholder={t("Syndication")}
                                            style={{ height: "35px" }}
                                            className="border-noround-right"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched.syndication_id &&
                                                formik.errors.syndication_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="syndication_id">
                                            <span className="mr-2">
                                                {t("Syndication")}
                                            </span>
                                            <span className="bg-yellow-100 px-1 border-round-sm">
                                                {t("Required")}
                                            </span>
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.syndication_id &&
                                    formik.errors.syndication_id ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.syndication_id.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-6">
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataBranchSyndication} // Tý chẹc không có hiển thị no branch
                                            value={
                                                formik.values
                                                    .syndication_branch_id
                                            }
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "syndication_branch_id",
                                                    e.value
                                                );
                                            }}
                                            showClear
                                            optionValue="id"
                                            optionLabel="branch"
                                            className="border-noround-left border-left-none"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            style={{ height: "35px" }}
                                            invalid={
                                                formik.touched
                                                    .syndication_branch_id &&
                                                formik.errors
                                                    .syndication_branch_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="factory">
                                            {t("Branch")}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className={"flex flex-row mt-4"}>
                                <div className={"w-6"}>
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataReceiving}
                                            value={dataReceiving?.find(
                                                (e) =>
                                                    e.id ===
                                                    formik.values
                                                        .receiving_factory_id
                                            )}
                                            onChange={(e) => {
                                                setSelectReceivingFactory(
                                                    e.value
                                                );
                                                formik.setFieldValue(
                                                    "receiving_factory_id",
                                                    e.value.id
                                                );
                                            }}
                                            filter
                                            id="receiving_factory_id"
                                            disabled={
                                                admin ===
                                                ObjectType.RECEIVING_FACTORY
                                            }
                                            optionLabel="name_jp"
                                            valueTemplate={dropdownSelected}
                                            itemTemplate={dropdownOption}
                                            emptyFilterMessage
                                            style={{ height: "35px" }}
                                            className="border-noround-right"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched
                                                    .receiving_factory_id &&
                                                formik.errors
                                                    .receiving_factory_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="receiving_factory_id">
                                            <span className="mr-2">
                                                {t("Receiving Factory")}
                                            </span>
                                            <span className="bg-yellow-100 px-1 border-round-sm">
                                                {t("Required")}
                                            </span>
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.receiving_factory_id &&
                                    formik.errors.receiving_factory_id ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.receiving_factory_id.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-6">
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataBranchReceiving}
                                            value={
                                                formik.values
                                                    .receiving_factory_branch_id
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "receiving_factory_branch_id",
                                                    e.value
                                                )
                                            }
                                            filter
                                            showClear
                                            optionLabel="branch"
                                            optionValue="id"
                                            style={{ height: "35px" }}
                                            className="border-noround-left border-left-none"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched
                                                    .receiving_factory_branch_id &&
                                                formik.errors
                                                    .receiving_factory_branch_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="factory">
                                            {t("Branch")}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className={"flex flex-row mt-4"}>
                                <div className={"w-6"}>
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataDispatching}
                                            value={dataDispatching?.find(
                                                (e) =>
                                                    e.id ===
                                                    formik.values
                                                        .dispatching_company_id
                                            )}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "dispatching_company_id",
                                                    e.value.id
                                                );
                                                setSelectDispatching(e.value);
                                            }}
                                            filter
                                            style={{ height: "35px" }}
                                            id="dispatching_company_id"
                                            disabled={
                                                admin ===
                                                ObjectType.DISPATCHING_COMPANY
                                            }
                                            optionLabel="name_jp"
                                            valueTemplate={dropdownSelected}
                                            itemTemplate={dropdownOption}
                                            emptyFilterMessage
                                            className="border-noround-right"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched
                                                    .dispatching_company_id &&
                                                formik.errors
                                                    .dispatching_company_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="dispatching_company_id">
                                            <span className="mr-2">
                                                {t("Dispatching company")}
                                            </span>
                                            <span className="bg-yellow-100 px-1 border-round-sm">
                                                {t("Required")}
                                            </span>
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.dispatching_company_id &&
                                    formik.errors.dispatching_company_id ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.dispatching_company_id.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-6">
                                    <FloatLabel>
                                        <Dropdown
                                            style={{ height: "35px" }}
                                            options={dataBranchDispatching}
                                            value={
                                                formik.values
                                                    .dispatching_company_branch_id
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "dispatching_company_branch_id",
                                                    e.value
                                                )
                                            }
                                            filter
                                            optionValue="id"
                                            showClear
                                            optionLabel="branch"
                                            className="border-noround-left border-left-none"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched
                                                    .dispatching_company_branch_id &&
                                                formik.errors
                                                    .dispatching_company_branch_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="factory">
                                            {t("Branch")}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className="flex flex-row mt-4">
                                <div className="w-6 mr-3">
                                    <FloatLabel>
                                        <Calendar
                                            value={formik.values.entry_date}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "entry_date",
                                                    e.value
                                                )
                                            }
                                            dateFormat="yy/mm/dd"
                                            showIcon
                                            invalid={
                                                formik.touched.entry_date &&
                                                formik.errors.entry_date
                                                    ? true
                                                    : false
                                            }
                                            className="w-full"
                                        />
                                        <label
                                            htmlFor="entrydate"
                                            className="overflow-hidden white-space-nowrap"
                                        >
                                            {t("Entry Date")}
                                        </label>
                                    </FloatLabel>
                                </div>
                                <div className="w-6">
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataCareer}
                                            showClear
                                            value={formik.values.career_id}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "career_id",
                                                    e.value
                                                )
                                            }
                                            optionLabel="name"
                                            optionValue="id"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched.career_id &&
                                                formik.errors.career_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="career_id">
                                            {t("Career")}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>

                            <div className="flex flex-row mt-4">
                                <DataTable
                                    value={cardList}
                                    className="w-full"
                                    emptyMessage={t("No data available")}
                                >
                                    <Column
                                        field="card_number"
                                        header={t("Foreigner Registration No.")}
                                        body={cardTemplete}
                                        className="w-5"
                                    ></Column>
                                    <Column
                                        field="card_license_date"
                                        header={t("Date of Issue")}
                                        body={(rowData) =>
                                            moment(
                                                rowData.card_license_date
                                            ).format("YYYY/MM/DD")
                                        }
                                        className="w-3"
                                    ></Column>
                                    <Column
                                        field="card_expiration_date"
                                        header={t("Date of Expiration")}
                                        body={(rowData) =>
                                            moment(
                                                rowData.card_expiration_date
                                            ).format("YYYY/MM/DD")
                                        }
                                        className="w-3"
                                    ></Column>
                                    <Column
                                        field="action"
                                        header={CardAddHeader}
                                        body={(rowData) => (
                                            <ActionMenu
                                                onEdit={() =>
                                                    handleEdit(rowData)
                                                }
                                                onDelete={() =>
                                                    handleDelete(rowData.id)
                                                }
                                            />
                                        )}
                                        className="w-1"
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>

                <AddressDetail
                    object_id={null}
                    object_type={ObjectType.INTERN}
                    callBackData={callBackData}
                />
            </Dialog>
            <Dialog
                visible={cardDialog}
                onHide={() => setCardDialog(false)}
                header={t("Add a new alien registration card")}
                style={{ width: "20%" }}
            >
                <div className="flex flex-column">
                    <label className="mb-2">
                        {t("Foreigner Registration No.")}
                    </label>
                    <InputText
                        id="card_number"
                        value={formik.values.card_number}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="flex flex-column mt-3">
                    <label className="mb-2">{t("Residency status")}</label>
                    <Dropdown
                        id="status_of_residence"
                        name="status_of_residence"
                        options={residenceItems}
                        value={formik.values.status_of_residence}
                        onChange={(e) =>
                            formik.setFieldValue("status_of_residence", e.value)
                        }
                        showClear
                        optionLabel="name"
                        optionValue="value"
                        pt={{ list: "p-0", header: "p-3" }}
                        invalid={
                            formik.touched.status_of_residence &&
                            formik.errors.status_of_residence
                                ? true
                                : false
                        }
                    />
                </div>
                <div className="flex flex-column mt-3">
                    <label className="mb-2">{t("Date of Issue")}</label>
                    <Calendar
                        id="card_license_date"
                        value={formik.values.card_license_date}
                        onChange={(e) =>
                            formik.setFieldValue("card_license_date", e.value)
                        }
                        showIcon
                        dateFormat="yy/mm/dd"
                    />
                </div>
                <div className="flex flex-column mt-3">
                    <label className="mb-2">{t("Date of Expiration")}</label>
                    <Calendar
                        id="card_expiration_date"
                        value={formik.values.card_expiration_date}
                        onChange={(e) =>
                            formik.setFieldValue(
                                "card_expiration_date",
                                e.value
                            )
                        }
                        showIcon
                        dateFormat="yy/mm/dd"
                    />
                </div>
                <Button
                    label={t("Save")}
                    className="mt-5 w-full"
                    onClick={handleAddCard}
                />
            </Dialog>
        </>
    );
};

export default AddInternDialog;
