import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_DASHBOARD_DATA,
  GET_NEW_INTERN,
  GET_NOTIFICATION,
} from "./actionTypes";

import {
  getDashboardDataSuccess,
  getDashboardDataFail,
  getNewInternFail,
  getNewInternSuccess,
  getNotificationSuccess,
  getNotificationFail,
} from "./action";

import {
  getDashboardData,
  getNewInterns,
  getNotification,
} from "../../api/backend_helper";

function* fetDashboardData() {
  try {
    const response = yield call(getDashboardData);
    yield put(getDashboardDataSuccess(response));
  } catch (error) {
    yield put(getDashboardDataFail(error));
  }
}

function* fetNewInterns({ payload: data }) {
  try {
    const response = yield call(getNewInterns, data);
    yield put(getNewInternSuccess(response));
  } catch (error) {
    yield put(getNewInternFail(error.message));
  }
}

function* fetNotification({ payload: data }) {
  try {
    const response = yield call(getNotification, data);
    yield put(getNotificationSuccess(response));
  } catch (error) {
    yield put(getNotificationFail(error.message));
  }
}

function* DashboardSaga() {
  yield takeEvery(GET_DASHBOARD_DATA, fetDashboardData);
  yield takeEvery(GET_NEW_INTERN, fetNewInterns);
  yield takeEvery(GET_NOTIFICATION, fetNotification);
}

export default DashboardSaga;
