import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { ScrollPanel } from 'primereact/scrollpanel';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  getTicketDetailList,
  setReplyTicket,
  translateTicket,
  closeTicket,
} from '../../store/ticket/action';
import { site } from '../../api/url_helper';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { OverlayPanel } from 'primereact/overlaypanel';

import socketInstance from '../../Singleton/SocketSingleton';


const validationSchema = Yup.object().shape({
  content: Yup.string().required('Content is required'),
});

const TicketDetail = ({ selectedRow, setSelectedRow, toast }) => {
  const [translatedContent, setTranslatedContent] = useState({});
  const [itemVisibility, setItemVisibility] = useState({});
  const scrollContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [inputVisible, setInputVisible] = useState(true);
  const [titleTranslated, setTitleTranslated] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState();
  const [messages, setMessages] = useState([]);
  const op = useRef(null);

  const [socketData, setSocketData] = useState({});
  const socket = socketInstance.getSocket();

  useEffect(() => {
    socket.on('get-message', (msg) => {
      setSocketData((prevState) => [...prevState, msg]);
    });
  },[socketData, socket]);

  console.log(socketData);


  const { loadingTranslate, dataTicketList } = useSelector(
    (state) => ({
      dataTicketList: state.Ticket.dataTicketList,
      menu: state.Ticket.dataTicketList.menu,
      loadingTranslate: state.Ticket.loadingTranslate,
    }),
    shallowEqual
  );

  const { dataTicketDetailList } = useSelector(
    (state) => ({
      dataTicketDetailList: state.Ticket.dataTicketDetailList,
    }),
    shallowEqual
  );

  const userId = JSON.parse(localStorage.getItem('userId'));
  const language = localStorage.getItem('I18N_LANGUAGE');

  useEffect(() => {
    if (selectedRow) {
      dispatch(
        getTicketDetailList({ page: 1, limit: 100, ticket_id: selectedRow.id })
      );
    }
    if (selectedRow.ticket_status === 'Done') {
      setInputVisible(false);
    }
  }, [dispatch, selectedRow]);

  // useEffect(() => {
  // }, [dataTicketDetailList]);

  // useEffect(() => {
  //   if (selectedRow?.ticket_status === "Done") {
  //     setInputVisible(false);
  //   } else {
  //     setInputVisible(true);
  //   }
  // }, [selectedRow]);

  useEffect(() => {
    scrollToBottom(false);
  }, [selectedRow, messages]);

  const scrollToBottom = (immediate = false) => {
    if (immediate) {
      try {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop =
            scrollContainerRef.current.scrollHeight;
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setTimeout(function () {
        try {
          if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop =
              scrollContainerRef.current.scrollHeight;
          }
        } catch (e) {
          console.log(e);
        }
      }, 300);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        ...values,
        ticket_id: selectedRow.id,
        sender_id: userId,
      };
      dispatch(setReplyTicket(data));
      resetForm();
    },
  });

  const formatDate = useMemo(
    () => (date) => {
      const now = new Date();
      const yesterday = new Date(now.getTime() - 86400000);
      const messageDate = new Date(date);
      const isToday = now.toDateString() === messageDate.toDateString();
      const isYesterday =
        yesterday.toDateString() === messageDate.toDateString();

      const timeString = messageDate.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });

      if (isToday) {
        return timeString;
      } else if (isYesterday) {
        return `${t('Yesterday')} ${timeString}`;
      } else {
        return `${messageDate.toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })} ${timeString}`;
      }
    },
    [t]
  );

  const handleFileUpload = (event) => {
    const file = event.files?.[0];
    let formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('ticket_id', selectedRow.id);
    formData.append('content', file.name ?? '');
    formData.append('send_at', new Date().toISOString());
    formData.append('sender_id', userId);
    formData.append('description', '');

    dispatch(setReplyTicket(formData));
  };

  // bật tắt nội dung được dịch
  const toggleContentVisibility = (itemId) => {
    if (!loadingTranslate) {
      setItemVisibility((prevState) => ({
        ...prevState,
        [itemId.id]: !prevState[itemId.id],
      }));
    }
  };

  useEffect(() => {
    if (!loadingTranslate) {
      const updatedMessage = dataTicketDetailList.find(
        (msg) => msg.id === selectedMessageId
      );
      if (updatedMessage) {
        setTranslatedContent((prevState) => ({
          ...prevState,
          [updatedMessage.id]: {
            en: updatedMessage.content_en || updatedMessage.content,
            vi: updatedMessage.content_vi || updatedMessage.content,
            ja: updatedMessage.content_ja || updatedMessage.content,
          },
        }));
      }
    }
  }, [loadingTranslate, dataTicketDetailList, selectedMessageId]);

  //
  const translateMessage = useCallback(
    (message) => {
      if (message.is_translate === false) {
        dispatch(translateTicket({ id: message.id }));
        setSelectedMessageId(message.id);
      } else {
        // Nếu đã dịch, chỉ cần chuyển đổi ngôn ngữ hiển thị
        setTranslatedContent((prevState) => ({
          ...prevState,
          [message.id]: {
            en: message.content_en || message.content,
            vi: message.content_vi || message.content,
            ja: message.content_ja || message.content,
          },
        }));
      }
      toggleContentVisibility(message);
    },
    [dispatch, toggleContentVisibility]
  );

  const cancelTranslation = (messageId) => {
    setItemVisibility((prevState) => ({
      ...prevState,
      [messageId]: false,
    }));
  };
  const handleTranslateTitle = () => {
    setTitleTranslated(!titleTranslated);
  };

  const handleClearData = () => {
    setSelectedRow(null);
  };

  const closeTicketHandler = (ticket_id) => {
    setSelectedRow(null);
    dispatch(closeTicket({ ticket_id }));
  };

  const confirmCloseTicket = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: t('Confirm ticket close'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => closeTicketHandler(selectedRow.id),
    });
  };

  const userBodyTemplate = useCallback((selectedRow) => {
    const userName = selectedRow.sender_info.name || 'Unknown';
    const avatar = selectedRow.sender_info.avata || null;
    return (
      <div className='flex flex-row align-items-center py-2'>
        <img
          src={avatar}
          className='mr-3'
          style={{ width: '36px', height: '36px', borderRadius: '50%' }}
          alt={userName}
        />
        <span className='font-bold'>{userName}</span>
      </div>
    );
  }, []);

  return (
    <div
      className='h-full flex flex-column'
      style={{ height: '800px' }}>
      <div className='flex justify-content-between mb-3'>
        <div className='flex align-items-center'>
          <Button
            icon='pi pi-angle-left'
            onClick={() => {
              // setMessages(null);
              handleClearData();
            }}
            rounded
            outlined
            severity='secondary'
          />
          <span className='font-bold text-xl pl-5 pr-5'>
            {selectedRow.sender_info.name || 'Unknown'}
          </span>
          <i
            className='pi pi-info-circle cursor-pointer'
            style={{ color: 'var(--primary-color)', fontSize: '1.5rem' }}
            onClick={(e) => op.current.toggle(e)}></i>
        </div>
        <Button
          style={{ color: 'green' }}
          icon='pi pi-check-square'
          label='Hoàn thành'
          onClick={confirmCloseTicket}
          rounded
          outlined
          severity='warning'
        />
        <ConfirmPopup />

        <OverlayPanel ref={op}>{userBodyTemplate(selectedRow)}</OverlayPanel>
      </div>
      <div className='mt-2 tieuDe'>
        <label
          htmlFor='titleInput'
          className='custom-label'>
          {t('Title')}
        </label>
        <span
          id='titleInput'
          className='font-bold'>
          {selectedRow.title}
        </span>
        <div
          className='mt-2'
          onClick={handleTranslateTitle}
          style={{ cursor: 'pointer', color: '#0C4DA2' }}>
          {t('Translate')}
          <i className='pi pi-angle-right'></i>
        </div>
        {titleTranslated && <div>{selectedRow[`title_${language}`]}</div>}
      </div>
      <ScrollPanel
        className='w-full flex-grow-1 bg-white border-round-md border-1 border-200'
        style={{ height: '580px' }}>
        <div
          ref={scrollContainerRef}
          className='flex flex-column px-3 mt-2'
          style={{
            width: '100%',
            height: '580px',
            overflowY: 'auto',
          }}>
          {dataTicketDetailList.length === 0 ? (
            <div>No messages found</div>
          ) : (
            dataTicketDetailList.map((msg) => {
              return (
                <div
                  key={msg.id}
                  className={`message ${
                    msg.sender_id === userId
                      ? 'message-user'
                      : 'message-support'
                  }`}>
                  {msg.type === 'text' && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: msg.content,
                      }}></div>
                  )}
                  {msg.type === 'img' && (
                    <Image
                      src={msg.url}
                      alt='User upload'
                      className='message-image'
                      preview
                    />
                  )}
                  {msg.type === 'image' && (
                    <Image
                      src={`${site}${msg.url}`}
                      alt='User upload'
                      className='message-image'
                      preview
                    />
                  )}
                  {msg.type === 'file' && (
                    <div className='flex align-items-center'>
                      <i
                        className={`mr-2 ${msg.iconClass}`}
                        style={{ fontSize: '1.5rem' }}></i>
                      <a
                        href={`${site}${msg.url}`}
                        download>
                        {msg.content || msg.url.split('/').pop()}
                      </a>
                    </div>
                  )}
                  <div className='flex justify-content-between align-items-center'>
                    {msg.type === 'text' &&
                      msg.sender_id !== userId &&
                      !itemVisibility[msg.id] && (
                        <div
                          className='translate-button mt-3'
                          onClick={() => translateMessage(msg)}>
                          {t('Translate')}
                          <i className='pi pi-angle-right'></i>
                        </div>
                      )}
                    {loadingTranslate ? (
                      <div>Loading...</div>
                    ) : (
                      itemVisibility[msg.id] && (
                        <div className='translation-box'>
                          <div
                            className='translate-button-2  mt-3'
                            onClick={() => cancelTranslation(msg.id)}>
                            {t('Hide')}
                            <i className='pi pi-angle-up bg-center '></i>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                translatedContent[msg.id]?.[language] ||
                                msg.content,
                            }}></div>
                        </div>
                      )
                    )}
                    <div className='message-time'>
                      {formatDate(msg.send_at)}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </ScrollPanel>
      {inputVisible && (
        <div
          className='flex mt-4'
          style={{ alignItems: 'center' }}>
          <FileUpload
            mode='basic'
            accept='.pdf,.doc,.docx,.xls,.xlsx,image/*'
            auto
            chooseLabel={t('Choose file')}
            className='custom-file-upload'
            onSelect={handleFileUpload}
            style={{ height: '40px' }}
          />

          <InputTextarea
            value={formik.values.content}
            onChange={formik.handleChange}
            name='content'
            placeholder={t('Enter message...')}
            className='flex-grow-1 mr-2'
            style={{ height: '40px' }}
          />
          <Button
            label={t('Send')}
            icon='pi pi-send'
            type='submit'
            style={{ height: '40px' }}
            onClick={() => {
              formik.handleSubmit();
              scrollToBottom();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(TicketDetail);
