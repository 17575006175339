export const GET_COUNTRY_ALL = "GET_COUNTRY_ALL";
export const GET_COUNTRY_ALL_SUCCESS = "GET_COUNTRY_ALL_SUCCESS";
export const GET_COUNTRY_ALL_FAIL = "GET_COUNTRY_ALL_FAIL";

export const GET_COUNTRY_ID = "GET_COUNTRY_ID";
export const GET_COUNTRY_ID_SUCCESS = "GET_COUNTRY_ID_SUCCESS";
export const GET_COUNTRY_ID_FAIL = "GET_COUNTRY_ID_FAIL";

export const SET_COUNTRY = "SET_COUNTRY";
export const SET_COUNTRY_SUCCESS = "SET_COUNTRY_SUCCESS";
export const SET_COUNTRY_FAIL = "SET_COUNTRY_FAIL";

export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const UPDATE_COUNTRY_SUCCESS = "UPDATE_COUNTRY_SUCCESS";
export const UPDATE_COUNTRY_FAIL = "UPDATE_COUNTRY_FAIL";

export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS";
export const DELETE_COUNTRY_FAIL = "DELETE_COUNTRY_FAIL";
