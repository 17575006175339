export const colorMap = {
  China: '#FF5C00',
  Vietnam: '#3749A6',
  Indonesia: '#FFBB38',
  Philippines: '#8280FF',
  Cambodia: '#91929E',
  Laos: '#00A9A9',
  Mongolia: '#565656',
  Bangladesh: '#B83EAC',
  'South Korea': '#8e44ad',
  Japan: '#3498db',
  Thailand: '#e74c3c',
  Malaysia: '#f39c12',
  India: '#EF3826',
  Myamar: '#278502',
};

export function getColorForCountry(country) {
  return colorMap[country] || '#000000'; // Default color if not found
}
