import {
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  REGISTER_ADMIN,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_ADMIN_FAIL,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  VERIFY_KEY,
  VERIFY_KEY_SUCCESS,
  VERIFY_KEY_FAIL,
  VERIFY_KEY_LICENSE,
  VERIFY_KEY_LICENSE_SUCCESS,
  VERIFY_KEY_LICENSE_FAIL,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_INFOR,
  GET_INFOR_SUCCESS,
  GET_INFOR_FAIL,
  SET_DATA_NULL,
} from './actionTypes';

// const userFromStorage = localStorage.getItem("accessToken") ? JSON.parse(localStorage.getItem("accessToken")) : null;

const INIT_STATE = {
  dataById: null,
  inforUser: null,
  dataByUserId: null,
  isAuthenticated: false,
  dataLogin: null,
  error: null,
  loading: false,
  loadingLogin: null,
  errorLogin: null,
  dataRegisterAdmin: null,
  errorRegisterAdmin: null,
  dataVerifyEmail: null,
  loadingVerifyEmail: null,
  errorByEmail: null,
  dataKey: null,
  errorKey: null,
  //change password
  dataChangePassword: null,
  errorChangePassword: null,
  //key license
  dataKeyLicense: null,
  errorKeyLicense: null,
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        dataById: action.payload,
      };
    case GET_USER_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case LOGIN:
      return {
        ...state,
        loadingLogin: true,
        errorLogin: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        dataLogin: action.payload,
        loadingLogin: false,
        errorLogin: null,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        dataLogin: null,
        loadingLogin: false,
        errorLogin: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        dataLogin: null,
        loading: false,
        error: null,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case REGISTER_ADMIN:
      return {
        ...state,
        data: action.payload,
      };
    case REGISTER_ADMIN_SUCCESS:
      return {
        ...state,
        dataRegisterAdmin: action.payload,
      };

    case REGISTER_ADMIN_FAIL:
      return {
        ...state,
        errorRegisterAdmin: action.payload,
      };
    case REGISTER_USER:
      return {
        ...state,
        data: action.payload,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        dataInsert: action.payload,
      };

    case REGISTER_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case VERIFY_KEY:
      return {
        ...state,
        data: action.payload,
      };
    case VERIFY_KEY_SUCCESS:
      return {
        ...state,
        dataKey: action.payload,
      };
    case VERIFY_KEY_FAIL:
      return {
        ...state,
        errorKey: action.payload,
      };

    case VERIFY_KEY_LICENSE:
      return {
        ...state,
        data: action.payload,
      };
    case VERIFY_KEY_LICENSE_SUCCESS:
      return {
        ...state,
        dataKeyLicense: action.payload,
      };
    case VERIFY_KEY_LICENSE_FAIL:
      return {
        ...state,
        errorKeyLicense: action.payload,
      };

    case VERIFY_EMAIL:
      return {
        ...state,
        data: action.payload,
        loadingVerifyEmail: true,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        dataVerifyEmail: action.payload,
        loadingVerifyEmail: false,
      };
    case VERIFY_EMAIL_FAIL:
      return {
        ...state,
        errorByEmail: action.payload,
        loadingVerifyEmail: false,
      };

    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
        data: action.payload,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        dataChangePassword: action.payload,
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        errorChangePassword: action.payload,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload,
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_INFOR:
      return {
        ...state,
        data: action.payload,
      };
    case GET_INFOR_SUCCESS:
      return {
        ...state,
        inforUser: action.payload,
      };
    case GET_INFOR_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case SET_DATA_NULL:
      return {
        ...state,
        dataLogin: null,
        errorLogin: null,
        dataChangePassword: null,
        errorChangePassword: null,
        dataKey: null,
        errorKey: null,
        dataKeyLicense: null,
        errorKeyLicense: null,
        dataVerifyEmail: null,
        errorByEmail: null,
      };

    default:
      return state;
  }
};

export default User;
