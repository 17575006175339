import React, { useCallback, useMemo, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';
import tabMenuTemplate from '../../assets/templete/tabMenuTemplete';

const TicketList = ({
  dataTicketList,
  tabMenuActive,
  setTabMenuActive,
  menu,
  first,
  rows,
  onPageChange,
  setSelectedRow,
  total,
  setGlobal,
}) => {
  const { t } = useTranslation();
  // const language = localStorage.getItem('I18N_LANGUAGE');
  const [searchValue, setSearchValue] = useState('');

  const userBodyTemplate = useCallback((rowData) => {
    const userId = JSON.parse(localStorage.getItem('userId'));
    const user =
      userId == rowData.sender_info.id
        ? rowData.receiver_info
        : rowData.sender_info;

    const userName = user.name || 'Unknown';
    const avatar = user.avata || null;
    return (
      <div className='flex flex-row align-items-center py-2'>
        <img
          src={avatar}
          className='mr-3'
          style={{ width: '36px', height: '36px', borderRadius: '50%' }}
          alt={userName}
        />
        <span className='font-bold'>{userName}</span>
      </div>
    );
  }, []);

  const statusBodyTemplate = useCallback(
    (rowData) => (
      <Tag
        value={t(rowData.ticket_status)}
        severity={
          t(rowData.ticket_status) === t('New')
            ? 'info'
            : t(rowData.ticket_status) === t('Processing')
            ? 'warning'
            : 'success'
        }
      />
    ),
    []
  );

  const formatDate = useMemo(
    () => (date) => {
      const now = new Date();
      const yesterday = new Date(now.getTime() - 86400000);
      const messageDate = new Date(date);
      const isToday = now.toDateString() === messageDate.toDateString();
      const isYesterday =
        yesterday.toDateString() === messageDate.toDateString();

      const timeString = messageDate.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });

      if (isToday) {
        return timeString;
      } else if (isYesterday) {
        return `${t('Yesterday')} ${timeString}`;
      } else {
        return `${messageDate.toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })} ${timeString}`;
      }
    },
    [t]
  );

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setGlobal(e.target.value);
  };

  return (
    <div>
      <div className='flex justify-content-between mb-3'>
        <TabMenu
          model={menu?.map((item, index) => ({
            ...item,
            template: () =>
              tabMenuTemplate(item, index, tabMenuActive, setTabMenuActive),
          }))}
          activeIndex={tabMenuActive}
          onTabChange={(e) => setTabMenuActive(e.index)}
        />
        <IconField
          iconPosition='left'
          className='w-6 mt-3'>
          <InputIcon className='pi pi-search'></InputIcon>
          <InputText
            value={searchValue}
            onChange={handleSearchChange}
            placeholder={t('Search')}
            className='w-full '
            style={{ height: '35px' }}
          />
        </IconField>
      </div>
      <DataTable
        value={dataTicketList}
        selectionMode='single'
        dataKey='id'
        onSelectionChange={(e) => setSelectedRow(e.value)}
        tableStyle={{ minWidth: '50rem' }}
        emptyMessage={t('No data available')}>
        <Column
          field='id'
          header={t('Account name/email')}
          body={userBodyTemplate}></Column>
        <Column
          field='ticket_status'
          header={t('Status')}
          body={statusBodyTemplate}
          textAlign='center'
          align='center'></Column>
        <Column
          field='title'
          header={t('Title')}></Column>
        <Column
          field='send_at'
          header={t('Date Sent')}
          body={(rowData) => formatDate(rowData.send_at)}></Column>
      </DataTable>
      <div className='card'>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={total || 0}
          onPageChange={onPageChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </div>
    </div>
  );
};

export default React.memo(TicketList);
