import React, { useEffect } from "react";
import { Timeline } from "primereact/timeline";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getTimeLine } from "../../store/intern/actions";
import { useTranslation } from "react-i18next";
import { site } from "../../api/url_helper";
function TimeLines({ visible, toast, onHide, internData }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { dataTimeLine } = useSelector(
    (state) => ({
      dataTimeLine: state.Intern.dataTimeLine,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (internData?.id) {
      dispatch(
        getTimeLine({
          page: 1,
          limit: 10,
          intern_id: internData.id,
        })
      );
    }
  }, [dispatch, internData]);

  console.log(dataTimeLine.data);

  const events = (dataTimeLine.data || []).map((item) => {
    return {
      content: (
        <div className="mb-5">
          <p className="font-bold">{new Date(item.event_date).toLocaleDateString()}</p>
          <p className="my-3">{item.content}</p>
          {item.files &&
            item.files.map((file, index) => (
              <img
                key={index}
                src={`${site}${file}`}
                alt="Hình ảnh sự kiện"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  marginRight: "1rem",
                }}
              />
            ))}
        </div>
      ),
      date: new Date(item.event_date).toLocaleDateString(),
    };
  });

  const header = (
    <div className="grid m-3 gap-3 text-xl font-bold">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 4H7v5h4V8H8z" />
        <path
          fill="currentColor"
          d="M16 7V3l-1.1 1.1C13.6 1.6 11 0 8 0C3.6 0 0 3.6 0 8s3.6 8 8 8c2.4 0 4.6-1.1 6-2.8l-1.5-1.3C11.4 13.2 9.8 14 8 14c-3.3 0-6-2.7-6-6s2.7-6 6-6c2.4 0 4.5 1.5 5.5 3.5L12 7z"
        />
      </svg>
      <p>{t("View internship process")}</p>
    </div>
  );

  return (
    <div>
      <Dialog visible={visible} onHide={onHide} style={{ width: "60%", height: "90%" }} header={header}>
        <Timeline value={events} content={(item) => item.content} className="ml-6" />
      </Dialog>
    </div>
  );
}

export default TimeLines;
