export const GET_DISPATCHING_COMPANY_BY_ID = "GET_DISPATCHING_COMPANY_BY_ID";
export const GET_DISPATCHING_COMPANY_BY_ID_SUCCESS = "GET_DISPATCHING_COMPANY_BY_ID_SUCCESS";
export const GET_DISPATCHING_COMPANY_BY_ID_FAIL = "GET_DISPATCHING_COMPANY_BY_ID_FAIL";

export const GET_DISPATCHING_COMPANY_BY_USER_ID = "GET_DISPATCHING_COMPANY_BY_USER_ID";
export const GET_DISPATCHING_COMPANY_BY_USER_ID_SUCCESS = "GET_DISPATCHING_COMPANY_BY_USER_ID_SUCCESS";
export const GET_DISPATCHING_COMPANY_BY_USER_ID_FAIL = "GET_DISPATCHING_COMPANY_BY_USER_ID_FAIL";

export const GET_DISPATCHING_COMPANY_LIST = "GET_DISPATCHING_COMPANY_LIST";
export const GET_DISPATCHING_COMPANY_LIST_SUCCESS = "GET_DISPATCHING_COMPANY_LIST_SUCCESS";
export const GET_DISPATCHING_COMPANY_LIST_FAIL = "GET_DISPATCHING_COMPANY_LIST_FAIL";

export const GET_DISPATCHING_COMPANY_BY_KEY_ID = "GET_DISPATCHING_COMPANY_BY_KEY_ID";
export const GET_DISPATCHING_COMPANY_BY_KEY_ID_SUCCESS = "GET_DISPATCHING_COMPANY_BY_KEY_ID_SUCCESS";
export const GET_DISPATCHING_COMPANY_BY_KEY_ID_FAIL = "GET_DISPATCHING_COMPANY_BY_KEY_ID_FAIL";

export const SET_DISPATCHING_COMPANY = "SET_DISPATCHING_COMPANY";
export const SET_DISPATCHING_COMPANY_SUCCESS = "SET_DISPATCHING_COMPANY_SUCCESS";
export const SET_DISPATCHING_COMPANY_FAIL = "SET_DISPATCHING_COMPANY_FAIL";

export const UPDATE_DISPATCHING_COMPANY = "UPDATE_DISPATCHING_COMPANY";
export const UPDATE_DISPATCHING_COMPANY_SUCCESS = "UPDATE_DISPATCHING_COMPANY_SUCCESS";
export const UPDATE_DISPATCHING_COMPANY_FAIL = "UPDATE_DISPATCHING_COMPANY_FAIL";

export const DELETE_DISPATCHING_COMPANY = "DELETE_DISPATCHING_COMPANY";
export const DELETE_DISPATCHING_COMPANY_SUCCESS = "DELETE_DISPATCHING_COMPANY_SUCCESS";
export const DELETE_DISPATCHING_COMPANY_FAIL = "DELETE_DISPATCHING_COMPANY_FAIL";

export const SET_DATA_NULL = 'SET_DATA_NULL';