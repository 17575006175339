export const GET_ANNOUNCED = 'GET_ANNOUNCED';
export const GET_ANNOUNCED_SUCCESS = 'GET_ANNOUNCED_SUCCESS';
export const GET_ANNOUNCED_FAIL = 'GET_ANNOUNCED_FAIL';

export const SET_ANNOUNCED = 'SET_ANNOUNCED';
export const SET_ANNOUNCED_SUCCESS = 'SET_ANNOUNCED_SUCCESS';
export const SET_ANNOUNCED_FAIL = 'SET_ANNOUNCED_FAIL';

export const GET_LIST_TARGET = 'GET_LIST_TARGET';
export const GET_LIST_TARGET_SUCCESS = 'GET_LIST_TARGET_SUCCESS';
export const GET_LIST_TARGET_FAIL = 'GET_LIST_TARGET_SUCCESS_FAIL';

export const GET_LIST_SYNDICATION = 'GET_LIST_SYNDICATION';
export const GET_LIST_SYNDICATION_SUCCESS = 'GET_LIST_SYNDICATION_SUCCESS';
export const GET_LIST_SYNDICATION_FAIL = 'GET_LIST_SYNDICATION_SUCCESS_FAIL';

export const GET_LIST_FACTORY = 'GET_LIST_FACTORY';
export const GET_LIST_FACTORY_SUCCESS = 'GET_LIST_FACTORY_SUCCESS';
export const GET_LIST_FACTORY_FAIL = 'GET_LIST_FACTORY_SUCCESS_FAIL';

export const GET_LIST_DISPATCH = 'GET_LIST_DISPATCH';
export const GET_LIST_DISPATCH_SUCCESS = 'GET_LIST_DISPATCH_SUCCESS';
export const GET_LIST_DISPATCH_FAIL = 'GET_LIST_DISPATCH_SUCCESS_FAIL';

export const GET_LIST_INTERN = 'GET_LIST_INTERN';
export const GET_LIST_INTERN_SUCCESS = 'GET_LIST_INTERN_SUCCESS';
export const GET_LIST_INTERN_FAIL = 'GET_LIST_INTERN_FAIL';

export const GET_LIST_INTERN_TARGET = 'GET_LIST_INTERN_TARGET';
export const GET_LIST_INTERN_TARGET_SUCCESS = 'GET_LIST_INTERN_TARGET_SUCCESS';
export const GET_LIST_INTERN_TARGET_FAIL = 'GET_LIST_INTERN_TARGET_FAIL';

export const SET_DATA_NULL = 'SET_DATA_NULL';
