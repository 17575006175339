import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Image } from "primereact/image";
import { InputSwitch } from "primereact/inputswitch";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { setDataNull, setDispatchingCompany } from "../../store/dispatching_company/actions";
import { getCountryAll } from "../../store/country/actions";
import AddressDetail from "../../components/AddressDetail";
import { getUsernameRandom } from "../../ultis/GetRandomCode";
import { ObjectType } from "../../components/Interface";

const ModelAddDispatchingCompany = ({ visible, onHide, toast }) => {
  const [listAddress, setListAddress] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const avata = "./images/avatas/logo-dispatching-null.svg";
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAvata, setShowAvata] = useState(avata);
  const [isLogin, setIsLogin] = useState(false);

  const callBackData = (data) => {
    setListAddress(data);
  };

  const { errorInsert, dataInsert, dataCountry } = useSelector(
    (state) => ({
      errorInsert: state.DispatchingCompany.errorInsert,
      dataInsert: state.DispatchingCompany.dataInsert,
      dataCountry: state.Country.datas,
    }),
    shallowEqual
  );

  // Lấy file ảnh
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setShowAvata(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // tao schema khi khong tao tk
  const withoutAccountSchema = Yup.object().shape({
    name_jp: Yup.string().required(t("This value is required")),
    name_en: Yup.string().required(t("This value is required")),
    country: Yup.string().required(t("This value is required")),
    date_of_joining_syndication: Yup.date().max(new Date(), t("Date select must be before current date")),
  });

  const withAccountSchema = Yup.object().shape({
    name_jp: Yup.string().required(t("This value is required")),
    name_en: Yup.string().required(t("This value is required")),
    password: Yup.string().min(6, t("This value is required")).required(t("This value is required")),
    username: Yup.string().required("Username is required"),
    date_of_joining_syndication: Yup.date().max(new Date(), t("Date select must be before current date")),
    country: Yup.string().required(t("This value is required")),
  });

  const getValidationSchema = () => {
    if (isLogin) {
      return withAccountSchema;
    } else {
      return withoutAccountSchema;
    }
  };

  useEffect(() => {
    dispatch(getCountryAll());
  }, [dispatch]);

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      avata: "avatar-null.png",
      name_jp: "",
      name_en: "",
      country: "",
      date_of_joining_syndication: "",
      code: "",
      description: "",
      username: getUsernameRandom(),
      password: process.env.REACT_APP_PASSWORD_DEFAULT,
    },
    validationSchema: getValidationSchema(),
    onSubmit: async (value) => {
      const data = {
        ...value,
        is_login: isLogin,
        addresses: listAddress,
        date_of_joining_syndication: value.date_of_joining_syndication
          ? new Date(value.date_of_joining_syndication).toISOString()
          : "",
      };

      dispatch(setDispatchingCompany(data, selectedFile));
    },
  });

  useEffect(() => {
    if (errorInsert) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: errorInsert.mes,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (dataInsert) {
      toast.current?.show({
        severity: "success",
        summary: "success",
        detail: "Add Dispatching Success",
        life: 3000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      setShowAvata(avata);
      onHide();
    }
    // eslint-disable-next-line
  }, [errorInsert, dataInsert]);

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.CountryName_en} src={option.Avatar} className={`mr-2 }`} style={{ width: "18px" }} />
          <div>{option.CountryName_en}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img alt={option.CountryName_en} src={option.Avatar} className={`mr-2 }`} style={{ width: "18px" }} />
        <div>{option.CountryName_en}</div>
      </div>
    );
  };

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        setShowAvata(avata);
        onHide();
      }}
      header={t("Add Dispatching Company")}
      modal
      style={{ width: "90vw", height: "90vh" }}
      footer={
        <div>
          <Button
            label={t("Cancel")}
            onClick={() => {
              formik.resetForm();
              setShowAvata(avata);
              onHide();
            }}
            severity="secondary"
            className="p-button-text "
          />
          <Button type="submit" label={t("Save")} onClick={formik.handleSubmit} />
        </div>
      }
      pt={{
        content: "surface-100 pt-3",
        footer: "surface-100",
        header: "py-3 pl-4",
      }}
    >
      <div className="flex flex-row gap-5 mb-5">
        <div className="bg-white w-2 p-3 border-round-md">
          <div className="flex justify-content-center	mb-3 border-round-md">
            <div>
              <Image src={showAvata} alt="Image" width="93" height="93" />
              <div className="my-2">
                <Button
                  name="icon"
                  type="file"
                  label={t("Upload logo")}
                  size="small"
                  severity="secondary"
                  outlined
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                />
                <input
                  onChange={handleFileChange}
                  multiple={false}
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  hidden
                  name="icon"
                  id="icon"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex align-items-center mb-3">
              <InputSwitch checked={isLogin} onChange={(e) => setIsLogin(e.value)} />
              <label className="font-bold ml-2">{t("Allow login")}</label>
            </div>
            {isLogin && (
              <div>
                <InputText
                  name="username"
                  placeholder={t("Username")}
                  className="w-full mb-1"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  invalid={formik.touched.username && formik.errors.username ? true : false}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div style={{ color: "red" }}>{formik.errors.username.toString()}</div>
                ) : null}
                <InputText
                  name="password"
                  placeholder={t("Password")}
                  className="w-full mt-1 mb-1"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  invalid={formik.touched.password && formik.errors.password ? true : false}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div style={{ color: "red" }}>{formik.errors.password.toString()}</div>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <div className="bg-white w-10 p-3 border-round-md">
          <div className="flex flex-row mb-4 gap-3">
            <div className="flex flex-column w-6">
              <label className="mb-2">{t("Company name")}</label>
              <InputText
                id="name_jp"
                name="name_jp"
                value={formik.values.name_jp}
                onChange={formik.handleChange}
                invalid={formik.touched.name_jp && formik.errors.name_jp ? true : false}
              />
              {formik.touched.name_jp && formik.errors.name_jp ? (
                <div style={{ color: "red", marginTop: "5px" }}>{formik.errors.name_jp.toString()}</div>
              ) : null}
            </div>
            <div className="flex flex-column w-6">
              <label className="mb-2">{t("Company name(English)")}</label>
              <InputText
                id="name_en"
                name="name_en"
                value={formik.values.name_en}
                onChange={formik.handleChange}
                invalid={formik.touched.name_en && formik.errors.name_en ? true : false}
              />
              {formik.touched.name_en && formik.errors.name_en ? (
                <div style={{ color: "red", marginTop: "5px" }}>{formik.errors.name_en.toString()}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-row mb-4 gap-3">
            <div className="flex flex-column w-6">
              <label className="mb-2">{t("Country")}</label>
              <FloatLabel>
                <Dropdown
                  options={dataCountry.filter((country) => country.CountryName_en != "Japan")}
                  name="country"
                  value={formik.values.country}
                  onChange={(e) => {
                    formik.setFieldValue("country", e.value);
                  }}
                  filter
                  optionLabel="CountryName_en"
                  optionValue="CountryName_en"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  emptyFilterMessage
                  placeholder={t("Country")}
                  pt={{ list: "p-0", root: "w-full", header: "p-3" }}
                  invalid={formik.touched.country && formik.errors.country ? true : false}
                />
                <label htmlFor="country">{t("")}</label>
              </FloatLabel>
              {formik.touched.country && formik.errors.country ? (
                <div style={{ color: "red", marginTop: "5px" }}>{formik.errors.country.toString()}</div>
              ) : null}
            </div>
            <div className="flex flex-row w-6">
              <div className="w-6 flex flex-column">
                <label className="mb-2">{t("Date of joining the company")}</label>
                <FloatLabel>
                  <Calendar
                    value={formik.values.date_of_joining_syndication}
                    onChange={(e) => formik.setFieldValue("date_of_joining_syndication", e.value)}
                    showIcon
                    dateFormat="dd/mm/yy"
                    className="w-full"
                    id="date_of_joining_syndication"
                  />
                  <label htmlFor="date_of_joining_syndication">{t("")}</label>
                </FloatLabel>
                {formik.touched.date_of_joining_syndication && formik.errors.date_of_joining_syndication ? (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {formik.errors.date_of_joining_syndication.toString()}
                  </div>
                ) : null}
              </div>
              <div className="w-6 flex flex-column ml-1">
                <label className="mb-2">{t("Company Code")}</label>
                <InputText
                  id="code"
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  invalid={formik.touched.code && formik.errors.code ? true : false}
                />
                {formik.touched.code && formik.errors.code ? (
                  <div style={{ color: "red", marginTop: "5px" }}>{formik.errors.code.toString()}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex flex-column mb-2">
            <label className="mb-2">{t("Description")}</label>
            <InputTextarea
              id="description"
              name="description"
              rows={3}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </div>
      <AddressDetail object_id={null} object_type={ObjectType.DISPATCHING_COMPANY} callBackData={callBackData} />
    </Dialog>
  );
};

export default ModelAddDispatchingCompany;
