export const GET_TICKET_LIST = "GET_TICKET_LIST";
export const GET_TICKET_LIST_SUCCESS = "GET_TICKET_LIST_SUCCESS";
export const GET_TICKET_LIST_FAIL = "GET_TICKET_LIST_FAIL";

export const GET_TICKET_DETAIL_LIST = "GET_TICKET_DETAIL_LIST";
export const GET_TICKET_DETAIL_LIST_SUCCESS = "GET_TICKET_DETAIL_LIST_SUCCESS";
export const GET_TICKET_DETAIL_LIST_FAIL = "GET_TICKET_DETAIL_LIST_FAIL";

export const SET_TICKET = "SET_TICKET";
export const SET_TICKET_SUCCESS = "SET_TICKET_SUCCESS";
export const SET_TICKET_FAIL = "SET_TICKET_FAIL";

export const RECEIVER_TICKET = "RECEIVER_TICKET";

export const SET_REPLY_TICKET = "SET_REPLY_TICKET";
export const SET_REPLY_TICKET_SUCCESS = "SET_REPLY_TICKET_SUCCESS";
export const SET_REPLY_TICKET_FAIL = "SET_REPLY_TICKET_FAIL";

export const UPDATE_TICKET = "UPDATE_TICKET";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAIL = "UPDATE_TICKET_FAIL";

export const TRANSLATE_TICKET = "TRANSLATE_TICKET";
export const TRANSLATE_TICKET_SUCCESS = "TRANSLATE_TICKET_SUCCESS";
export const TRANSLATE_TICKET_FAIL = "TRANSLATE_TICKET_FAIL";

export const SET_DATA_NULL = "SET_DATA_NULL";

export const GET_LIST_USER_REQUEST = "GET_LIST_USER_REQUEST";
export const GET_LIST_USER_SUCCESS = "GET_LIST_USER_SUCCESS";
export const GET_LIST_USER_FAILURE = "GET_LIST_USER_FAILURE";

export const CLOSE_TICKET = "CLOSE_TICKET";
export const CLOSE_TICKET_SUCCESS = "CLOSE_TICKET_SUCCESS";
export const CLOSE_TICKET_FAIL = "CLOSE_TICKET_FAIL";
