import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import {
  GET_RECEIVING_FACTORY_BY_ID,
  GET_RECEIVING_FACTORY_BY_USER_ID,
  GET_RECEIVING_FACTORY_BY_KEY_ID,
  SET_RECEIVING_FACTORY,
  UPDATE_RECEIVING_FACTORY,
  DELETE_RECEIVING_FACTORY,
  GET_RECEIVING_FACTORY_LIST,
} from "./actionTypes";
import {
  getReceivingFactoryByIdSuccess,
  getReceivingFactoryByIdFail,
  getReceivingFactoryByUserIdSuccess,
  getReceivingFactoryByUserIdFail,
  getReceivingFactoryByKeyIdSuccess,
  getReceivingFactoryByKeyIdFail,
  setReceivingFactorySuccess,
  setReceivingFactoryFail,
  updateReceivingFactorySuccess,
  updateReceivingFactoryFail,
  deleteReceivingFactorySuccess,
  deleteReceivingFactoryFail,
  getReceivingFactoryGetListSuccess,
  getReceivingFactoryGetListFail,
} from "./actions";

import {
  getReceivingFactoryDataById,
  getReceivingFactoryDataByUserId,
  getReceivingFactoryDataByKeyId,
  deleteDataReceivingFactory,
  getReceivingFactoryDataList,
} from "../../api/backend_helper";
import axios from "axios";
import * as URL_API from "../../api/url_helper";

function* fetReceivingFactoryDataId({ payload: id }) {
  try {
    const response = yield call(getReceivingFactoryDataById, id);
    yield put(getReceivingFactoryByIdSuccess(response));
  } catch (error) {
    yield put(getReceivingFactoryByIdFail(error.response.data));
  }
}

function* fetReceivingFactoryDataByUserId() {
  try {
    const response = yield call(getReceivingFactoryDataByUserId);
    yield put(getReceivingFactoryByUserIdSuccess(response));
  } catch (error) {
    yield put(getReceivingFactoryByUserIdFail(error.response.data));
  }
}

// list
function* fetReceivingFactoryDataGetList() {
  try {
    const response = yield call(getReceivingFactoryDataList);
    yield put(getReceivingFactoryGetListSuccess(response));
  } catch (error) {
    yield put(getReceivingFactoryGetListFail(error.response.data));
  }
}

function* fetReceivingFactoryDataByKeyId() {
  try {
    const response = yield call(getReceivingFactoryDataByKeyId);
    yield put(getReceivingFactoryByKeyIdSuccess(response));
  } catch (error) {
    yield put(getReceivingFactoryByKeyIdFail(error.response.data));
  }
}

function* onAddNewReceivingFactory(action) {
  try {
    const { values, file } = action.payload;
    const receiving_factory = new FormData();
    receiving_factory.append("name_jp", values.name_jp);
    receiving_factory.append("name_en", values.name_en);
    receiving_factory.append("description", values.description);
    receiving_factory.append("addresses", JSON.stringify(values.addresses));
    receiving_factory.append("code", values.code);
    receiving_factory.append(
      "date_of_joining_syndication",
      values.date_of_joining_syndication
    );
    receiving_factory.append("username", values.username);
    receiving_factory.append("password", values.password);
    receiving_factory.append("is_login", values.is_login);

    if (file) {
      receiving_factory.append("file", file);
    }

    const response = yield call(
      axios.post,
      URL_API.SET_RECEIVING_FACTORY,
      receiving_factory,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // Convert Axios headers to a plain object
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };
    yield put(setReceivingFactorySuccess(dataUpdate));
  } catch (error) {
    yield put(setReceivingFactoryFail(error.response.data));
  }
}

function* onUpdateReceivingFactory(action) {
  try {
    const { values, file } = action.payload;

    const receiving_factory = new FormData();
    receiving_factory.append("id", values.id);
    receiving_factory.append("name_jp", values.name_jp);
    receiving_factory.append("name_en", values.name_en);
    receiving_factory.append("description", values.description);
    receiving_factory.append("addresses", JSON.stringify(values.addresses));
    receiving_factory.append("code", values.code);
    receiving_factory.append(
      "date_of_joining_syndication",
      values.date_of_joining_syndication
    );
    receiving_factory.append("username", values.username);
    receiving_factory.append("password", values.password);
    receiving_factory.append("is_login", values.is_login);
    receiving_factory.append("type", values.type);

    if (file) {
      receiving_factory.append("file", file);
    }

    const response = yield call(
      axios.put,
      URL_API.UPDATE_RECEIVING_FACTORY,
      receiving_factory,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // Convert Axios headers to a plain object
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };
    yield put(updateReceivingFactorySuccess(dataUpdate));
  } catch (error) {
    yield put(updateReceivingFactoryFail(error.response.data));
  }
}

function* onDeleteReceivingFactory({ payload: id }) {
  try {
    const response = yield call(deleteDataReceivingFactory, id);
    yield put(deleteReceivingFactorySuccess(response));
  } catch (error) {
    yield put(deleteReceivingFactoryFail(error.response.data));
  }
}

function* ReceivingFactorySaga() {
  yield takeEvery(GET_RECEIVING_FACTORY_BY_ID, fetReceivingFactoryDataId);
  yield takeEvery(
    GET_RECEIVING_FACTORY_BY_USER_ID,
    fetReceivingFactoryDataByUserId
  );
  yield takeEvery(
    GET_RECEIVING_FACTORY_BY_KEY_ID,
    fetReceivingFactoryDataByKeyId
  );
  yield takeEvery(GET_RECEIVING_FACTORY_LIST, fetReceivingFactoryDataGetList);
  yield takeLatest(SET_RECEIVING_FACTORY, onAddNewReceivingFactory);
  yield takeEvery(UPDATE_RECEIVING_FACTORY, onUpdateReceivingFactory);
  yield takeEvery(DELETE_RECEIVING_FACTORY, onDeleteReceivingFactory);
}

export default ReceivingFactorySaga;
