import { takeEvery, put, call } from "redux-saga/effects";

import {
  GET_ANNOUNCED,
  SET_ANNOUNCED,
  GET_LIST_TARGET,
  GET_LIST_SYNDICATION,
  GET_LIST_FACTORY,
  GET_LIST_DISPATCH,
  GET_LIST_INTERN,
  GET_LIST_INTERN_TARGET,
} from "./actionTypes";

import socketInstance from "../../Singleton/SocketSingleton";

import {
  getAnnouncedFail,
  getAnnouncedSuccess,
  setAnnouncedFail,
  setAnnouncedSuccess,
  getListTargetSuccess,
  getListTargetFail,
  getListSyndicationSuccess,
  getListSyndicationFail,
  getListFactorySuccess,
  getListFactoryFail,
  getListDispatchSuccess,
  getListDispatchFail,
  getListInternSuccess,
  getListInternFail,
  getListInternTargetSuccess,
  getListInternTargetFail,
} from "./actions";

import {
  getAnnounced,
  setAnnounced,
  getListTarget,
  getListInternTarget,
} from "../../api/backend_helper";

function* onGetAnnounced({ payload: query }) {
  try {
    const response = yield call(getAnnounced, query);
    yield put(getAnnouncedSuccess(response));
  } catch (error) {
    yield put(getAnnouncedFail(error.response.data));
  }
}

function* onSetAnnounced({ payload: data }) {
  try {
    console.log("🚀 ~ function*onSetAnnounced ~ data:", data);

    const response = yield call(setAnnounced, data);

    const socket = socketInstance.getSocket();

    // // Gửi socket khi reply thành công
    if (socket) {
      socket.emit("send-notifications", {
        receiver: data.receiver_list,
      });
    }

    yield put(setAnnouncedSuccess(response));
  } catch (error) {
    yield put(setAnnouncedFail(error.response.data));
  }
}

function* onGetListTarget({ payload: query }) {
  try {
    const response = yield call(getListTarget, query);
    yield put(getListTargetSuccess(response));
  } catch (error) {
    yield put(getListTargetFail(error.response.data));
  }
}

function* onGetListSyndication({ payload: query }) {
  try {
    const response = yield call(getListTarget, query);
    yield put(getListSyndicationSuccess(response));
  } catch (error) {
    yield put(getListSyndicationFail(error.response.data));
  }
}

function* onGetListFactory({ payload: query }) {
  try {
    const response = yield call(getListTarget, query);
    yield put(getListFactorySuccess(response));
  } catch (error) {
    yield put(getListFactoryFail(error.response.data));
  }
}

function* onGetListDispatch({ payload: query }) {
  try {
    const response = yield call(getListTarget, query);
    yield put(getListDispatchSuccess(response));
  } catch (error) {
    yield put(getListDispatchFail(error.response.data));
  }
}

function* onGetListIntern({ payload: query }) {
  try {
    const response = yield call(getListTarget, query);
    yield put(getListInternSuccess(response));
  } catch (error) {
    yield put(getListInternFail(error.response.data));
  }
}

function* onGetListInternTarget({ payload: data }) {
  try {
    const response = yield call(getListInternTarget, data);
    yield put(getListInternTargetSuccess(response));
  } catch (error) {
    yield put(getListInternTargetFail(error.response.data));
  }
}

function* AnnouncedSaga() {
  yield takeEvery(GET_ANNOUNCED, onGetAnnounced);
  yield takeEvery(SET_ANNOUNCED, onSetAnnounced);
  yield takeEvery(GET_LIST_TARGET, onGetListTarget);
  yield takeEvery(GET_LIST_SYNDICATION, onGetListSyndication);
  yield takeEvery(GET_LIST_FACTORY, onGetListFactory);
  yield takeEvery(GET_LIST_DISPATCH, onGetListDispatch);
  yield takeEvery(GET_LIST_INTERN, onGetListIntern);
  yield takeEvery(GET_LIST_INTERN_TARGET, onGetListInternTarget);
}

export default AnnouncedSaga;
