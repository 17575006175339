import { Dialog } from "primereact/dialog";

const NotiDialog = ({ visible, onHide, success, failed, failedUrl }) => {
  const header = <p>Kết quả</p>;
  return (
    <Dialog
      visible={visible}
      onHide={() => {
        onHide();
      }}
      header={header}
      style={{ width: "30%" }}
    >
      <div className="surface-100 border-round-md p-3 line-height-4">
        <div className="font-bold text-green-700">Thành công: {success}</div>
        <span className="text-red-500 font-bold">
          Thất bại: {failed}
          <a
            className="text-blue-500 underline cursor-pointer ml-2"
            href={failedUrl}
            download={failedUrl}
          >
            Tải file
          </a>
        </span>
      </div>
    </Dialog>
  );
};

export default NotiDialog;
