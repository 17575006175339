
export const GET_VIOLATE_TYPE_BY_ID = "GET_VIOLATE_TYPE_BY_ID";
export const GET_VIOLATE_TYPE_BY_ID_SUCCESS = "GET_VIOLATE_TYPE_BY_ID_SUCCESS";
export const GET_VIOLATE_TYPE_BY_ID_FAIL = "GET_VIOLATE_TYPE_BY_ID_FAIL";

export const GET_VIOLATE_TYPE_BY_USER_ID = "GET_VIOLATE_TYPE_BY_USER_ID";
export const GET_VIOLATE_TYPE_BY_USER_ID_SUCCESS = "GET_VIOLATE_TYPE_BY_USER_ID_SUCCESS";
export const GET_VIOLATE_TYPE_BY_USER_ID_FAIL = "GET_VIOLATE_TYPE_BY_USER_ID_FAIL";

export const SET_VIOLATE_TYPE = "SET_VIOLATE_TYPE";
export const SET_VIOLATE_TYPE_SUCCESS = "SET_VIOLATE_TYPE_SUCCESS";
export const SET_VIOLATE_TYPE_FAIL = "SET_VIOLATE_TYPE_FAIL";

export const UPDATE_VIOLATE_TYPE = "UPDATE_VIOLATE_TYPE";
export const UPDATE_VIOLATE_TYPE_SUCCESS = "UPDATE_VIOLATE_TYPE_SUCCESS";
export const UPDATE_VIOLATE_TYPE_FAIL = "UPDATE_VIOLATE_TYPE_FAIL";

export const DELETE_VIOLATE_TYPE = "DELETE_VIOLATE_TYPE";
export const DELETE_VIOLATE_TYPE_SUCCESS = "DELETE_VIOLATE_TYPE_SUCCESS";
export const DELETE_VIOLATE_TYPE_FAIL = "DELETE_VIOLATE_TYPE_FAIL";

export const SET_DATA_NULL = 'SET_DATA_NULL';