import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useTranslation } from "react-i18next";
import { updateCareer } from "../../../store/career/actions";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from 'yup';

const EditCareerDialog = ({ visible, onHide, toast, rowData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: rowData?.id,
      name: rowData?.name,
      description: rowData?.description,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      const res = dispatch(updateCareer(values));
      if(res) {
          formik.resetForm();
          toast.current?.show({ severity: 'success', summary: 'success', detail: 'Add career Success', life: 3000 });
      }
      onHide();
    },
  });

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={t("Edit Career")}
      modal
      style={{ width: "50rem", top: "-15%" }}
      footer={
        <div>
          <Button
            label="Cancel"
            onClick={onHide}
            severity="secondary"
            className="p-button-text"
          />
          <Button
            type="submit"
            label="Save"
            onClick={() => formik.handleSubmit()}
          />
        </div>
      }
    >
      <div className="flex flex-column mb-4">
        <label className="mb-2">Career name</label>
        <InputText
          id="name"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
      </div>
      <div className="flex flex-column mb-3">
        <label className="mb-2">Description</label>
        <InputTextarea
          id="description"
          rows={5}
          value={formik.values.description}
          onChange={formik.handleChange}
        />
      </div>
    </Dialog>
  );
};

export default EditCareerDialog;
