import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ActionMenu from "../../components/ActionMenu";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getReceivingFactoryGetList } from "../../store/receiving_factory/actions";
import { getSyndicationGetList } from "../../store/syndication/actions";
import { getDispatchingCompanyGetList } from "../../store/dispatching_company/actions";
import { getCareerByUserId } from "../../store/career/actions";
import {
    getBranchReceiving,
    getBranchSyndication,
    getBranchDispatching,
    setDataNull as setDataAddressFullNull,
} from "../../store/address/actions";
import { getListCard } from "../../store/intern/actions";
import { residenceItems } from "../../contexts/data/defaultData";
import {
    dropdownSelected,
    dropdownOption,
} from "../../assets/templete/dropdownTemplete";
import CircleImage from "../../components/CircleImage";
import { setDataNull, updateIntern } from "../../store/intern/actions";
// import { site } from '../../api/url_helper';
import { ObjectType } from "../../components/Interface";
import AddressDetail from "../../components/AddressDetail";
import moment from "moment";
import AlienCard from "./AlienCard";
import { ConvertLinkGoogleDrive } from "../../ultis/ConvertLinkGoogleDrive";

const EditInternDialog = ({ visible, onHide, toast, internData }) => {
    const [listAddress, setListAddress] = useState([]);
    const { t } = useTranslation();
    const [selectReceivingFactory, setSelectReceivingFactory] = useState(null);
    const [selectSyndication, setSelectSyndication] = useState(null);
    const [selectDispaching, setSelectDispatching] = useState(null);

    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState(null);
    const admin = JSON.parse(localStorage.getItem("object_type"));
    const adminId = JSON.parse(localStorage.getItem("object_id"));
    // const avata = './images/avatas/avatar-null.png';
    const avata = "images/avatas/logo-intern-null.svg";
    const checked_icon = "/images/image/check.png";
    const fileInputRef = useRef(null);
    const [showAvata, setShowAvata] = useState(avata);

    const [statusIdsToUpdate, setStatusIdsToUpdate] = useState([]);
    const [cardDialog, setCardDialog] = useState(false);
    const [editingCard, setEditingCard] = useState(null);
    const [cardList, setCardList] = useState([]);

    const [isLogin, setIsLogin] = useState(false);
    const [isHasAccount, setIsHasAccount] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const callBackData = (data) => {
        setListAddress(data);
    };

    const {
        dataCountry,
        dataStatus,
        dataReceiving,
        dataBranchReceiving,
        dataSydication,
        dataBranchSyndication,
        dataDispatching,
        dataBranchDispatching,
        dataCareer,
        errorUpdate,
        dataUpdate,
        dataListCard,
    } = useSelector(
        (state) => ({
            dataCountry: state.Country.datas,
            dataStatus: state.Status.dataByUserId,
            dataReceiving: state.ReceivingFactory.dataGetList,
            dataBranchReceiving: state.Address.dataBranchReceving,
            dataSydication: state.Syndication.dataGetList,
            dataBranchSyndication: state.Address.dataBranchSyndication,
            dataDispatching: state.DispatchingCompany.dataGetList,
            dataBranchDispatching: state.Address.dataBranchDispatching,
            dataCareer: state.Career.dataByUserId,
            errorUpdate: state.Intern.errorUpdate,
            dataUpdate: state.Intern.dataUpdate,
            dataListCard: state.Intern.dataListCard.data,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (internData) {
            dispatch(getListCard({ intern_id: internData.id }));
        }
    }, [internData]);

    useEffect(() => {
        if (dataListCard) {
            setCardList(dataListCard);
        }
    }, [dataListCard]);

    const openDialog = () => {
        setEditingCard(null);
        setCardDialog(true);
    };

    const genderItems = [
        {
            value: "Male",
            name: t("male"),
        },
        {
            value: "Female",
            name: t("female"),
        },
    ];

    const residenceItems = [
        {
            code: 1,
            name: t("Intern training (i)"),
            value: "Intern training (i)",
        },
        {
            code: 2,
            name: t("Intern training (ii) (2nd year)"),
            value: "Intern training (ii) (2nd year)",
        },
        {
            code: 3,
            name: t("Intern training (ii) (3nd year)"),
            value: "Intern training (ii) (3nd year)",
        },
        {
            code: 4,
            name: t("Technical Intern training No. (iii) (4th year)"),
            value: "Technical Intern training No. (iii) (4th year)",
        },
        {
            code: 5,
            name: t("Technical Intern training No. (iii) (5th year)"),
            value: "Technical Intern training No. (iii) (5th year)",
        },
        {
            code: 6,
            name: t("Specification"),
            value: "Specification",
        },
    ];

    useEffect(() => {
        dispatch(getCareerByUserId());
        dispatch(getSyndicationGetList());
        dispatch(getReceivingFactoryGetList());
        dispatch(getDispatchingCompanyGetList());
    }, []);

    // Lắng nghe sự thay đổi khi vào edit
    useEffect(() => {
        if (internData) {
            const selectRowDispatching = dataDispatching?.find(
                (e) => e.id === internData?.dispatching_company_id
            );
            setSelectDispatching(selectRowDispatching);

            const selectRowSyndication = dataSydication?.find(
                (e) => e.id === internData?.syndication_id
            );
            setSelectSyndication(selectRowSyndication);

            const selectRowReceiving = dataReceiving?.find(
                (e) => e.id === internData?.receiving_factory_id
            );
            setSelectReceivingFactory(selectRowReceiving);
        }
    }, [internData]);

    // Lấy danh sách branch
    useEffect(() => {
        if (selectReceivingFactory) {
            const data = {
                object_id: selectReceivingFactory.id,
                object_type: selectReceivingFactory.object_type,
            };
            dispatch(getBranchReceiving(data));
        }
    }, [selectReceivingFactory]);

    useEffect(() => {
        if (selectSyndication) {
            const data = {
                object_id: selectSyndication.id,
                object_type: selectSyndication.object_type,
            };
            dispatch(getBranchSyndication(data));
        }
    }, [selectSyndication]);

    useEffect(() => {
        if (selectDispaching) {
            const data = {
                object_id: selectDispaching.id,
                object_type: selectDispaching.object_type,
            };
            dispatch(getBranchDispatching(data));
        }
    }, [selectDispaching]);

    // Lấy dữ liệu ban đầu từ local để list branch để gán giá trị branch khi ban đầu cập nhật
    useEffect(() => {
        if (admin === ObjectType.RECEIVING_FACTORY) {
            const data = {
                object_id: adminId,
                object_type: admin,
            };
            dispatch(getBranchReceiving(data));
        } else if (admin === ObjectType.SYNDICATION) {
            const data = {
                object_id: adminId,
                object_type: admin,
            };
            dispatch(getBranchSyndication(data));
        } else {
            const data = {
                object_id: adminId,
                object_type: admin,
            };
            dispatch(getBranchDispatching(data));
        }
    }, []);

    // Thay đổi file
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setShowAvata(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // tao schema khi khong tao tk
    const withoutAccountSchema = Yup.object().shape({
        // last_name_jp: Yup.string().required(t("This value is required")),
        // first_name_jp: Yup.string().required(t("This value is required")),
        country: Yup.string().required(t("This value is required")),
        gender: Yup.string().required(t("This value is required")),
        dob: Yup.date()
            .max(new Date(), t("This value is required"))
            .required("Dob date is required"),
        // phone_domestically: Yup.string().required(t("This value is required")),
        syndication_id: Yup.string().required(t("This value is required")),
        receiving_factory_id: Yup.string().required(
            t("This value is required")
        ),
        dispatching_company_id: Yup.string().required(
            t("This value is required")
        ),
    });

    const withAccountSchema = Yup.object().shape({
        // last_name_jp: Yup.string().required(t("This value is required")),
        // first_name_jp: Yup.string().required(t("This value is required")),
        country: Yup.string().required(t("This value is required")),
        gender: Yup.string().required(t("This value is required")),
        dob: Yup.date()
            .max(new Date(), t("This value is required"))
            .required("Dob date is required"),
        // phone_domestically: Yup.string().required(t("This value is required")),
        syndication_id: Yup.string().required(t("This value is required")),
        receiving_factory_id: Yup.string().required(
            t("This value is required")
        ),
        dispatching_company_id: Yup.string().required(
            t("This value is required")
        ),
        password: Yup.string()
            .min(6, t("This value is required"))
            .required(t("This value is required")),
        username: Yup.string().required(t("This value is required")),
    });

    // check login
    const getValidationSchema = () => {
        if (isLogin) {
            return withAccountSchema;
        } else {
            return withoutAccountSchema;
        }
    };

    // Lấy và lọc theo 1 mảng [1,2,3]
    useEffect(() => {
        if (internData) {
            const statusIds = internData?.intern_status_detail_info?.map(
                (item) => item.status_id
            );
            setStatusIdsToUpdate(statusIds);
        }
    }, [internData]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: internData ? internData?.id : "",
            avata: "",
            username: internData?.intern_user_info
                ? internData?.intern_user_info.username
                : "",
            password: isActive
                ? "******"
                : process.env.REACT_APP_PASSWORD_DEFAULT,
            is_login: internData?.intern_user_info
                ? internData?.intern_user_info.active
                : "",
            last_name_jp: internData ? internData?.last_name_jp : "",
            middle_name_jp: internData ? internData?.middle_name_jp : "",
            first_name_jp: internData ? internData?.first_name_jp : "",
            last_name_en: internData ? internData?.last_name_en : "",
            middle_name_en: internData ? internData?.middle_name_en : "",
            first_name_en: internData ? internData?.first_name_en : "",
            country: internData ? internData?.country : "",
            gender: internData ? internData?.gender : "",
            dob: internData?.dob ? new Date(internData?.dob) : "",
            passport_code: internData ? internData?.passport_code : "", // Mã hộ chiếu
            passport_license_date: internData?.passport_license_date
                ? new Date(internData?.passport_license_date)
                : "",
            passport_expiration_date: internData?.passport_expiration_date
                ? new Date(internData?.passport_expiration_date)
                : "",
            phone_domestically:
                internData?.phone_domestically == "null"
                    ? ""
                    : internData?.phone_domestically, // số điện thoại trong nước
            phone_abroad:
                internData?.phone_abroad == "null"
                    ? ""
                    : internData?.phone_abroad, // Số điẹn thoại nước ngoài
            email: internData?.email == "null" ? "" : internData?.email,
            description: internData ? internData?.description : "",
            status_ids: internData ? statusIdsToUpdate : [], // Trạng thái thanh 1 mang
            receiving_factory_id:
                admin === ObjectType.RECEIVING_FACTORY
                    ? adminId
                    : internData?.receiving_factory_id,
            receiving_factory_branch_id: internData
                ? internData?.receiving_factory_branch_id
                : "", // chi nhanh cua xi nghiep
            dispatching_company_id:
                admin === ObjectType.DISPATCHING_COMPANY
                    ? adminId
                    : internData?.dispatching_company_id,
            dispatching_company_branch_id: internData
                ? internData?.dispatching_company_branch_id
                : "",
            syndication_id:
                admin === ObjectType.SYNDICATION
                    ? adminId
                    : internData?.syndication_id,
            syndication_branch_id: internData
                ? internData?.syndication_branch_id
                : "",
            entry_date: internData?.entry_date
                ? new Date(internData?.entry_date)
                : "", // Ngày nhập cảnh
            career_id: internData ? internData?.career_id : "",
            alien_registration_cards: [],
        },
        validationSchema: getValidationSchema(),
        onSubmit: async (values) => {
            const data = {
                ...values,
                is_login: isLogin,
                dob: moment(values.dob).utcOffset(+9).format("YYYY/MM/DD"),
                passport_license_date: moment(values.passport_license_date)
                    .utcOffset(+9)
                    .format("YYYY/MM/DD"),
                passport_expiration_date: moment(
                    values.passport_expiration_date
                )
                    .utcOffset(+9)
                    .format("YYYY/MM/DD"),
                entry_date: moment(values.entry_date)
                    .utcOffset(+9)
                    .format("YYYY/MM/DD"),
                addresses: listAddress,
                alien_registration_cards: cardList,
            };
            dispatch(updateIntern(data, selectedFile));
        },
    });

    // format lá cờ
    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img
                        alt={option.CountryName_vi}
                        src={`${option.Avatar}`}
                        className={`mr-2 }`}
                        style={{ width: "18px" }}
                    />
                    <div>{option.CountryName_vi}</div>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img
                    alt={option.CountryName_vi}
                    src={`${option.Avatar}`}
                    className={`mr-2 }`}
                    style={{ width: "18px" }}
                />
                <div>{option.CountryName_vi}</div>
            </div>
        );
    };

    // Lấy danh sách true and false khi đăng nhập
    useEffect(() => {
        if (internData) {
            setShowAvata(internData?.avata);
            if (internData?.intern_user_info) {
                setIsHasAccount(true);
                if (internData?.intern_user_info.access) {
                    setIsLogin(true);
                } else {
                    setIsLogin(false);
                }

                if (internData?.intern_user_info.active) {
                    setIsActive(true);
                } else {
                    setIsActive(false);
                }
            } else {
                setIsHasAccount(false);
                setIsLogin(false);
            }
        }
    }, [internData]);

    useEffect(() => {
        if (errorUpdate) {
            toast.current?.show({
                severity: "error",
                summary: "Error",
                detail: `Update Intern Fail: ${errorUpdate.message}`,
                life: 5000,
            });
            // dispatch(setDataNull());
        }
        if (dataUpdate) {
            toast.current?.show({
                severity: "success",
                summary: "success",
                detail: "Update Intern Success",
                life: 5000,
            });
            formik.resetForm();
            dispatch(setDataNull());
            setShowAvata(avata);
            onHide();
        }
        // eslint-disable-next-line
    }, [errorUpdate, dataUpdate]);

    //Card Templete

    const cardTemplete = (dataListCard) => {
        return (
            <div className="flex flex-column">
                <span className="font-bold">{dataListCard.card_number}</span>
                <span className="text-green-600">
                    {t(dataListCard.status_of_residence)}
                </span>
            </div>
        );
    };

    const CardAddHeader = () => {
        return (
            <div
                onClick={openDialog}
                style={{
                    cursor: "pointer",
                    backgroundColor: "#10B981",
                    color: "white",
                    padding: "5px",
                    borderRadius: "5px",
                }}
            >
                {t("Add New")}
            </div>
        );
    };

    const handleEdit = (card) => {
        setCardDialog(true);
        setEditingCard(card);
        formik.setValues({
            ...formik.values,
            card_number: card.card_number,
            status_of_residence: card.status_of_residence,
            card_license_date: card.card_license_date,
            card_expiration_date: card.card_expiration_date,
            description: card.description,
        });
    };

    const handleDelete = (cardId) => {
        setCardList((prevCardList) =>
            prevCardList.filter((card) => card.id !== cardId)
        );
        formik.setFieldValue(
            "alien_registration_cards",
            formik.values.alien_registration_cards.filter(
                (card) => card.id !== cardId
            )
        );
    };

    //Menu hành động
    const actionBodyTemplate = (dataListCard) => {
        return (
            <ActionMenu
                onEdit={() => handleEdit(dataListCard)}
                onDelete={() => handleDelete(dataListCard.id)}
            />
        );
    };

    const statusItemTemplate = (option) => {
        return <div>{t(option.name)}</div>;
    };

    return (
        <>
            <Dialog
                visible={visible}
                onHide={() => {
                    dispatch(setDataNull());
                    dispatch(setDataAddressFullNull());
                    onHide();
                }}
                header={t("Edit Intern")}
                modal
                style={{ width: "90vw", height: "90vh" }}
                footer={
                    <div>
                        <Button
                            label={t("Cancel")}
                            onClick={() => {
                                dispatch(setDataNull());
                                dispatch(setDataAddressFullNull());
                                onHide();
                            }}
                            severity="secondary"
                            className="p-button-text"
                        />
                        <Button
                            label={t("Save")}
                            type="submit"
                            onClick={formik.handleSubmit}
                        />
                    </div>
                }
                pt={{
                    content: "surface-100 p-3",
                    footer: "surface-100",
                    header: "py-3 pl-4",
                }}
            >
                <div className="flex flex-row flex-wrap">
                    <div className="w-2 p-2">
                        <div className="bg-white p-3 border-round-md h-full">
                            <div className="flex justify-content-center">
                                <div className="flex flex-column">
                                    <CircleImage
                                        src={showAvata}
                                        size={150}
                                        altImage="Avatar"
                                    />

                                    <div className="my-2 text-center">
                                        <Button
                                            name="icon"
                                            type="file"
                                            label={t("Upload Photo")}
                                            size="small"
                                            severity="secondary"
                                            outlined
                                            onClick={() => {
                                                fileInputRef.current?.click();
                                            }}
                                        />
                                        <input
                                            onChange={handleFileChange}
                                            multiple={false}
                                            ref={fileInputRef}
                                            type="file"
                                            accept="image/*"
                                            hidden
                                            name="icon"
                                            id="icon"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6">
                                <div className="flex align-items-center mb-3 justify-content-center">
                                    <InputSwitch
                                        checked={isLogin}
                                        onChange={(e) => setIsLogin(e.value)}
                                    />
                                    <label className="font-bold ml-2">
                                        {t("Allow login")}
                                    </label>
                                </div>
                                {(isLogin || isHasAccount) && (
                                    <div>
                                        <InputText
                                            name="username"
                                            placeholder={t("Username")}
                                            className="w-full mb-1"
                                            value={formik.values.username}
                                            onChange={formik.handleChange}
                                            invalid={
                                                formik.touched.username &&
                                                formik.errors.username
                                                    ? true
                                                    : false
                                            }
                                            disabled={isHasAccount && isActive}
                                        />
                                        {formik.touched.username &&
                                        formik.errors.username ? (
                                            <div style={{ color: "red" }}>
                                                {formik.errors.username.toString()}
                                            </div>
                                        ) : null}
                                        <InputText
                                            name="password"
                                            placeholder={t("Password")}
                                            className="w-full mt-1 mb-1"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            invalid={
                                                formik.touched.password &&
                                                formik.errors.password
                                                    ? true
                                                    : false
                                            }
                                            disabled={isHasAccount && isActive}
                                        />
                                        {formik.touched.password &&
                                        formik.errors.password ? (
                                            <div style={{ color: "red" }}>
                                                {formik.errors.password.toString()}
                                            </div>
                                        ) : null}
                                        {isActive && (
                                            <div className="flex flex-row justify-content-center">
                                                <img
                                                    src={checked_icon}
                                                    width={"20px"}
                                                    alt=""
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* ===================================Intern information============================================== */}

                    <div className="w-5 p-2">
                        <div className="bg-white border-round-md p-3 h-full">
                            <div className="flex flex-row">
                                <div className="w-4 flex flex-column px-2 mt-2">
                                    <FloatLabel>
                                        <InputText
                                            id="last_name_jp"
                                            className="w-full border-noround-bottom"
                                            value={formik.values.last_name_jp}
                                            onChange={formik.handleChange}
                                            // invalid={formik.touched.last_name_jp && formik.errors.last_name_jp ? true : false}
                                        />
                                        <label htmlFor="last_name_jp">
                                            {t("Last Name")}
                                        </label>
                                    </FloatLabel>
                                </div>
                                <div className="w-4 flex flex-column px-2 mt-2">
                                    <FloatLabel>
                                        <InputText
                                            id="middle_name_jp"
                                            className="w-full border-noround-bottom"
                                            onChange={formik.handleChange}
                                            value={formik.values.middle_name_jp}
                                            // invalid={formik.touched.middle_name_jp && formik.errors.middle_name_jp ? true : false}
                                        />
                                        <label htmlFor="middle_name_jp">
                                            {t("Middle Name")}
                                        </label>
                                    </FloatLabel>
                                </div>
                                <div className="w-4 flex flex-column px-2 mt-2">
                                    <FloatLabel>
                                        <InputText
                                            id="first_name_jp"
                                            className="w-full border-noround-bottom"
                                            onChange={formik.handleChange}
                                            value={formik.values.first_name_jp}
                                            // invalid={formik.touched.first_name_jp && formik.errors.first_name_jp ? true : false}
                                        />
                                        <label htmlFor="first_name_jp">
                                            {t("First Name")}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="px-2 w-4">
                                    <InputText
                                        id="last_name_en"
                                        placeholder={t("Last Name (English)")}
                                        className="w-full border-noround-top border-top-none"
                                        onChange={formik.handleChange}
                                        value={formik.values.last_name_en}
                                        invalid={
                                            formik.touched.last_name_en &&
                                            formik.errors.last_name_en
                                                ? true
                                                : false
                                        }
                                    />
                                    {formik.touched.last_name_en &&
                                    formik.errors.last_name_en ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.last_name_en.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="px-2 w-4">
                                    <InputText
                                        id="middle_name_en"
                                        placeholder={t("Middle Name (English)")}
                                        className="w-full border-noround-top border-top-none"
                                        onChange={formik.handleChange}
                                        value={formik.values.middle_name_en}
                                        invalid={
                                            formik.touched.middle_name_en &&
                                            formik.errors.middle_name_en
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                <div className="px-2 w-4">
                                    <InputText
                                        id="first_name_en"
                                        placeholder={t("First Name (English)")}
                                        className="w-full border-noround-top border-top-none"
                                        onChange={formik.handleChange}
                                        value={formik.values.first_name_en}
                                        invalid={
                                            formik.touched.first_name_en &&
                                            formik.errors.first_name_en
                                                ? true
                                                : false
                                        }
                                    />
                                    {formik.touched.first_name_en &&
                                    formik.errors.first_name_en ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.first_name_en.toString()}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex flex-row mt-4">
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataCountry.filter(
                                                (e) =>
                                                    e.CountryName_en !== "Japan"
                                            )}
                                            name="country"
                                            value={formik.values.country}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "country",
                                                    e.value
                                                );
                                            }}
                                            filter
                                            optionLabel="CountryName_en"
                                            optionValue="CountryName_en"
                                            valueTemplate={
                                                selectedCountryTemplate
                                            }
                                            itemTemplate={countryOptionTemplate}
                                            emptyFilterMessage
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched.country &&
                                                formik.errors.country
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="country">
                                            {t("Country")}
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.country &&
                                    formik.errors.country ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.country.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <Dropdown
                                            id="gender"
                                            options={genderItems}
                                            value={formik.values.gender}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "gender",
                                                    e.value
                                                )
                                            }
                                            optionLabel="name"
                                            optionValue="value"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched.gender &&
                                                formik.errors.gender
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="gender">
                                            {t("Gender")}
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.gender &&
                                    formik.errors.gender ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.gender.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <Calendar
                                            id="birthday"
                                            value={formik.values.dob}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "dob",
                                                    e.value
                                                )
                                            }
                                            showIcon
                                            dateFormat="yy/mm/dd"
                                            invalid={
                                                formik.touched.dob &&
                                                formik.errors.dob
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="birthday">
                                            {t("Date of Birth")}
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.dob && formik.errors.dob ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.dob.toString()}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex flex-row mt-4">
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <InputText
                                            id="passport_code"
                                            className="w-full"
                                            onChange={formik.handleChange}
                                            value={formik.values.passport_code}
                                            invalid={
                                                formik.touched.passport_code &&
                                                formik.errors.passport_code
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="passport_code">
                                            {t("Passport Number")}
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.passport_code &&
                                    formik.errors.passport_code ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.passport_code.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <Calendar
                                            id="passport_license_date"
                                            value={
                                                formik.values
                                                    .passport_license_date
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "passport_license_date",
                                                    e.value
                                                )
                                            }
                                            showIcon
                                            dateFormat="yy/mm/dd"
                                            invalid={
                                                formik.touched
                                                    .passport_license_date &&
                                                formik.errors
                                                    .passport_license_date
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="passport_license_date">
                                            {t("Date of Issue")}
                                        </label>
                                    </FloatLabel>
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <Calendar
                                            value={
                                                formik.values
                                                    .passport_expiration_date
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "passport_expiration_date",
                                                    e.value
                                                )
                                            }
                                            showIcon
                                            dateFormat="yy/mm/dd"
                                            invalid={
                                                formik.touched
                                                    .passport_expiration_date &&
                                                formik.errors
                                                    .passport_expiration_date
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="passport_expiration_date">
                                            {t("Date of Expiration")}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className="flex flex-row mt-4">
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <InputText
                                            id="phone_domestically"
                                            className="w-full"
                                            onChange={formik.handleChange}
                                            value={
                                                formik.values.phone_domestically
                                            }
                                            invalid={
                                                formik.touched
                                                    .phone_domestically &&
                                                formik.errors.phone_domestically
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label
                                            htmlFor="phone_domestically"
                                            className="overflow-hidden white-space-nowrap w-9"
                                        >
                                            {t("Domestic Phone Number")}
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.phone_domestically &&
                                    formik.errors.phone_domestically ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.phone_domestically.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <InputText
                                            id="phone_abroad"
                                            className="w-full"
                                            onChange={formik.handleChange}
                                            value={formik.values.phone_abroad}
                                            invalid={
                                                formik.touched.phone_abroad &&
                                                formik.errors.phone_abroad
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label
                                            htmlFor="phone2"
                                            className="overflow-hidden white-space-nowrap w-8"
                                        >
                                            {t("Foreign Phone Number")}
                                        </label>
                                    </FloatLabel>
                                </div>
                                <div className="w-4 px-2">
                                    <FloatLabel>
                                        <InputText
                                            id="email"
                                            className="w-full"
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            invalid={
                                                formik.touched.email &&
                                                formik.errors.email
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="email">
                                            {t("Email")}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className="mt-4 px-2">
                                <FloatLabel>
                                    <InputTextarea
                                        rows={2}
                                        id="description"
                                        className="w-full"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                    />
                                    <label htmlFor="description">
                                        {t("Description")}
                                    </label>
                                </FloatLabel>
                            </div>
                            <div className="mt-4 px-2">
                                <FloatLabel>
                                    <MultiSelect
                                        value={formik.values.status_ids}
                                        options={dataStatus}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                "status_ids",
                                                e.value
                                            )
                                        }
                                        optionLabel="name"
                                        optionValue="id"
                                        itemTemplate={statusItemTemplate}
                                        showClear
                                        className="w-full"
                                        display="chip"
                                        pt={{ header: "hidden" }}
                                        invalid={
                                            formik.touched.status_ids &&
                                            formik.errors.status_ids
                                                ? true
                                                : false
                                        }
                                    />
                                    <label htmlFor="status">
                                        {t("Status")}
                                    </label>
                                </FloatLabel>
                            </div>
                        </div>
                    </div>

                    {/* ===================================Japan information============================================== */}

                    <div className="w-5 p-2">
                        <div className="bg-white border-round-md p-4 h-full ">
                            <div className={"flex"}>
                                <div className={"w-6 "}>
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataSydication}
                                            value={dataSydication?.find(
                                                (e) =>
                                                    e.id ===
                                                    formik.values.syndication_id
                                            )}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "syndication_id",
                                                    e.value.id
                                                );
                                                setSelectSyndication(e.value);
                                            }}
                                            filter
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            optionLabel="name_jp"
                                            disabled={
                                                admin === ObjectType.SYNDICATION
                                            }
                                            valueTemplate={dropdownSelected}
                                            itemTemplate={dropdownOption}
                                            emptyFilterMessage
                                            className="border-noround-right"
                                            style={{ height: "35px" }}
                                            invalid={
                                                formik.touched.syndication_id &&
                                                formik.errors.syndication_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="syndication_id">
                                            {t("Syndication")}
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.syndication_id &&
                                    formik.errors.syndication_id ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.syndication_id.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-6">
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataBranchSyndication} // Tý chẹc không có hiển thị no branch
                                            value={
                                                formik.values
                                                    .syndication_branch_id
                                            }
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "syndication_branch_id",
                                                    e.value
                                                );
                                            }}
                                            filter
                                            showClear
                                            optionValue="id"
                                            optionLabel="branch"
                                            style={{ height: "35px" }}
                                            className="border-noround-left border-left-none"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched
                                                    .syndication_branch_id &&
                                                formik.errors
                                                    .syndication_branch_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="factory">
                                            {t("Branch")}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className={"flex mt-4"}>
                                <div className={"w-6"}>
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataReceiving}
                                            value={dataReceiving?.find(
                                                (e) =>
                                                    e.id ===
                                                    formik.values
                                                        .receiving_factory_id
                                            )}
                                            onChange={(e) => {
                                                setSelectReceivingFactory(
                                                    e.value
                                                );
                                                formik.setFieldValue(
                                                    "receiving_factory_id",
                                                    e.value.id
                                                );
                                            }}
                                            filter
                                            disabled={
                                                admin ===
                                                ObjectType.RECEIVING_FACTORY
                                            }
                                            optionLabel="name_jp"
                                            valueTemplate={dropdownSelected}
                                            itemTemplate={dropdownOption}
                                            emptyFilterMessage
                                            style={{ height: "35px" }}
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            className="border-noround-right"
                                            invalid={
                                                formik.touched
                                                    .receiving_factory_id &&
                                                formik.errors
                                                    .receiving_factory_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="receiving_factory_id">
                                            {t("Receiving Factory")}
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.receiving_factory_id &&
                                    formik.errors.receiving_factory_id ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.receiving_factory_id.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-6">
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataBranchReceiving}
                                            value={
                                                formik.values
                                                    .receiving_factory_branch_id
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "receiving_factory_branch_id",
                                                    e.value
                                                )
                                            }
                                            filter
                                            style={{ height: "35px" }}
                                            showClear
                                            optionLabel="branch"
                                            optionValue="id"
                                            className="border-noround-left border-left-none"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched
                                                    .receiving_factory_branch_id &&
                                                formik.errors
                                                    .receiving_factory_branch_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="factory">
                                            {t("Branch")}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className={"flex flex-row mt-4"}>
                                <div className={"w-6 "}>
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataDispatching}
                                            value={dataDispatching?.find(
                                                (e) =>
                                                    e.id ===
                                                    formik.values
                                                        .dispatching_company_id
                                            )}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "dispatching_company_id",
                                                    e.value.id
                                                );
                                                setSelectDispatching(e.value);
                                            }}
                                            filter
                                            id="dispatching_company_id"
                                            disabled={
                                                admin ===
                                                ObjectType.DISPATCHING_COMPANY
                                            }
                                            optionLabel="name_jp"
                                            valueTemplate={dropdownSelected}
                                            itemTemplate={dropdownOption}
                                            emptyFilterMessage
                                            style={{ height: "35px" }}
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            className="border-noround-right"
                                            invalid={
                                                formik.touched
                                                    .dispatching_company_id &&
                                                formik.errors
                                                    .dispatching_company_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="dispatching_company_id">
                                            {t("Dispatching Company")}
                                        </label>
                                    </FloatLabel>
                                    {formik.touched.dispatching_company_id &&
                                    formik.errors.dispatching_company_id ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.dispatching_company_id.toString()}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="w-6">
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataBranchDispatching}
                                            value={
                                                formik.values
                                                    .dispatching_company_branch_id
                                            }
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "dispatching_company_branch_id",
                                                    e.value
                                                )
                                            }
                                            style={{ height: "35px" }}
                                            filter
                                            optionValue="id"
                                            showClear
                                            optionLabel="branch"
                                            className="border-noround-left border-left-none"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched
                                                    .dispatching_company_branch_id &&
                                                formik.errors
                                                    .dispatching_company_branch_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="factory">
                                            {t("Branch")}
                                        </label>
                                    </FloatLabel>
                                    {formik.touched
                                        .dispatching_company_branch_id &&
                                    formik.errors
                                        .dispatching_company_branch_id ? (
                                        <div style={{ color: "red" }}>
                                            {formik.errors.dispatching_company_branch_id.toString()}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex flex-row mt-4">
                                <div className="w-6 mr-3">
                                    <FloatLabel>
                                        <Calendar
                                            value={formik.values.entry_date}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "entry_date",
                                                    e.value
                                                )
                                            }
                                            showIcon
                                            dateFormat="yy/mm/dd"
                                            invalid={
                                                formik.touched.entry_date &&
                                                formik.errors.entry_date
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="entrydate">
                                            {t("Entry Date")}
                                        </label>
                                    </FloatLabel>
                                </div>
                                <div className="w-6">
                                    <FloatLabel>
                                        <Dropdown
                                            options={dataCareer}
                                            showClear
                                            value={formik.values.career_id}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "career_id",
                                                    e.value
                                                )
                                            }
                                            optionLabel="name"
                                            optionValue="id"
                                            pt={{
                                                list: "p-0",
                                                root: "w-full",
                                                header: "p-3",
                                            }}
                                            invalid={
                                                formik.touched.career_id &&
                                                formik.errors.career_id
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <label htmlFor="career_id">
                                            {t("Career")}
                                        </label>
                                    </FloatLabel>
                                </div>
                            </div>

                            <div className="flex flex-row mt-4">
                                <DataTable
                                    value={cardList}
                                    className="w-full"
                                    emptyMessage={t("No data available")}
                                >
                                    <Column
                                        field="card_number"
                                        header={t("Foreigner Registration No.")}
                                        body={cardTemplete}
                                        className="w-5"
                                    ></Column>
                                    <Column
                                        field="card_license_date"
                                        header={t("Date of Issue")}
                                        body={(rowData) =>
                                            moment(
                                                rowData.card_license_date
                                            ).format("YYYY/MM/DD")
                                        }
                                        className="w-3"
                                    ></Column>
                                    <Column
                                        field="card_expiration_date"
                                        header={t("Date of Expiration")}
                                        body={(rowData) =>
                                            moment(
                                                rowData.card_expiration_date
                                            ).format("YYYY/MM/DD")
                                        }
                                        className="w-3"
                                    ></Column>
                                    <Column
                                        field="action"
                                        header={CardAddHeader}
                                        body={actionBodyTemplate}
                                        className="w-1"
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
                <AddressDetail
                    object_id={internData?.id}
                    object_type={ObjectType.INTERN}
                    callBackData={callBackData}
                />
            </Dialog>
            <AlienCard
                cardDialog={cardDialog}
                setCardDialog={setCardDialog}
                editingCard={editingCard}
                residenceItems={residenceItems}
                setCardList={setCardList}
                setEditingCard={setEditingCard}
            />
        </>
    );
};

export default EditInternDialog;
