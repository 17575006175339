import { combineReducers } from 'redux';

import Country from './country/reducer';
import State from './state/reducer';
import District from './district/reducer';
import Ward from './ward/reducer';

import Career from './career/reducer';
import User from './auth/reducer';
import Status from './status/reducer';
import ViolateType from './violate_type/reducer';
import Syndication from './syndication/reducer';
import DispatchingCompany from './dispatching_company/reducer';
import ReceivingFactory from './receiving_factory/reducer';
import Intern from './intern/reducer';
import Address from './address/reducer';
import Violate from './violate/reducer';
import Announced from './announced/reducer';
import Ticket from './ticket/reducer';
import Dashboard from './dashboard/reducer';
import Notify from './notify/reducer';

const rootReducer = combineReducers({
  Country,
  State,
  District,
  Ward,
  Career,
  User,
  Status,
  ViolateType,
  Syndication,
  DispatchingCompany,
  ReceivingFactory,
  Intern,
  Address,
  Violate,
  Announced,
  Ticket,
  Dashboard,
  Notify,
});

export default rootReducer;
