export const GET_WARD_ALL = "GET_WARD_ALL";
export const GET_WARD_ALL_SUCCESS = "GET_WARD_ALL_SUCCESS";
export const GET_WARD_ALL_FAIL = "GET_WARD_ALL_FAIL";

export const GET_WARD_ID = "GET_WARD_ID";
export const GET_WARD_ID_SUCCESS = "GET_WARD_ID_SUCCESS";
export const GET_WARD_ID_FAIL = "GET_WARD_ID_FAIL";

export const GET_WARD_BY_DISTRICT_ID = "GET_WARD_BY_DISTRICT_ID";
export const GET_WARD_BY_DISTRICT_ID_SUCCESS = "GET_WARD_BY_DISTRICT_ID_SUCCESS";
export const GET_WARD_BY_DISTRICT_ID_FAIL = "GET_WARD_BY_DISTRICT_ID_FAIL";

export const SET_WARD = "SET_WARD";
export const SET_WARD_SUCCESS = "SET_WARD_SUCCESS";
export const SET_WARD_FAIL = "SET_WARD_FAIL";

export const UPDATE_WARD = "UPDATE_WARD";
export const UPDATE_WARD_SUCCESS = "UPDATE_WARD_SUCCESS";
export const UPDATE_WARD_FAIL = "UPDATE_WARD_FAIL";

export const DELETE_WARD = "DELETE_WARD";
export const DELETE_WARD_SUCCESS = "DELETE_WARD_SUCCESS";
export const DELETE_WARD_FAIL = "DELETE_WARD_FAIL";