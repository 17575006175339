import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import HeaderDashboard from './HeaderDashboard';
import DonutChart from './DonutChart';
import ColumsChart from './ColumsChart';
import NewNoti from './NewNoti';
import NewIntern from './NewIntern';
import { getDashboardData } from '../../store/dashboard/action';

function Dashboard() {
  const auth = JSON.parse(localStorage.getItem('auth'));

  const { dataIntern } = useSelector(
    (state) => ({
      dataIntern: state.Dashboard.datas,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth) {
      dispatch(getDashboardData());
    }
  }, [dispatch]);

  return (
    <div>
      <HeaderDashboard dataIntern={dataIntern} />
      <div className='grid'>
        <div
          className='col-4 bg-white border-round-lg'
          style={{ width: 'calc(33.3333% - 30px)', margin: '15px' }}>
          <DonutChart dataIntern={dataIntern} />
        </div>
        <div
          className='col-8 bg-white border-round-lg'
          style={{ width: 'calc(66.6667% - 30px)', margin: '15px' }}>
          <ColumsChart dataIntern={dataIntern} />
        </div>
      </div>
      <div className='grid'>
        <div
          className='col-6 bg-white border-round-lg'
          style={{ margin: '15px', width: 'calc(50% - 30px)' }}>
          <NewNoti dataIntern={dataIntern} />
        </div>
        <div
          className='col-6 bg-white border-round-lg'
          style={{
            margin: '15px',
            width: 'calc(50% - 30px)',
          }}>
          <NewIntern dataIntern={dataIntern} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
