import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_STATE_ALL,
  GET_STATE_ID,
  SET_STATE,
  UPDATE_STATE,
  DELETE_STATE,
  GET_STATE_BY_COUNTRY_ID,
} from "./actionTypes";
import {
  getStateAllFail,
  getStateAllSuccess,
  getStateIdSuccess,
  getStateIdFail,
  getStateByCountryIdSuccess,
  getStateByCountryIdFail,
  setStateSuccess,
  setStateFail,
  updateStateSuccess,
  updateStateFail,
  deleteStateSuccess,
  deleteStateFail,
} from "./actions";

import {
  getStateDataAll,
  getStateDataId,
  addNewDataState,
  updateDataState,
  deleteDataState,
} from "../../api/backend_helper";
import axios from "axios";
import * as urlAddress from "../../api/url_helper";

function* fetStateData() {
  try {
    const response = yield call(getStateDataAll);
    yield put(getStateAllSuccess(response));
  } catch (error) {
    yield put(getStateAllFail(error));
  }
}

function* fetStateDataId({ payload: id }) {
  try {
    const response = yield call(getStateDataId, id);
    yield put(getStateIdSuccess(response));
  } catch (error) {
    yield put(getStateIdFail(error));
  }
}

function* fetStateByCountryDataId({ payload: id }) {
  try {
    // const response = yield call(getStateByCountryDataId, id);
    const response = yield call(
      axios.get,
      `${urlAddress.GET_STATE_BY_COUNTRY_ID}/${id}`
    );
    yield put(getStateByCountryIdSuccess(response.data));
  } catch (error) {
    yield put(getStateByCountryIdFail(error.message));
  }
}

function* onAddNewState({ payload: data }) {
  try {
    const response = yield call(addNewDataState, data);
    yield put(setStateSuccess(response));
    yield call(refreshStateData);
  } catch (error) {
    yield put(setStateFail(error));
  }
}

function* onUpdateState({ payload: data }) {
  try {
    const response = yield call(updateDataState, data);
    yield put(updateStateSuccess(response));
    yield call(refreshStateData);
  } catch (error) {
    yield put(updateStateFail(error));
  }
}

function* onDeleteState({ payload: id }) {
  try {
    const response = yield call(deleteDataState, id);
    yield put(deleteStateSuccess(response));
  } catch (error) {
    yield put(deleteStateFail(error));
  }
}

function* refreshStateData() {
  const response = yield call(getStateDataAll);
  yield put(getStateAllSuccess(response));
}

function* StateSaga() {
  yield takeEvery(GET_STATE_ALL, fetStateData);
  yield takeEvery(GET_STATE_ID, fetStateDataId);
  yield takeEvery(GET_STATE_BY_COUNTRY_ID, fetStateByCountryDataId);
  yield takeEvery(SET_STATE, onAddNewState);
  yield takeEvery(UPDATE_STATE, onUpdateState);
  yield takeEvery(DELETE_STATE, onDeleteState);
}

export default StateSaga;
