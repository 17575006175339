import { takeEvery, put, call } from "redux-saga/effects";
import {
  GET_VIOLATE_TYPE_BY_ID,GET_VIOLATE_TYPE_BY_USER_ID, SET_VIOLATE_TYPE, UPDATE_VIOLATE_TYPE,DELETE_VIOLATE_TYPE
} from "./actionTypes"
import {
    getViolateTypeByIdSuccess,
    getViolateTypeByIdFail,
    getViolateTypeByUserIdSuccess,
    getViolateTypeByUserIdFail,
    setViolateTypeSuccess,
    setViolateTypeFail,
    updateViolateTypeSuccess,
    updateViolateTypeFail,
    deleteViolateTypeSuccess,
    deleteViolateTypeFail
} from "./actions"
                                      
import { getViolateTypeDataById , getViolateTypeDataByUserId,addNewDataViolateType, updateDataViolateType, deleteDataViolateType } from "../../api/backend_helper";



function* fetViolateTypeDataId({payload: id}) {
  try {
    const response = yield call(getViolateTypeDataById, id);
    yield put(getViolateTypeByIdSuccess(response));
  } catch (error) {
    yield put(getViolateTypeByIdFail(error.response.data))
  }
}

function* fetViolateTypeDataByUserId() {
  try {
    const response = yield call(getViolateTypeDataByUserId);
    yield put(getViolateTypeByUserIdSuccess(response));
  } catch (error) {
    yield put(getViolateTypeByUserIdFail(error.response.data))
  }
}

function* onAddNewViolateType({ payload: data }) {
  try {
      const response = yield call(addNewDataViolateType, data);
      yield put(setViolateTypeSuccess(response));
  } catch (error) {
      yield put(setViolateTypeFail(error.response.data));
  }
}

function* onUpdateViolateType({ payload: data }) {
  try {
      const response = yield call(updateDataViolateType, data)
      yield put(updateViolateTypeSuccess(response));
  } catch (error) {
      yield put(updateViolateTypeFail(error.response.data))
  }
}

function* onDeleteViolateType({ payload: id }) {
  try {
      const response = yield call(deleteDataViolateType, id)
      yield put(deleteViolateTypeSuccess(response));
  } catch (error) {
      yield put(deleteViolateTypeFail(error.response.data))
  }
}

                                      
function* ViolateTypeSaga() {
  yield takeEvery(GET_VIOLATE_TYPE_BY_ID, fetViolateTypeDataId)
  yield takeEvery(GET_VIOLATE_TYPE_BY_USER_ID, fetViolateTypeDataByUserId)
  yield takeEvery(SET_VIOLATE_TYPE, onAddNewViolateType)
  yield takeEvery(UPDATE_VIOLATE_TYPE, onUpdateViolateType)
  yield takeEvery(DELETE_VIOLATE_TYPE, onDeleteViolateType)
}
                                      
export default ViolateTypeSaga;