import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import store from "./store/index";
import { DataProvider } from "./contexts/data/DataProvider";
import "./locales/setupLocales"
import moment from 'moment-timezone';

moment.tz.setDefault('Asia/Tokyo'); // Đặt timezone mặc định

// import { tokenRefreshRequest } from './actions/authActions';

// const accessToken = localStorage.getItem('accessToken');

// if (accessToken) {
//     store.dispatch(tokenRefreshRequest());
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <DataProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </DataProvider>
  </Provider>
  // </React.StrictMode>
);
