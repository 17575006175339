import {
  GET_BRANCH_RECEIVING,
  GET_BRANCH_RECEIVING_SUCCESS,
  GET_BRANCH_RECEIVING_FAIL,
  GET_BRANCH_SYNDICATION,
  GET_BRANCH_SYNDICATION_SUCCESS,
  GET_BRANCH_SYNDICATION_FAIL,
  GET_BRANCH_DISPATCHING,
  GET_BRANCH_DISPATCHING_SUCCESS,
  GET_BRANCH_DISPATCHING_FAIL,
  GET_BRANCH_FULL,
  GET_BRANCH_FULL_SUCCESS,
  GET_BRANCH_FULL_FAIL,
  SET_DATA_NULL,
} from "./actionTypes";

export const getBranchReceiving = (data) => ({
  type: GET_BRANCH_RECEIVING,
  payload: data,
});

export const getBranchReceivingSuccess = (data) => ({
  type: GET_BRANCH_RECEIVING_SUCCESS,
  payload: data,
});

export const getBranchReceivingFail = (error) => ({
  type: GET_BRANCH_RECEIVING_FAIL,
  payload: error,
});

export const getBranchSyndication = (data) => ({
  type: GET_BRANCH_SYNDICATION,
  payload: data,
});

export const getBranchSyndicationSuccess = (data) => ({
  type: GET_BRANCH_SYNDICATION_SUCCESS,
  payload: data,
});

export const getBranchSyndicationFail = (error) => ({
  type: GET_BRANCH_SYNDICATION_FAIL,
  payload: error,
});

export const getBranchDispatching = (data) => ({
  type: GET_BRANCH_DISPATCHING,
  payload: data,
});

export const getBranchDispatchingSuccess = (data) => ({
  type: GET_BRANCH_DISPATCHING_SUCCESS,
  payload: data,
});

export const getBranchDispatchingFail = (error) => ({
  type: GET_BRANCH_DISPATCHING_FAIL,
  payload: error,
});

export const getBranchFull = (data) => ({
  type: GET_BRANCH_FULL,
  payload: data,
});

export const getBranchFullSuccess = (data) => ({
  type: GET_BRANCH_FULL_SUCCESS,
  payload: data,
});

export const getBranchFullFail = (error) => ({
  type: GET_BRANCH_FULL_FAIL,
  payload: error,
});

export const setDataNull = () => ({
  type: SET_DATA_NULL,
});
