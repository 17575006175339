import { takeEvery, put, call } from "redux-saga/effects";
import {
  GET_TICKET_LIST,
  SET_TICKET,
  UPDATE_TICKET,
  GET_TICKET_DETAIL_LIST,
  SET_REPLY_TICKET,
  TRANSLATE_TICKET,
  GET_LIST_USER_REQUEST,
  CLOSE_TICKET,
} from "./actionTypes";

import socketInstance from "../../Singleton/SocketSingleton";

import {
  getTicketListSuccess,
  getTicketListFail,
  getTicketDetailListSuccess,
  getTicketDetailListFail,
  setTicketSuccess,
  updateTicketSuccess,
  updateTicketFail,
  setReplyTicketSuccess,
  setReplyTicketFail,
  translateTicketSuccess,
  translateTicketFail,
  getListUserSuccess,
  getListUserFailure,
  closeTicketSuccess,
  closeTicketFail,
} from "./action";

import {
  getTicketDetailList,
  updateDataTicket,
  getTicketList,
  translateTicket,
  getListUser,
  closeTicket,
} from "../../api/backend_helper";
import axios from "axios";
import * as URL_API from "../../api/url_helper";

function* fetTicketData({ payload: data }) {
  try {
    // console.log("data - query api - fetch ticket data - saga: ", data);
    if (data) {
      const response = yield call(getTicketList, data);
      yield put(getTicketListSuccess(response));
    }
  } catch (error) {
    // console.log(`error - get ticket list: ${error}`);
    yield put(getTicketListFail(error.message));
  }
}

function* fetTicketDetailData({ payload: data }) {
  try {
    const response = yield call(getTicketDetailList, data);
    // console.log("🚀 ~ function*fetTicketDetailData ~ data:", response.data);
    const result = response.data;
    yield put(getTicketDetailListSuccess(result));
  } catch (error) {
    yield put(getTicketDetailListFail(error));
  }
}

function* onAddNewTicket(action) {
  try {
    const { values, file } = action.payload;

    const ticket = new FormData();
    ticket.append("title", values.title);
    ticket.append("object", values.object);
    ticket.append("content", values.content);
    ticket.append("sender_id", values.sender_id);
    ticket.append("receiver_id", values.personToSend);
    ticket.append("priority", "Low");
    ticket.append("description", values.content);

    ticket.append("category", values.category);

    if (file) {
      ticket.append("file", file);
    }

    const response = yield call(
      axios.post,
      URL_API.SET_TICKET,
      ticket,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

     // emit message here
     const socket = socketInstance.getSocket();

     // Gửi socket khi reply thành công
     if (socket) {
       socket.emit("send-message", {
         data: response.data,
         members: [values.sender_id, values.personToSend],
       });
       socket.emit("send-notifications", {
         receiver: values.personToSend,
       });
     }

    // const headers = { ...response.headers };
    yield put(setTicketSuccess(response.data));
  } catch (error) {
    yield put(setReplyTicketFail(error.response.data));
  }
}

function* onReplyTicket(action) {
  try {
    const { values } = action.payload;

    const response = yield call(
      axios.post,
      URL_API.SET_REPLY_TICKET,
      values,
      { withCredentials: true },
      {
        headers: {
          contextType: "multipart/form-data",
        },
      }
    );

    const responseData = response.data;

    // emit message here
    const socket = socketInstance.getSocket();

    // // Gửi socket khi reply thành công
    if (socket) {
      socket.emit("send-message", {
        data: responseData.data,
        members: [responseData.data.sender_id, responseData.data.receiver_id],
      });
      socket.emit("send-notifications", {
        receiver: responseData.data.receiver_id,
      });
    }

    yield put(setReplyTicketSuccess(responseData));
  } catch (error) {
    console.log("🚀 ~ function*onReplyTicket ~ error:", error);
    yield put(setReplyTicketFail(error.response.data));
  }
}

function* onTranslateTicket(action) {
  try {
    const values = action.payload;

    const response = yield call(translateTicket, values);

    const dataTranslate = response.data;
    yield put(translateTicketSuccess(dataTranslate));
  } catch (error) {
    console.log(
      `translateTicket - saga error: ${
        error.response ? JSON.stringify(error.response.data) : error.message
      }`
    );
    yield put(
      translateTicketFail(error.response ? error.response.data : error.message)
    );
  }
}

function* onCloseTicket(action) {
  try {
    const response = yield call(closeTicket, action.payload);

    console.log("🚀 ~ function*onCloseTicket ~ response:", response);

    yield put(closeTicketSuccess(action.payload));
  } catch (error) {
    yield put(closeTicketFail(error));
  }
}

function* onUpdateTicket({ payload: data }) {
  try {
    const response = yield call(updateDataTicket, data);
    yield put(updateTicketSuccess(response));
    // toast.success("Ticket Updated Successfully", { autoClose: 2000 });
    // yield call(refreshTicketData);
  } catch (error) {
    yield put(updateTicketFail(error));
  }
}

function* fetchUserList(action) {
  try {
    const response = yield call(getListUser, action.payload);
    yield put(getListUserSuccess(response.data));
  } catch (error) {
    yield put(getListUserFailure(error.message));
  }
}

function* TicketSaga() {
  yield takeEvery(GET_TICKET_LIST, fetTicketData);
  yield takeEvery(GET_TICKET_DETAIL_LIST, fetTicketDetailData);
  yield takeEvery(SET_TICKET, onAddNewTicket);
  yield takeEvery(UPDATE_TICKET, onUpdateTicket);
  yield takeEvery(SET_REPLY_TICKET, onReplyTicket);
  yield takeEvery(TRANSLATE_TICKET, onTranslateTicket);
  yield takeEvery(GET_LIST_USER_REQUEST, fetchUserList);
  yield takeEvery(CLOSE_TICKET, onCloseTicket);

  // yield fork(watchNewTickets);
}

export default TicketSaga;
