import React, { useEffect, useRef, useState, useContext } from 'react';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';

import DialogVerify from './DialogVerify';
import axios from 'axios';
import { site } from '../../api/url_helper';
import { registerAdmin } from '../../store/auth/actions';
import { DataContext } from '../../contexts/data/DataProvider';

//redux
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { useNavigate, useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Register = () => {
  //meta title
  const toast = useRef(null);
  const location = useLocation();
  // const params = new URLSearchParams(location.search);
  const [visible, setVisible] = useState(false);
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');

  const { keyLicense } = useContext(DataContext);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  document.title = t('Register');

  let query = useQuery();
  let key = query.get('key');
  let key_id = query.get('key_license_id');

  // const [dataKey, setDataKey] = useState(null);

  const handleCodeChange = (value) => {
    setCode(value);
  };

  useEffect(() => {
    if (keyLicense == null || keyLicense == '') {
      navigate('/login');
    }
  }, []);

  const { dataRegisterAdmin, error } = useSelector(
    (state) => ({
      dataRegisterAdmin: state.User.dataRegisterAdmin,
      error: state.User.errorRegisterAdmin,
    }),
    shallowEqual
  );

  const handleChangeEmail = () => {
    axios.post(`${site}/api/users/delete-wrong-user`, {
      email: email,
      key_license_id: key_id,
    });
    setVisible(false);
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      name_en: '',
      email: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object().shape({
      name_en: Yup.string().required('Please Enter Company Name'),
      email: Yup.string()
        .required('Please Enter Your Email')
        .email('Must be a valid Email'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters long')
        .required('Please Enter Your Password'),
      confirm_password: Yup.string()
        .required('Please Confirm Your Password')
        .oneOf([Yup.ref('password'), null], 'Password incorrect'),
    }),
    onSubmit: (value) => {
      const dataRegister = {
        key_license_id: key_id,
        key_license_name: key,
        name_jp: value.name_en,
        name_en: value.name_en,
        email: value.email,
        password: value.password,
      };
      setEmail(value.email);
      dispatch(registerAdmin(dataRegister));
    },
  });

  useEffect(() => {
    if (dataRegisterAdmin) {
      setVisible(true);
    }
  }, [dataRegisterAdmin]);

  useEffect(() => {
    if (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: `Register fail. ${error.response.data.message}`,
        life: 3000,
      });
    }
  }, [error]);

  return (
    <div
      className='p-3'
      style={{ width: '500px' }}>
      <form>
        <Toast ref={toast} />
        <div
          className='flex align-content-center justify-content-between bg-green-100 px-5 pt-5'
          style={{
            borderTopLeftRadius: '0.375rem',
            borderTopRightRadius: '0.375rem',
          }}>
          <div className='flex flex-column'>
            <span className='mb-2 text-green-800 font-bold'>
              {t('Register')}!
            </span>
            <span className='text-green-800'>
              {t('Please do not reload the page when registering')}.
            </span>
          </div>
          <Image
            src='/images/logo/logo.png'
            alt='Image'
            width='100'
          />
        </div>
        <div
          className='bg-white p-5'
          style={{
            borderBottomLeftRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
          }}>
          <div className='flex flex-column mb-2'>
            <label className='mb-1'>{t('Company name')}</label>
            <InputText
              value={formik.values.name_en}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='name_en'
              name='name_en'
              type='text'
              placeholder={t('Company name')}
              className='w-full mb-2'
              style={{ padding: '1rem' }}
              invalid={
                formik.touched.name_en && formik.errors.name_en ? true : false
              }
            />
          </div>
          <div className='flex flex-column mb-2'>
            <label className='mb-1'>{t('Email')}</label>
            <InputText
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete='off'
              id='email'
              name='email'
              type='email'
              placeholder={t('Email')}
              className='w-full mb-2'
              style={{ padding: '1rem' }}
              invalid={formik.touched.email && !!formik.errors.email}
            />
          </div>
          <div className='flex flex-column mb-2'>
            <label className='mb-1'>{t('Password')}</label>
            <Password
              value={formik.values.password}
              onChange={formik.handleChange}
              placeholder={t('Password')}
              toggleMask
              autoComplete='off'
              name='password'
              className='w-full mb-2'
              // inputClassName='w-full p-3 md:w-30rem'
              inputClassName='w-full p-3'
              pt={{ iconField: { root: { className: 'w-full' } } }}
              invalid={formik.touched.password && !!formik.errors.password}
            />
          </div>
          <div className='flex flex-column mb-2'>
            <label className='mb-1'>{t('Confirm password')}</label>
            <Password
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              placeholder={t('Confirm password')}
              toggleMask
              name='confirm_password'
              className='w-full mb-2'
              inputClassName='w-full p-3'
              feedback={false}
              autoComplete='off'
              pt={{ iconField: { root: { className: 'w-full' } } }}
              invalid={
                formik.touched.confirm_password &&
                !!formik.errors.confirm_password
              }
            />
          </div>
          <Button
            type='submit'
            label={t('Register')}
            className='w-full mt-5'
            onClick={formik.handleSubmit}
          />
          <div className='flex justify-content-center flex-row mt-8 '></div>
        </div>
        {/*------------------------------ Dialog confirm OTP key  --------------------------------------------------*/}
        <DialogVerify
          email={email}
          visible={visible}
          handleChangeEmail={handleChangeEmail}
          onHide={handleChangeEmail}
          value={code}
          onChange={handleCodeChange}
          reSendCode={() => {
            axios
              .post(`${site}/api/users/resend-code`, { email: email })
              .then(() => {
                toast.current?.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Resend verify code success',
                  life: 3000,
                });
              })
              .catch((error) => {
                toast.current?.show({
                  severity: 'error',
                  summary: 'Verify fail',
                  detail: 'Re-enter the code or request to resend the code',
                  life: 3000,
                });
              });
          }}
          submitClick={() => {
            axios
              .post(`${site}/api/users/verify-code`, {
                email: email,
                code: code,
              })
              .then(() => {
                toast.current?.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Register Success',
                  life: 3000,
                });
                setTimeout(() => navigate('/login'), 3000);
                setVisible(false);
              })
              .catch((error) => {
                return toast.current?.show({
                  severity: 'error',
                  summary: 'Verify fail',
                  detail: 'Verify fail the code or request to resend the code',
                  life: 3000,
                });
              });
          }}
        />
      </form>
    </div>
  );
};

export default Register;
