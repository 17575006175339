import React from "react";
import { site } from "../../api/url_helper";
import { Avatar } from "primereact/avatar";

// Template for selected item
const dropdownSelected = (option, props) => {
  if (option) {
    return (
      <div className="flex align-items-center gap-2">
        <Avatar
          image={option.avata}
          shape="circle"
          style={{ minWidth: "1.5rem" }}
          pt={{
            root: {
              style: { width: "1.5rem", height: "1.5rem" },
            },
          }}
        />
        <div className="font-bold text-primary">{option.name_jp}</div>
      </div>
    );
  }
  return <span>{props.placeholder}</span>;
};

// Template for dropdown options
const dropdownOption = (option) => {
  return (
    <div className="flex align-items-center gap-2">
      <Avatar
        image={option.avata}
        shape="circle"
        style={{ minWidth: "2rem" }}
        pt={{
          root: {
            style: { width: "2rem", height: "2rem" },
          },
        }}
      />
      <div className="flex flex-column">
        <div>{option.name_jp}</div>
        <div>{option.name_en}</div>
      </div>
    </div>
  );
};

export { dropdownSelected, dropdownOption };
