import {
  GET_VIOLATE_BY_ID,
  GET_VIOLATE_BY_ID_SUCCESS,
  GET_VIOLATE_BY_ID_FAIL,
  GET_VIOLATE_BY_USER_ID,
  GET_VIOLATE_BY_USER_ID_SUCCESS,
  GET_VIOLATE_BY_USER_ID_FAIL,
  SET_VIOLATE,
  SET_VIOLATE_SUCCESS,
  SET_VIOLATE_FAIL,
  UPDATE_VIOLATE,
  UPDATE_VIOLATE_SUCCESS,
  UPDATE_VIOLATE_FAIL,
  DELETE_VIOLATE,
  DELETE_VIOLATE_SUCCESS,
  DELETE_VIOLATE_FAIL,
  SET_DATA_NULL,
  HANDLE_VIOLATE,
  HANDLE_VIOLATE_FAIL,
  HANDLE_VIOLATE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  dataById: null,
  dataHandle: null,
  dataByUserId: null,
  dataInsert: null,
  dataUpdate: null,
  dataDelete: null,
  loading: false,
  loadingHandle: false,
  loadingInsert: false,
  loadingUpdate: false,
  loadingDelete: false,
  error: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
  errorHandle: null,
};

const Violate = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VIOLATE_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_VIOLATE_BY_ID_SUCCESS:
      return {
        ...state,
        dataById: action.payload,
        loading: false,
      };
    case GET_VIOLATE_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_VIOLATE_BY_USER_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_VIOLATE_BY_USER_ID_SUCCESS:
      return {
        ...state,
        dataByUserId: action.payload,
        loading: false,
      };
    case GET_VIOLATE_BY_USER_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SET_VIOLATE:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: true,
        errorInsert: null,
      };
    case SET_VIOLATE_SUCCESS:
      return {
        ...state,
        dataInsert: action.payload,
        loadingInsert: false,
        errorInsert: null,
      };

    case SET_VIOLATE_FAIL:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: false,
        errorInsert: action.payload,
      };
    case HANDLE_VIOLATE:
      return {
        ...state,
        dataHandle: null,
        loadingHandle: true,
        errorHandle: null,
      };
    case HANDLE_VIOLATE_SUCCESS:
      return {
        ...state,
        dataHandle: action.payload,
        loadingHandle: false,
        errorHandle: null,
      };
    case HANDLE_VIOLATE_FAIL:
      return {
        ...state,
        dataHandle: null,
        loadingHandle: false,
        errorHandle: action.payload,
      };

    case UPDATE_VIOLATE:
      return {
        ...state,
        dataUpdate: null,
        loadingUpdate: true,
        errorUpdate: null,
      };
    case UPDATE_VIOLATE_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload,
        loadingUpdate: false,
        errorUpdate: null,
      };
    case UPDATE_VIOLATE_FAIL:
      return {
        ...state,
        dataUpdate: null,
        loadingUpdate: false,
        errorUpdate: action.payload,
      };
    case DELETE_VIOLATE:
      return {
        ...state,
        dataDelete: null,
        loadingDelete: true,
        errorDelete: null,
      };
    case DELETE_VIOLATE_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload,
        loadingDelete: false,
        errorDelete: null,
      };
    case DELETE_VIOLATE_FAIL:
      return {
        ...state,
        dataDelete: null,
        loadingDelete: false,
        errorDelete: action.payload,
      };
    case SET_DATA_NULL:
      return {
        ...state,
        dataInsert: null,
        dataUpdate: null,
        dataDelete: null,
        error: null,
        errorInsert: null,
        errorUpdate: null,
        errorDelete: null,
        dataHandle: null,
        errorHandle: null,
      };

    default:
      return state;
  }
};

export default Violate;
