export const ConvertLinkGoogleDrive = (link) => {
  // console.log(link);
  let linkConvert = link;
  if (link !== null && link !== undefined) {
    if (link.includes("drive.google.com")) {
      const id = link.split("d/")[1].split("/")[0];
      linkConvert = `https://drive.google.com/thumbnail?id=${id}`;
    }
  }
  console.log("linkConvert", linkConvert);
  return linkConvert;
};
