import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import {
  GET_SYNDICATION_BY_ID,
  GET_SYNDICATION_BY_USER_ID,
  GET_SYNDICATION_BY_KEY_ID,
  SET_SYNDICATION,
  UPDATE_SYNDICATION,
  DELETE_SYNDICATION,
  GET_SYNDICATION_LIST,
} from "./actionTypes";
import {
  getSyndicationByIdSuccess,
  getSyndicationByIdFail,
  getSyndicationByUserIdSuccess,
  getSyndicationByUserIdFail,
  getSyndicationByKeyIdSuccess,
  getSyndicationByKeyIdFail,
  setSyndicationSuccess,
  setSyndicationFail,
  updateSyndicationSuccess,
  updateSyndicationFail,
  deleteSyndicationSuccess,
  deleteSyndicationFail,
  getSyndicationGetListSuccess,
  getSyndicationGetListFail,
} from "./actions";

import {
  getSyndicationDataById,
  getSyndicationDataByUserId,
  getSyndicationDataByKeyId,
  deleteDataSyndication,
  getSyndicationDataList,
} from "../../api/backend_helper";
import axios from "axios";
import * as URL_API from "../../api/url_helper";

function* fetSyndicationDataId({ payload: id }) {
  try {
    const response = yield call(getSyndicationDataById, id);
    yield put(getSyndicationByIdSuccess(response));
  } catch (error) {
    yield put(getSyndicationByIdFail(error.response.data));
  }
}

// list
function* fetSyndicationDataGetList() {
  try {
    const response = yield call(getSyndicationDataList);
    yield put(getSyndicationGetListSuccess(response));
  } catch (error) {
    yield put(getSyndicationGetListFail(error.response.data));
  }
}

function* fetSyndicationDataByUserId() {
  try {
    const response = yield call(getSyndicationDataByUserId);
    yield put(getSyndicationByUserIdSuccess(response));
  } catch (error) {
    yield put(getSyndicationByUserIdFail(error.response.data));
  }
}
function* fetSyndicationDataByKeyId() {
  try {
    const response = yield call(getSyndicationDataByKeyId);
    yield put(getSyndicationByKeyIdSuccess(response));
  } catch (error) {
    yield put(getSyndicationByKeyIdFail(error.response.data));
  }
}

function* onAddNewSyndication(action) {
  try {
    const { values, file } = action.payload;

    const syndication = new FormData();
    syndication.append("name_jp", values.name_jp);
    syndication.append("name_en", values.name_en);
    syndication.append("code", values.code);
    syndication.append("description", values.description);
    syndication.append("addresses", JSON.stringify(values.addresses));
    syndication.append("username", values.username);
    syndication.append("password", values.password);
    syndication.append("is_login", values.is_login);

    if (file) {
      syndication.append("file", file);
    }

    const response = yield call(
      axios.post,
      URL_API.SET_SYNDICATION,
      syndication,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // Convert Axios headers to a plain object
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };

    yield put(setSyndicationSuccess(dataUpdate));
  } catch (error) {
    yield put(setSyndicationFail(error.response.data));
  }
}

function* onUpdateSyndication(action) {
  try {
    const { values, file } = action.payload;

    const syndication = new FormData();
    syndication.append("id", values.id);
    syndication.append("name_jp", values.name_jp);
    syndication.append("name_en", values.name_en);
    syndication.append("code", values.code);
    syndication.append("description", values.description);
    syndication.append("addresses", JSON.stringify(values.addresses));
    syndication.append("username", values.username);
    syndication.append("password", values.password);
    syndication.append("is_login", values.is_login);
    syndication.append("type", values.type);

    if (file) {
      syndication.append("file", file);
    }

    const response = yield call(
      axios.put,
      URL_API.UPDATE_SYNDICATION,
      syndication,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // Convert Axios headers to a plain object
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };
    yield put(updateSyndicationSuccess(dataUpdate));
  } catch (error) {
    yield put(updateSyndicationFail(error.response.data));
  }
}

function* onDeleteSyndication({ payload: id }) {
  try {
    const response = yield call(deleteDataSyndication, id);
    yield put(deleteSyndicationSuccess(response));
  } catch (error) {
    yield put(deleteSyndicationFail(error.response.data));
  }
}

function* SyndicationSaga() {
  yield takeEvery(GET_SYNDICATION_BY_ID, fetSyndicationDataId);
  yield takeEvery(GET_SYNDICATION_BY_USER_ID, fetSyndicationDataByUserId);
  yield takeEvery(GET_SYNDICATION_BY_KEY_ID, fetSyndicationDataByKeyId);
  yield takeEvery(GET_SYNDICATION_LIST, fetSyndicationDataGetList);
  yield takeLatest(SET_SYNDICATION, onAddNewSyndication);
  yield takeEvery(UPDATE_SYNDICATION, onUpdateSyndication);
  yield takeEvery(DELETE_SYNDICATION, onDeleteSyndication);
}

export default SyndicationSaga;
