import {
  GET_RECEIVING_FACTORY_BY_ID,
  GET_RECEIVING_FACTORY_BY_ID_SUCCESS,
  GET_RECEIVING_FACTORY_BY_ID_FAIL,
  GET_RECEIVING_FACTORY_BY_USER_ID,
  GET_RECEIVING_FACTORY_BY_USER_ID_SUCCESS,
  GET_RECEIVING_FACTORY_BY_USER_ID_FAIL,
  GET_RECEIVING_FACTORY_LIST,
  GET_RECEIVING_FACTORY_LIST_FAIL,
  GET_RECEIVING_FACTORY_LIST_SUCCESS,
  GET_RECEIVING_FACTORY_BY_KEY_ID,
  GET_RECEIVING_FACTORY_BY_KEY_ID_SUCCESS,
  GET_RECEIVING_FACTORY_BY_KEY_ID_FAIL,
  SET_RECEIVING_FACTORY,
  SET_RECEIVING_FACTORY_SUCCESS,
  SET_RECEIVING_FACTORY_FAIL,
  UPDATE_RECEIVING_FACTORY,
  UPDATE_RECEIVING_FACTORY_SUCCESS,
  UPDATE_RECEIVING_FACTORY_FAIL,
  DELETE_RECEIVING_FACTORY,
  DELETE_RECEIVING_FACTORY_SUCCESS,
  DELETE_RECEIVING_FACTORY_FAIL,
  SET_DATA_NULL,
} from "./actionTypes";

const INIT_STATE = {
  dataById: null,
  dataByUserId: null,
  dataByKeyId: null,
  dataInsert: null,
  dataGetList: null,
  dataUpdate: null,
  dataDelete: null,
  loading: false,
  loadingInsert: false,
  loadingUpdate: false,
  loadingDelete: false,
  error: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
};

const ReceivingFactory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RECEIVING_FACTORY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_RECEIVING_FACTORY_BY_ID_SUCCESS:
      return {
        ...state,
        dataById: action.payload,
        loading: false,
      };
    case GET_RECEIVING_FACTORY_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_RECEIVING_FACTORY_BY_USER_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_RECEIVING_FACTORY_BY_USER_ID_SUCCESS:
      return {
        ...state,
        dataByUserId: action.payload,
        loading: false,
      };
    case GET_RECEIVING_FACTORY_BY_USER_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    //
    case GET_RECEIVING_FACTORY_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_RECEIVING_FACTORY_LIST_SUCCESS:
      return {
        ...state,
        dataGetList: action.payload,
        loading: false,
      };
    case GET_RECEIVING_FACTORY_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    //
    case GET_RECEIVING_FACTORY_BY_KEY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_RECEIVING_FACTORY_BY_KEY_ID_SUCCESS:
      return {
        ...state,
        dataByKeyId: action.payload,
        loading: false,
      };
    case GET_RECEIVING_FACTORY_BY_KEY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SET_RECEIVING_FACTORY:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: true,
        errorInsert: null,
      };
    case SET_RECEIVING_FACTORY_SUCCESS:
      return {
        ...state,
        dataInsert: action.payload,
        loadingInsert: false,
        errorInsert: null,
      };

    case SET_RECEIVING_FACTORY_FAIL:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: false,
        errorInsert: action.payload,
      };
    case UPDATE_RECEIVING_FACTORY:
      return {
        ...state,
        dataUpdate: null,
        loadingUpdate: true,
        errorUpdate: null,
      };
    case UPDATE_RECEIVING_FACTORY_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload,
        loadingUpdate: false,
        errorUpdate: null,
      };
    case UPDATE_RECEIVING_FACTORY_FAIL:
      return {
        ...state,
        dataUpdate: null,
        loadingUpdate: false,
        errorUpdate: action.payload,
      };
    case DELETE_RECEIVING_FACTORY:
      return {
        ...state,
        dataDelete: null,
        loadingDelete: true,
        errorDelete: null,
      };
    case DELETE_RECEIVING_FACTORY_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload,
        loadingDelete: false,
        errorDelete: null,
      };
    case DELETE_RECEIVING_FACTORY_FAIL:
      return {
        ...state,
        dataDelete: null,
        loadingDelete: false,
        errorDelete: action.payload,
      };
    case SET_DATA_NULL:
      return {
        ...state,
        dataInsert: null,
        dataUpdate: null,
        dataDelete: null,
        error: null,
        errorInsert: null,
        errorUpdate: null,
        errorDelete: null,
      };

    default:
      return state;
  }
};

export default ReceivingFactory;
