import { takeEvery, put, call } from "redux-saga/effects";
import { GET_NOTIFY } from "./actionTypes";

import { getNotifySuccess, getNotifyFail } from "./actions";

import { getNotification } from "../../api/backend_helper";

function* onGetNotify({ payload: query }) {
  try {
    const response = yield call(getNotification, query);
    yield put(getNotifySuccess(response));
  } catch (error) {
    yield put(getNotifyFail(error.response.data));
  }
}

function* NotifySaga() {
  yield takeEvery(GET_NOTIFY, onGetNotify);
}

export default NotifySaga;
