import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./ViolateDetail.scss";
import { site } from "../../api/url_helper";
import { useTranslation } from "react-i18next";

function ListIntern({ dataById }) {
  const defaultAvatar = "./images/avatas/avatar-null.png";
  const defaultFlag = "./images/flag/vn.png";
  const { t } = useTranslation();

  if (!dataById || !dataById.violate_info) {
    return <div>{t("No data")}</div>;
  }

  const data = dataById.violate_info.map((item) => {
    const intern = item.intern_info;
    const syndication = intern.syndication_info;
    const dispatching = intern.dispatching_company_info;
    const receiving = intern.receiving_factory_info;

    return {
      id: item.id,
      namejp: (
        <div className="grid py-3 gap-2">
          <img height="35px" width="35px" src={intern.avata ? `${intern.avata}` : defaultAvatar} alt="avatar" />
          <div className="flex flex-column justify-content-center">
            <p>{`${intern.first_name_jp} ${intern.middle_name_jp} ${intern.last_name_jp}`}</p>
            <p className="mt-1 text-400">{`${intern.first_name_en} ${intern.middle_name_en} ${intern.last_name_en}`}</p>
          </div>
        </div>
      ),
      country: (
        <div className="grid gap-2">
          <img src={defaultFlag} height="20px" width="20px" alt="flag" />
          <p>{intern.country}</p>
        </div>
      ),
      syndication: (
        <div className="grid gap-2">
          <img
            height="35px"
            width="35px"
            src={syndication.avata ? `${syndication.avata}` : defaultAvatar}
            alt="avatar"
          />
          <div className="flex align-items-center">
            <p>{syndication.name_jp}</p>
          </div>
        </div>
      ),
      receiving: (
        <div className="grid gap-2">
          <img height="35px" width="35px" src={receiving.avata ? `${receiving.avata}` : defaultAvatar} alt="avatar" />
          <div className="flex align-items-center">
            <p>{receiving.name_jp}</p>
          </div>
        </div>
      ),
      dispatching: (
        <div className="grid gap-2">
          <img
            height="35px"
            width="35px"
            src={dispatching.avata ? `${dispatching.avata}` : defaultAvatar}
            alt="avatar"
          />
          <div className="flex align-items-center">
            <p>{dispatching.name_jp}</p>
          </div>
        </div>
      ),
    };
  });

  return (
    <div>
      <p className="text-400 font-bold mb-3 mt-4">{t("List of Violating Interns")}</p>
      <DataTable
        className="list-table"
        value={data}
        showGridlines
        emptyMessage={t("No data available")}
        tableStyle={{
          minWidth: "50rem",
          height: "10rem",
          borderLeft: "none",
          borderRight: "none",
        }}
      >
        <Column header={t("No")} body={(data, options) => options.rowIndex + 1}></Column>
        <Column field="namejp" header={t("Intern")}></Column>
        <Column field="country" header={t("Country")}></Column>
        <Column field="syndication" header={t("Syndication")}></Column>
        <Column field="receiving" header={t("Receiving Factory")}></Column>
        <Column field="dispatching" header={t("Dispatching company")}></Column>
      </DataTable>
    </div>
  );
}

export default ListIntern;
