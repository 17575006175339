import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import './Dashboard.scss';
import { getColorForCountry } from './colors';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';

const ColumsChart = ({ dataIntern }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedButton, setSelectedButton] = useState('btn1');
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const [yearNodes, setYearNodes] = useState();

  useEffect(() => {
    if (!dataIntern) {
      console.warn('dataIntern is undefined or null');
      return;
    }

    const years = Object.keys(dataIntern.internCountCountryByYear || {}).map(
      (year) => ({
        key: year,
        label: year,
        value: year,
      })
    );
    setYearNodes(years);

    const convertData = (inputData) => {
      if (!inputData) return [];

      const countriesSet = new Set();
      const categories = Object.keys(inputData);
      const dataByCountry = {};

      categories.forEach((category) => {
        const countriesData = inputData[category] || {};
        Object.keys(countriesData).forEach((country) => {
          countriesSet.add(country);
          if (!dataByCountry[country]) {
            dataByCountry[country] = new Array(categories.length).fill(0);
          }
          const categoryIndex = categories.indexOf(category);
          dataByCountry[country][categoryIndex] = countriesData[country];
        });
      });

      return Array.from(countriesSet).map((country) => ({
        name: t(country),
        data: dataByCountry[country],
        color: getColorForCountry(country),
      }));
    };

    if (selectedButton === 'btn1') {
      const filteredMonthData = convertData(
        Object.fromEntries(
          Object.entries(dataIntern.internCountCountryByMonth || {}).filter(
            ([key]) => key.endsWith(`/${selectedYear}`)
          )
        )
      );
      const filteredMonthCategories = Object.keys(
        dataIntern.internCountCountryByMonth || {}
      ).filter((key) => key.endsWith(`/${selectedYear}`));
      setData(filteredMonthData);
      setCategories(filteredMonthCategories);
    } else {
      const yearData = convertData(dataIntern.internCountCountryByYear || {});
      setData(yearData);
      setCategories(Object.keys(dataIntern.internCountCountryByYear || {}));
    }
  }, [dataIntern, selectedButton, selectedYear]);

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    plotOptions: {
      bar: {
        columnWidth: '22px',
      },
    },
    dataLabels: { enabled: false },
    xaxis: {
      categories: categories,
    },
  };

  const handleClickButton = (buttonType) => {
    setSelectedButton(buttonType);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.value);
  };

  return (
    <div>
      <div
        className='p-3'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <p className='text-xl font-bold'>
          {t('Statistics on the number of interns entering the country')}
        </p>
        <div className='btn-container flex items-center'>
          {selectedButton === 'btn1' && (
            <Dropdown
              value={selectedYear}
              options={yearNodes}
              onChange={handleYearChange}
              placeholder={t('Select a Year')}
              className='year-select'
              style={{ marginRight: '4px', height: '34px' }}
            />
          )}
          <button
            className={`${
              selectedButton === 'btn1' ? 'btn-active ' : 'btn-inactive'
            } text-white`}
            style={{
              cursor: 'pointer',
              width: '81px',
              padding: '4px',
              marginRight: '4px',
              borderRadius: '4px',
              height: '34px',
            }}
            onClick={() => handleClickButton('btn1')}>
            {t('Month')}
          </button>
          <button
            className={`${
              selectedButton === 'btn2' ? 'btn-active' : 'btn-inactive'
            } text-white`}
            style={{
              cursor: 'pointer',
              width: '81px',
              padding: '4px',
              borderRadius: '4px',
              height: '34px',
            }}
            onClick={() => handleClickButton('btn2')}>
            {t('Year')}
          </button>
        </div>
      </div>
      <ReactApexChart
        height={'300px'}
        options={options}
        series={data}
        type='bar'
      />
    </div>
  );
};

export default ColumsChart;
