import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import reducer from "./reducers";
import mySaga from "./sagas";

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
// Mount it on the Store
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          "SET_INTERN",
          "UPDATE_INTERN",
          "SET_DISPATCHING_COMPANY",
          "UPDATE_DISPATCHING_COMPANY",
          "SET_RECEIVING_FACTORY",
          "UPDATE_RECEIVING_FACTORY",
          "SET_SYNDICATION",
          "UPDATE_SYNDICATION",
          "SET_VIOLATE",
        ],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        // Ignore these paths in the state
        ignoredPaths: ["items.dates"],
      },
    }).concat(middleware),
});

sagaMiddleware.run(mySaga);
export default store;
