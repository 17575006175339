import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { Image } from 'primereact/image';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import { DataContext } from '../../../../contexts/data/DataProvider';

export default function SidebarMenu() {
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState([]);
  const location = useLocation();
  const dataInfo = useSelector((state) => state.User.dataInfo, shallowEqual);
  const { selectedLang, setSelectedItem } = useContext(DataContext);

  function convertPathToTitle(path) {
    // Loại bỏ dấu gạch chéo ở đầu chuỗi nếu có
    if (path.startsWith('/')) {
      path = path.slice(1);
    }

    // Thay thế các dấu gạch ngang bằng dấu cách và viết hoa chữ cái đầu
    const title = path
      .replace(/-/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return title;
  }

  useEffect(() => {
    const selectedItem = t(convertPathToTitle(location.pathname));
    setSelectedItem(selectedItem);
  }, [location.pathname, selectedLang]);

  const itemRenderer = (item) => {
    const isSelected = location.pathname === item.url;

    return (
      <Link
        to={item.url}
        className={`flex align-items-center p-menuitem-content p-menuitem-link border-round-md ml-3 mr-3 py-3 pl-5 pr-3 ${
          isSelected ? 'font-bold text-green-600 bg-green-100' : ''
        }`}>
        <span className={item.icon} />
        <span className='mx-3 white-space-nowrap'>{t(item.label)}</span>
      </Link>
    );
  };

  const object_type = JSON.parse(localStorage.getItem('object_type'));

  useEffect(() => {
    if (object_type && object_type === 'intern') {
      setItems(itemsForIntern);
    } else {
      setItems(itemsCustom);
    }
  }, [location.pathname, selectedLang, i18n.language]);

  const itemsCustom = [
    {
      template: () => <></>,
    },
    {
      label: 'Dashboard',
      icon: 'pi pi-chart-bar',
      url: '/dashboard',
      template: itemRenderer,
    },
    {
      label: 'Support',
      icon: 'pi pi-headphones',
      url: '/support',
      template: itemRenderer,
    },
    {
      label: 'News',
      icon: 'pi pi-megaphone',
      url: '/notification',
      template: itemRenderer,
    },
    {
      label: t('Intern'),
      items: [
        {
          label: 'Intern list',
          icon: 'pi pi-users',
          url: '/intern',
          template: itemRenderer,
        },
        {
          label: 'Violate List',
          icon: 'pi pi-exclamation-triangle',
          url: '/violate',
          template: itemRenderer,
        },
      ],
    },
    {
      label: t('Partner'),
      items: [
        {
          label: 'Syndication',
          icon: 'pi pi-briefcase',
          url: '/syndication',
          template: itemRenderer,
          visible: object_type !== 'syndication',
        },
        {
          label: 'Receiving Factory',
          icon: 'pi pi-building',
          url: '/receiving-factory',
          template: itemRenderer,
          visible: object_type !== 'receiving_factory',
        },
        {
          label: 'Dispatching company',
          icon: 'pi pi-globe',
          url: '/dispatching-company',
          template: itemRenderer,
          visible: object_type !== 'dispatching_company',
        },
      ],
    },
    {
      label: t('Categories'),
      items: [
        {
          label: 'Career category',
          icon: 'pi pi-list',
          url: '/career',
          template: itemRenderer,
        },
        {
          label: 'Status category',
          icon: 'pi pi-list',
          url: '/status',
          template: itemRenderer,
        },
        {
          label: 'Violate type',
          icon: 'pi pi-list',
          url: '/violate-type',
          template: itemRenderer,
        },
      ],
    },
  ];

  const itemsForIntern = [
    {
      template: () => <></>,
    },
    {
      label: 'Intern',
      items: [
        {
          label: 'Support',
          icon: 'pi pi-headphones',
          url: '/support',
          template: itemRenderer,
        },
        {
          label: 'Reminder',
          icon: 'pi pi-megaphone',
          url: '/notification',
          template: itemRenderer,
        },
      ],
    },
  ];

  return (
    <div className='flex flex-column h-screen md:border-right-1 border-gray-200'>
      <div className='hidden md:flex justify-content-center p-3'>
        <Image
          src='images/logo/logo.png'
          alt='Image'
          width='80'
        />
      </div>
      <Menu
        model={items}
        className='w-full md:w-18rem border-noround overflow-auto border-none'
      />
    </div>
  );
}
