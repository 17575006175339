import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

export default function ModelDelete({ visible, setVisible, accept }) {
  return (
    <>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to proceed?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={() => setVisible(false)}
      />
    </>
  );
}
