import React, { useEffect, useRef } from "react";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BrowserInfo } from "../../ultis/GetInfoClient";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { site } from "../../api/url_helper";

function ForgetPassword() {
  const location = useLocation();
  const email = location.state?.email;
  const toast = useRef(null);
  const navigate = useNavigate();
  const browserInfo = BrowserInfo();
  const [client, setClient] = React.useState({});

  const { t } = useTranslation();

  // lay thong tin ip nguoi dung
  useEffect(() => {
    axios.get("https://api.ipify.org?format=json").then((res) => {
      browserInfo.ip = res.data.ip;
    });
    setClient(browserInfo);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .min(6, "Password must be at least 6 characters long")
        .required(t("Do not leave it blank")),
      confirmPassword: Yup.string()
        .required("Please Confirm Your Password")
        .oneOf([Yup.ref("newPassword"), null], "Password incorrect"),
    }),
    onSubmit: async (values) => {
      let data = {
        email: email,
        newPassword: values.newPassword,
      };
      axios
        .put(`${site}/api/users/forgot-password`, data)
        .then((res) => {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: res.data.message,
            life: 3000,
          });
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error,
            life: 3000,
          });
        });
    },
  });

  return (
    <div className="p-3" style={{ width: "500px" }}>
      <form>
        <Toast ref={toast} />
        <div
          className="flex align-content-center justify-content-between bg-green-100 px-5 pt-5"
          style={{
            borderTopLeftRadius: "0.375rem",
            borderTopRightRadius: "0.375rem",
          }}
        >
          <div className="flex flex-column">
            <span className="mb-2 text-green-800">{t("Reset password")}.</span>
            <span className="text-green-800  ">
              {t("Enter a new password to complete")}!
            </span>
          </div>
          <Image src="/images/logo/logo.png" alt="Image" width="100" />
        </div>
        <div
          className="bg-white p-5"
          style={{
            borderBottomLeftRadius: "0.375rem",
            borderBottomRightRadius: "0.375rem",
          }}
        >
          <div className="flex flex-column mb-4">
            <label className="mb-2">{t("New password")}</label>
            <InputText
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="newPassword"
              name="newPassword"
              type="password"
              autoComplete="off"
              placeholder={t("New password")}
              className="w-full "
              style={{ padding: "1rem" }}
              invalid={
                formik.touched.newPassword && formik.errors.newPassword
                  ? true
                  : false
              }
            />
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <p className="text-red-500">{formik.errors.newPassword}</p>
            ) : null}
          </div>
          <div className="flex flex-column my-5">
            <label className="mb-2">{t("Confirm new password")}</label>
            <InputText
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              autoComplete="off"
              placeholder={t("Confirm new password")}
              className="w-full"
              style={{ padding: "1rem" }}
              invalid={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? true
                  : false
              }
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <p className="text-red-500">{formik.errors.confirmPassword}</p>
            ) : null}
          </div>
          <Button
            type="submit"
            label={t("Save")}
            className="w-full mt-5"
            onClick={formik.handleSubmit}
          />
          <div className="flex justify-content-center flex-row mt-8">
            <div className="flex align-content-center ">
              <span className="">
                {`${t("Back to")} `}
                <Link to={"/login"}>
                  <span className="cursor-pointer hover:text-green-600">
                    {t("Login")}
                  </span>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ForgetPassword;
