import {
  GET_VIOLATE_TYPE_BY_ID,
  GET_VIOLATE_TYPE_BY_ID_SUCCESS,
  GET_VIOLATE_TYPE_BY_ID_FAIL,
  GET_VIOLATE_TYPE_BY_USER_ID,
  GET_VIOLATE_TYPE_BY_USER_ID_SUCCESS,
  GET_VIOLATE_TYPE_BY_USER_ID_FAIL,
  SET_VIOLATE_TYPE,
  SET_VIOLATE_TYPE_SUCCESS,
  SET_VIOLATE_TYPE_FAIL,
  UPDATE_VIOLATE_TYPE,
  UPDATE_VIOLATE_TYPE_SUCCESS,
  UPDATE_VIOLATE_TYPE_FAIL,
  DELETE_VIOLATE_TYPE,
  DELETE_VIOLATE_TYPE_SUCCESS,
  DELETE_VIOLATE_TYPE_FAIL,
  SET_DATA_NULL
} from "./actionTypes";

export const getViolateTypeById = id => ({
  type: GET_VIOLATE_TYPE_BY_ID,
  payload: id,
});

export const getViolateTypeByIdSuccess = data => ({
  type: GET_VIOLATE_TYPE_BY_ID_SUCCESS,
  payload: data,
});

export const getViolateTypeByIdFail = error => ({
  type: GET_VIOLATE_TYPE_BY_ID_FAIL,
  payload: error,
});

export const getViolateTypeByUserId = id => ({
  type: GET_VIOLATE_TYPE_BY_USER_ID,
  payload: id,
});

export const getViolateTypeByUserIdSuccess = data => ({
  type: GET_VIOLATE_TYPE_BY_USER_ID_SUCCESS,
  payload: data,
});

export const getViolateTypeByUserIdFail = error => ({
  type: GET_VIOLATE_TYPE_BY_USER_ID_FAIL,
  payload: error,
});

export const setViolateType = data => ({
  type: SET_VIOLATE_TYPE,
  payload: data
});

export const setViolateTypeSuccess = data => ({
  type: SET_VIOLATE_TYPE_SUCCESS,
  payload: data,
});

export const setViolateTypeFail = error => ({
  type: SET_VIOLATE_TYPE_FAIL,
  payload: error,
});

export const updateViolateType = (data) => ({
  type: UPDATE_VIOLATE_TYPE,
  payload: data
});

export const updateViolateTypeSuccess = data => ({
  type: UPDATE_VIOLATE_TYPE_SUCCESS,
  payload: data,
});

export const updateViolateTypeFail = error => ({
  type: UPDATE_VIOLATE_TYPE_FAIL,
  payload: error,
});

export const deleteViolateType = (id) => ({
  type: DELETE_VIOLATE_TYPE,
  payload: id
});

export const deleteViolateTypeSuccess = data => ({
  type: DELETE_VIOLATE_TYPE_SUCCESS,
  payload: data,
});

export const deleteViolateTypeFail = error => ({
  type: DELETE_VIOLATE_TYPE_FAIL,
  payload: error,
});

export const setDataNull = () => ({
  type: SET_DATA_NULL,
});