import React, { useEffect } from 'react';
import './ViolateDetail.scss';
import { Timeline } from 'primereact/timeline';
import { Image } from 'primereact/image';
import { site } from '../../api/url_helper';
import { useTranslation } from 'react-i18next';

function HistoryViolate({ dataById }) {
  const { t } = useTranslation();
  const avatar = './images/avatas/avatar-null.png';
  if (!dataById || !dataById.violate_handle_info) {
    return <div>{t('No data available')}</div>;
  }

  const data = dataById.violate_handle_info.map((item) => {
    const images =
      item.violate_handle_file_info.length > 0
        ? item.violate_handle_file_info.map((file) => `${site}${file.url}`)
        : [];

    return {
      handle_date: new Date(item.handle_date).toLocaleDateString(),
      handle_content: item.handle_content,
      handler: item.handler,
      avatar,
      images,
    };
  });

  const events = data.map((item, index) => {
    return {
      content: (
        <div
          key={index}
          className='mb-5'>
          <p className='font-bold'>{item.handle_date}</p>
          <p className='my-3'>{item.handle_content}</p>
          <div className='grid align-items-center gap-1 ml-1 my-3'>
            <img
              width='20px'
              height='20px'
              src={item.avatar}
              alt='avatar'
            />
            <p>{item.handler}</p>
          </div>
          {item.images.map((e, index) => (
            <Image
              src={e}
              alt='Image'
              width='50'
              height='50'
              preview
              className='mr-2'
              style={{ objectFit: 'cover' }}
            />
          ))}
        </div>
      ),
      date: item.handle_date,
    };
  });

  return (
    <div>
      <p className='text-400 font-bold mb-3 mt-6'>
        {t('History of Violation Handling Process')}
      </p>
      <Timeline
        value={events}
        content={(item) => item.content}
      />
    </div>
  );
}

export default HistoryViolate;
