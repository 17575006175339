import {
  GET_TICKET_LIST,
  GET_TICKET_LIST_SUCCESS,
  GET_TICKET_LIST_FAIL,
  GET_TICKET_DETAIL_LIST,
  GET_TICKET_DETAIL_LIST_SUCCESS,
  GET_TICKET_DETAIL_LIST_FAIL,
  SET_TICKET,
  SET_TICKET_SUCCESS,
  SET_TICKET_FAIL,
  UPDATE_TICKET,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_FAIL,
  SET_DATA_NULL,
  SET_REPLY_TICKET,
  SET_REPLY_TICKET_SUCCESS,
  SET_REPLY_TICKET_FAIL,
  TRANSLATE_TICKET,
  TRANSLATE_TICKET_SUCCESS,
  TRANSLATE_TICKET_FAIL,
  GET_LIST_USER_FAILURE,
  GET_LIST_USER_SUCCESS,
  GET_LIST_USER_REQUEST,
  CLOSE_TICKET,
  CLOSE_TICKET_SUCCESS,
  CLOSE_TICKET_FAIL,
  RECEIVER_TICKET,
} from "./actionTypes";

const INIT_STATE = {
  data: [],
  datas: [],
  dataId: null,
  dataTicketList: [],
  menuTicketList: [],
  total: 0,
  dataTicketDetailList: [],
  totalInBox: null,
  totalOutBox: null,
  dataTranslate: null,
  errorTranslate: null,
  dataClose: null,
  // data: null,
  loading: false,
  dataInsert: null,
  loadingInsert: false,
  loadingUpdate: false,
  loadingDelete: false,
  loadingTranslate: false,
  error: null,
  errorInsert: null,
  errorUpdate: null,
  errorDelete: null,
};

const Ticket = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TICKET_LIST:
      return { ...state, loading: true, error: null };
    case GET_TICKET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dataTicketList: action.payload.data,
        menuTicketList: action.payload.menu,
        totalInBox: action.payload.total_inbox,
        totalOutBox: action.payload.total_outbox,
        total: action.payload.total,
        error: null,
      };
    case GET_TICKET_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_TICKET_DETAIL_LIST:
      return { ...state, loading: true, error: null };
    case GET_TICKET_DETAIL_LIST_SUCCESS:
      const formattedMessages = formatMessages(action.payload);
      formattedMessages.sort(
        (a, b) => new Date(a.send_at) - new Date(b.send_at)
      );
      return {
        ...state,
        loading: false,
        dataTicketDetailList: formattedMessages,
        error: null,
      };
    case GET_TICKET_DETAIL_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case SET_TICKET:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: true,
        errorInsert: null,
      };
    case SET_TICKET_SUCCESS:
      return {
        ...state,
        dataInsert: action.payload,
        loadingInsert: false,
        errorInsert: null,
      };
    case SET_TICKET_FAIL:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: false,
        errorInsert: action.payload,
      };
    case SET_REPLY_TICKET:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: true,
        errorInsert: null,
      };
    case SET_REPLY_TICKET_SUCCESS:
      // console.log("reducer - reply ticket success: ", action.payload.data);
      return {
        ...state,
        // dataInsert: action.payload,
        dataTicketDetailList: [
          ...state.dataTicketDetailList,
          formatMessage(action.payload.data),
        ],
        loadingInsert: false,
        errorInsert: null,
      };
    case SET_REPLY_TICKET_FAIL:
      return {
        ...state,
        dataInsert: null,
        loadingInsert: false,
        errorInsert: action.payload,
      };
    case RECEIVER_TICKET:
      console.log(action.payload.data);
      return {
        ...state,
        dataTicketDetailList: [
          ...state.dataTicketDetailList,
          formatMessage(action.payload),
        ],
      };
    case TRANSLATE_TICKET:
      return {
        ...state,
        loadingTranslate: true,
        dataTranslate: null,
        errorsTranslate: null,
      };

    case TRANSLATE_TICKET_SUCCESS:
      const translatedMessage = action.payload;
      return {
        ...state,
        loadingTranslate: false,
        dataTicketDetailList: state.dataTicketDetailList.map((ticket) =>
          ticket.id === translatedMessage.id
            ? {
                ...ticket,
                ...translatedMessage,
                is_translate: true,
                content_en: translatedMessage.content_en,
                content_vi: translatedMessage.content_vi,
                content_ja: translatedMessage.content_ja,
              }
            : ticket
        ),
      };

    case TRANSLATE_TICKET_FAIL:
      return {
        ...state,
        dataTranslate: null,
        loadingTranslate: false,
        errorsTranslate: action.payload,
      };
    case GET_LIST_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_LIST_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_LIST_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_TICKET:
      return { ...state, loading: true };
    case UPDATE_TICKET_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case UPDATE_TICKET_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CLOSE_TICKET:
      return { ...state, loading: true };
    case CLOSE_TICKET_SUCCESS:
      console.log(
        "CLOSE_TICKET_SUCCESS - reducer: ",
        action.payload.id.ticket_id
      );

      let updateListDataTicket = state.dataTicketList.map((ticket) => {
        if (ticket.id === action.payload.id.ticket_id) {
          return { ...ticket, ticket_status: "Done" };
        }
        return ticket;
      });
      console.log("🚀 ~ Ticket ~ updateListDataTicket:", updateListDataTicket);

      return {
        ...state,
        dataTicketList: updateListDataTicket,
        loading: false,
      };
    case CLOSE_TICKET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_DATA_NULL:
      return {
        ...state,
        data: null,
        dataTranslate: null,
        dataTicketDetailList: [],
        dataTicketList: [],
      };
    default:
      return { ...state };
  }
};

const formatMessages = (ticketDetailList) => {
  return ticketDetailList
    ? ticketDetailList.map((ticket) => ({
        id: ticket.id,
        sender_id: ticket.sender_id,
        content: ticket.content,
        send_at: ticket.send_at,
        type: ticket.ticket_type ? ticket.ticket_type.toLowerCase() : "text",
        url: ticket.url,
        is_translate: ticket.is_translate,
        sender_name: ticket.sender_name,
        sender_avata: ticket.sender_avata,
      }))
    : [];
};

const formatMessage = (message) => {
  console.log("🚀 ~ formatMessage ~ message:", message);

  return {
    id: message.id,
    sender_id: message.sender_id,
    content: message.content,
    send_at: message.send_at,
    type: message.ticket_type.toLowerCase(),
    url: message.url,
    is_translate: message.is_translate,
    sender_name: message.sender_name,
    sender_avata: message.sender_avata,
    content_en: message.content_en,
    content_vi: message.content_vi,
    content_ja: message.content_ja,
  };
};

export default Ticket;
