// import React, { useState } from 'react';
// import ExcelJS from 'exceljs';

// function ExcelReader() {
//     const [lastRow, setLastRow] = useState(null);
//     const [error, setError] = useState(null);

//     const handleFileUpload = async (e) => {
//         const file = e.target.files[0];
//         if (!file) {
//             setError('Không có file nào được chọn');
//             return;
//         }

//         const workbook = new ExcelJS.Workbook();

//         try {
//             await workbook.xlsx.load(file);

//             console.log('Số lượng sheets:', workbook.worksheets.length);

//             if (workbook.worksheets.length === 0) {
//                 throw new Error('Workbook không chứa sheet nào');
//             }

//             const worksheet = workbook.worksheets[0]; // Lấy sheet đầu tiên

//             console.log('Tên sheet:', worksheet.name);
//             console.log('Số hàng:', worksheet.rowCount);
//             console.log('Số cột:', worksheet.columnCount);

//             if (!worksheet || worksheet.rowCount === undefined) {
//                 throw new Error('Không thể đọc dữ liệu từ worksheet');
//             }

//             // Tìm dòng cuối cùng có dữ liệu trong cột B
//             let lastRowWithData = worksheet.rowCount;
//             for (let row = worksheet.rowCount; row >= 1; row--) {
//                 const cell = worksheet.getCell(`B${row}`);
//                 if (cell && cell.value !== null && cell.value !== undefined) {
//                     lastRowWithData = row;
//                     break;
//                 }
//             }

//             // Lấy giá trị của ô B ở dòng cuối cùng có dữ liệu
//             const lastCellValue = worksheet.getCell(`B${lastRowWithData}`).value;

//             setLastRow(lastCellValue);
//             setError(null);

//             // Log để kiểm tra
//             console.log('Dòng cuối cùng có dữ liệu trong cột B:', lastRowWithData);
//             console.log('Giá trị của ô B ở dòng cuối cùng:', lastCellValue);
//         } catch (error) {
//             console.error('Lỗi khi đọc file:', error);
//             setError(`Lỗi khi đọc file: ${error.message}`);
//         }
//     };

//     return (
//         <div>
//             <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
//             {error && <p style={{ color: 'red' }}>{error}</p>}
//             {lastRow !== null && <p>Giá trị ở dòng cuối cùng của cột B: {lastRow}</p>}
//         </div>
//     );
// }

// export default ExcelReader;














import React from 'react';
import ExcelJS from 'exceljs';

function ExportExcel() {
    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Định nghĩa các cột
        const columns = [
            { header: 'Họ tiếng nhật', key: 'lastNameJp' },
            { header: 'Tên đệm tiếng nhật', key: 'middleNameJp' },
            { header: 'Tên tiếng nhật', key: 'firstNameJp' },
            { header: 'Họ tiếng anh', key: 'lastNameEn' },
            { header: 'Tên đệm tiếng anh', key: 'middleNameEn' },
            { header: 'Tên tiếng anh', key: 'firstNameEn' },
            { header: 'Quốc gia', key: 'country' },
            { header: 'Giới tính', key: 'gender' },
            { header: 'Mã thực tập sinh', key: 'traineeCode' },
            { header: 'Ngày sinh', key: 'birthDate' },
            { header: 'Số hộ chiếu', key: 'passportNumber' },
            { header: 'Ngày cấp hộ chiếu', key: 'passportIssueDate' },
            { header: 'Ngày hết hạn hộ chiếu', key: 'passportExpiryDate' },
            { header: 'Số điện thoại', key: 'phoneNumber' },
            { header: 'Email', key: 'email' },
            { header: 'Ghi chú', key: 'notes' },
            { header: 'Mã nghiệp đoàn', key: 'unionCode' },
            { header: 'Mã xí nghiệp', key: 'companyCode' },
            { header: 'Mã phái cử', key: 'dispatchCode' },
            { header: 'Ngày nhập cảnh', key: 'entryDate' },
            { header: 'Ngành nghề', key: 'occupation' },
            { header: 'Tư cách lưu trú', key: 'residenceStatus' },
            { header: 'Số thẻ ngoại kiều', key: 'foreignerCardNumber' },
            { header: 'Ngày cấp thẻ ngoại kiều', key: 'foreignerCardIssueDate' },
            { header: 'Ngày hết hạn thẻ ngoại kiều', key: 'foreignerCardExpiryDate' },
            { header: 'Địa chỉ', key: 'address' },
        ];

        worksheet.columns = columns;

        // Định dạng các cột
        worksheet.columns.forEach(column => {
            if (['birthDate', 'passportIssueDate', 'passportExpiryDate', 'entryDate', 'foreignerCardIssueDate', 'foreignerCardExpiryDate'].includes(column.key)) {
                column.numFmt = 'yyyy/mm/dd';
            } else {
                column.numFmt = '@';
            }
        });

        // Tạo dropdown cho cột quốc gia
        worksheet.dataValidations.add('G2:G1001', {
            type: 'list',
            allowBlank: true,
            formulae: ['"Việt Nam,Trung Quốc,Nhật Bản"']
        });


        // Mở khóa cho các ô
        for (let row = 2; row <= 1000; row++) {
            for (let col = 1; col <= 50; col++) {
                let cell = worksheet.getCell(row, col);
                cell.protection = {
                    locked: false,
                    hidden: false
                };
            }
        }

        // Bảo vệ sheet
        worksheet.protect('1111', {
            selectLockedCells: true,
            selectUnlockedCells: true,
            formatCells: true,
            formatColumns: true,
            formatRows: true,
            insertColumns: false,
            insertRows: false,
            insertHyperlinks: false,
            deleteColumns: false,
            deleteRows: false,
            sort: false,
            autoFilter: false,
            pivotTables: false
        });

        // Tạo và tải xuống file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'trainee_data.xlsx';
        link.click();
        URL.revokeObjectURL(link.href);
    };

    return (
        <button onClick={exportToExcel}>Xuất Excel</button>
    );
}

export default ExportExcel;