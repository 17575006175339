import {
  GET_STATE_ALL,
  GET_STATE_ALL_SUCCESS,
  GET_STATE_ALL_FAIL,
  GET_STATE_ID,
  GET_STATE_ID_SUCCESS,
  GET_STATE_ID_FAIL,
  GET_STATE_BY_COUNTRY_ID,
  GET_STATE_BY_COUNTRY_ID_SUCCESS,
  GET_STATE_BY_COUNTRY_ID_FAIL,
  SET_STATE,
  SET_STATE_SUCCESS,
  SET_STATE_FAIL,
  UPDATE_STATE,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAIL,
  DELETE_STATE,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAIL
} from "./actionTypes";

export const getStateAll = () => ({
  type: GET_STATE_ALL,
});

export const getStateAllSuccess = data => ({
  type: GET_STATE_ALL_SUCCESS,
  payload: data,
});

export const getStateAllFail = error => ({
  type: GET_STATE_ALL_FAIL,
  payload: error,
});

export const getStateId = id => ({
  type: GET_STATE_ID,
  payload: id,
});

export const getStateIdSuccess = data => ({
  type: GET_STATE_ID_SUCCESS,
  payload: data,
});

export const getStateIdFail = error => ({
  type: GET_STATE_ID_FAIL,
  payload: error,
});

export const getStateByCountryId = id => ({
  type: GET_STATE_BY_COUNTRY_ID,
  payload: id,
});

export const getStateByCountryIdSuccess = data => ({
  type: GET_STATE_BY_COUNTRY_ID_SUCCESS,
  payload: data,
});

export const getStateByCountryIdFail = error => ({
  type: GET_STATE_BY_COUNTRY_ID_FAIL,
  payload: error,
});

export const setState = data => ({
  type: SET_STATE,
  payload: data
});

export const setStateSuccess = data => ({
  type: SET_STATE_SUCCESS,
  payload: data,
});

export const setStateFail = error => ({
  type: SET_STATE_FAIL,
  payload: error,
});

export const updateState = (data) => ({
  type: UPDATE_STATE,
  payload: data
});

export const updateStateSuccess = data => ({
  type: UPDATE_STATE_SUCCESS,
  payload: data,
});

export const updateStateFail = error => ({
  type: UPDATE_STATE_FAIL,
  payload: error,
});

export const deleteState = (data) => ({
  type: DELETE_STATE,
  payload: data
});

export const deleteStateSuccess = data => ({
  type: DELETE_STATE_SUCCESS,
  payload: data,
});

export const deleteStateFail = error => ({
  type: DELETE_STATE_FAIL,
  payload: error,
});