import { createContext, useState } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  // socket io
  const [message, setMessage] = useState("");
  const [socket, setSocket] = useState();
  const [skTicket, setSkTicket] = useState("");
  const [updateUserCount, setUpdateUserCount] = useState();
  const [notification, setNotification] = useState();
  const [keyLicense, setKeyLicense] = useState("");
  const [selectedLang, setSelectedLang] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  return (
    <DataContext.Provider
      value={{
        socket,
        setSocket,
        message,
        setMessage,
        skTicket,
        setSkTicket,
        updateUserCount,
        setUpdateUserCount,
        notification,
        setNotification,
        keyLicense,
        setKeyLicense,
        selectedLang,
        setSelectedLang,
        selectedItem,
        setSelectedItem,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
