import {
  GET_COUNTRY_ALL,
  GET_COUNTRY_ALL_SUCCESS,
  GET_COUNTRY_ALL_FAIL,
  GET_COUNTRY_ID,
  GET_COUNTRY_ID_SUCCESS,
  GET_COUNTRY_ID_FAIL,
  SET_COUNTRY,
  SET_COUNTRY_SUCCESS,
  SET_COUNTRY_FAIL,
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAIL,
  DELETE_COUNTRY,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAIL
} from "./actionTypes";

export const getCountryAll = () => ({
  type: GET_COUNTRY_ALL,
});

export const getCountryAllSuccess = data => ({
  type: GET_COUNTRY_ALL_SUCCESS,
  payload: data,
});

export const getCountryAllFail = error => ({
  type: GET_COUNTRY_ALL_FAIL,
  payload: error,
});

export const getCountryId = id => ({
  type: GET_COUNTRY_ID,
  payload: id,
});

export const getCountryIdSuccess = data => ({
  type: GET_COUNTRY_ID_SUCCESS,
  payload: data,
});

export const getCountryIdFail = error => ({
  type: GET_COUNTRY_ID_FAIL,
  payload: error,
});

export const setCountry = data => ({
  type: SET_COUNTRY,
  payload: data
});

export const setCountrySuccess = data => ({
  type: SET_COUNTRY_SUCCESS,
  payload: data,
});

export const setCountryFail = error => ({
  type: SET_COUNTRY_FAIL,
  payload: error,
});

export const updateCountry = (data) => ({
  type: UPDATE_COUNTRY,
  payload: data
});

export const updateCountrySuccess = data => ({
  type: UPDATE_COUNTRY_SUCCESS,
  payload: data,
});

export const updateCountryFail = error => ({
  type: UPDATE_COUNTRY_FAIL,
  payload: error,
});

export const deleteCountry = (data) => ({
  type: DELETE_COUNTRY,
  payload: data
});

export const deleteCountrySuccess = data => ({
  type: DELETE_COUNTRY_SUCCESS,
  payload: data,
});

export const deleteCountryFail = error => ({
  type: DELETE_COUNTRY_FAIL,
  payload: error,
});