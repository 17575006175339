import {
  GET_RECEIVING_FACTORY_BY_ID,
  GET_RECEIVING_FACTORY_BY_ID_SUCCESS,
  GET_RECEIVING_FACTORY_BY_ID_FAIL,
  GET_RECEIVING_FACTORY_BY_USER_ID,
  GET_RECEIVING_FACTORY_BY_USER_ID_SUCCESS,
  GET_RECEIVING_FACTORY_BY_USER_ID_FAIL,
  GET_RECEIVING_FACTORY_LIST,
  GET_RECEIVING_FACTORY_LIST_FAIL,
  GET_RECEIVING_FACTORY_LIST_SUCCESS,
  GET_RECEIVING_FACTORY_BY_KEY_ID,
  GET_RECEIVING_FACTORY_BY_KEY_ID_SUCCESS,
  GET_RECEIVING_FACTORY_BY_KEY_ID_FAIL,
  SET_RECEIVING_FACTORY,
  SET_RECEIVING_FACTORY_SUCCESS,
  SET_RECEIVING_FACTORY_FAIL,
  UPDATE_RECEIVING_FACTORY,
  UPDATE_RECEIVING_FACTORY_SUCCESS,
  UPDATE_RECEIVING_FACTORY_FAIL,
  DELETE_RECEIVING_FACTORY,
  DELETE_RECEIVING_FACTORY_SUCCESS,
  DELETE_RECEIVING_FACTORY_FAIL,
  SET_DATA_NULL,
} from "./actionTypes";

export const getReceivingFactoryById = (id) => ({
  type: GET_RECEIVING_FACTORY_BY_ID,
  payload: id,
});

export const getReceivingFactoryByIdSuccess = (data) => ({
  type: GET_RECEIVING_FACTORY_BY_ID_SUCCESS,
  payload: data,
});

export const getReceivingFactoryByIdFail = (error) => ({
  type: GET_RECEIVING_FACTORY_BY_ID_FAIL,
  payload: error,
});

export const getReceivingFactoryByUserId = () => ({
  type: GET_RECEIVING_FACTORY_BY_USER_ID,
});

export const getReceivingFactoryByUserIdSuccess = (data) => ({
  type: GET_RECEIVING_FACTORY_BY_USER_ID_SUCCESS,
  payload: data,
});

export const getReceivingFactoryByUserIdFail = (error) => ({
  type: GET_RECEIVING_FACTORY_BY_USER_ID_FAIL,
  payload: error,
});

// Them moi
export const getReceivingFactoryGetList = () => ({
  type: GET_RECEIVING_FACTORY_LIST,
});

export const getReceivingFactoryGetListSuccess = (data) => ({
  type: GET_RECEIVING_FACTORY_LIST_SUCCESS,
  payload: data,
});

export const getReceivingFactoryGetListFail = (error) => ({
  type: GET_RECEIVING_FACTORY_LIST_FAIL,
  payload: error,
});

// end
export const getReceivingFactoryByKeyId = (id) => ({
  type: GET_RECEIVING_FACTORY_BY_KEY_ID,
  payload: id,
});

export const getReceivingFactoryByKeyIdSuccess = (data) => ({
  type: GET_RECEIVING_FACTORY_BY_KEY_ID_SUCCESS,
  payload: data,
});

export const getReceivingFactoryByKeyIdFail = (error) => ({
  type: GET_RECEIVING_FACTORY_BY_KEY_ID_FAIL,
  payload: error,
});

export const setReceivingFactory = (values, file) => ({
  type: SET_RECEIVING_FACTORY,
  payload: { values, file },
});

export const setReceivingFactorySuccess = (data) => ({
  type: SET_RECEIVING_FACTORY_SUCCESS,
  payload: data,
});

export const setReceivingFactoryFail = (error) => ({
  type: SET_RECEIVING_FACTORY_FAIL,
  payload: error,
});

export const updateReceivingFactory = (values, file) => ({
  type: UPDATE_RECEIVING_FACTORY,
  payload: { values, file },
});

export const updateReceivingFactorySuccess = (data) => ({
  type: UPDATE_RECEIVING_FACTORY_SUCCESS,
  payload: data,
});

export const updateReceivingFactoryFail = (error) => ({
  type: UPDATE_RECEIVING_FACTORY_FAIL,
  payload: error,
});

export const deleteReceivingFactory = (id) => ({
  type: DELETE_RECEIVING_FACTORY,
  payload: id,
});

export const deleteReceivingFactorySuccess = (data) => ({
  type: DELETE_RECEIVING_FACTORY_SUCCESS,
  payload: data,
});

export const deleteReceivingFactoryFail = (error) => ({
  type: DELETE_RECEIVING_FACTORY_FAIL,
  payload: error,
});

export const setDataNull = () => ({
  type: SET_DATA_NULL,
});
