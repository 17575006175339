import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_NEW_INTERN,
  GET_NEW_INTERN_SUCCESS,
  GET_NEW_INTERN_FAIL,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  datas: [],
  loading: false,
  dataIntern: [],
  dataNotification: [],
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        datas: action.payload,
        loading: false,
      };

    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_NEW_INTERN:
      return {
        ...state,
        loading: true,
      };
    case GET_NEW_INTERN_SUCCESS:
      return {
        ...state,
        dataIntern: action.payload,
        loading: false,
      };
    case GET_NEW_INTERN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        dataNotification: action.payload,
        loading: false,
      };
    case GET_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Dashboard;
