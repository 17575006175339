import React, { useEffect, useState } from 'react';
import './ViolateDetail.scss';
import ListIntern from './List';
import HistoryViolate from './History';
import ModelAddViolate from './ModelAddViolate';
import { Button } from 'primereact/button';
import { getViolateById } from '../../store/violate/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

function ViolateDetail({ onHide, toast, internData }) {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { dataById } = useSelector(
    (state) => ({
      dataById: state.Violate.dataById?.data,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (internData?.id) {
      dispatch(
        getViolateById({
          id: internData.id,
        })
      );
    }
  }, [dispatch, internData, visible]);

  const dateTemplate = (violateDate) => {
    return (
      <div className='flex flex-column'>
        <span className='font-bold'>
          {moment(violateDate).utcOffset(+9).format('YYYY-MM-DD')}
        </span>
        <span>{moment(violateDate).utcOffset(+9).format('HH-mm')}</span>
      </div>
    );
  };

  return (
    <Dialog
      // header="Chi tiết vi phạm"
      visible={true}
      style={{ width: '88%', height: '90%' }}
      onHide={onHide}
      // focusOnShow={false}
    >
      <div className='bg-white border-round-xl'>
        <div className='border-bottom-1 px-6 py-4 border-200'>
          <div className='grid align-items-center'>
            <div className='grid align-items-center col-10'>
              <div
                style={{
                  display: 'flex',
                  padding: '4px 4px 4px 6px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '4px',
                  marginLeft: '-1rem',
                }}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 24 24'
                  className='cursor-pointer'
                  onClick={onHide}>
                  <path
                    fill='currentColor'
                    d='M12.727 3.687a1 1 0 1 0-1.454-1.374l-8.5 9a1 1 0 0 0 0 1.374l8.5 9.001a1 1 0 1 0 1.454-1.373L4.875 12z'
                  />
                </svg>
              </div>
              <p className='font-bold ml-3'>{t('Violation Details')}</p>
            </div>
            <Button
              label={t('Add processing')}
              severity='info'
              onClick={() => {
                setVisible(true);
              }}
              style={{
                backgroundColor: '#4880ff',
                padding: '8px 24px',
                border: 'none',
              }}
            />
          </div>
        </div>
        <div className='px-6 py-3 ml-5'>
          <div className='grid line-height-4'>
            <div className='col-5'>
              <p className='text-400 font-bold'>{t('Violation Title')}</p>
              <p className=' font-semibold'>
                {dataById?.violate_type_info?.name || t('No data')}
              </p>
            </div>
            <div className='col-5 '>
              <p className='text-400 font-bold'>{t('Location')}</p>
              <p className='font-semibold'>
                {dataById?.violate_address || t('No data')}
              </p>
            </div>
            <div
              className='col-2'
              style={{ marginLeft: '-24px' }}>
              <p className='text-400 font-bold'>{t('Time')}</p>
              <p className='font-semibold'>
                {dataById?.violate_date
                  ? dateTemplate(dataById.violate_date)
                  : t('No data')}
              </p>
            </div>
          </div>
          {dataById && <ListIntern dataById={dataById} />}
          {dataById && <HistoryViolate dataById={dataById} />}
          {visible && (
            <ModelAddViolate
              dataById={dataById}
              toast={toast}
              visible={visible}
              onHide={() => {
                setVisible(false);
              }}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default ViolateDetail;
