export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL";

export const GET_NEW_INTERN = "GET_NEW_INTERN";
export const GET_NEW_INTERN_SUCCESS = "GET_NEW_INTERN_SUCCESS";
export const GET_NEW_INTERN_FAIL = "GET_NEW_INTERN_FAIL";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";
