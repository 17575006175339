import React, { useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Image } from 'primereact/image';
import { InputSwitch } from 'primereact/inputswitch';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setDataNull, setSyndication } from '../../store/syndication/actions';
import AddressDetail from '../../components/AddressDetail';
import { ObjectType } from '../../components/Interface';
import { getUsernameRandom } from '../../ultis/GetRandomCode';

const ModelAddSyndication = ({ visible, onHide, toast }) => {
  const [listAddress, setListAddress] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const avata = './images/avatas/logo-syndication-null.svg';
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAvata, setShowAvata] = useState(avata);
  const [isLogin, setIsLogin] = useState(false);

  const callBackData = (data) => {
    setListAddress(data);
  };

  // xử lý call redux
  const { errorInsert, dataInsert } = useSelector(
    (state) => ({
      errorInsert: state.Syndication.errorInsert,
      dataInsert: state.Syndication.dataInsert,
    }),
    shallowEqual
  );

  // thêm address
  const getListAddress = (data) => {
    setListAddress(data);
  };

  // xử lý file up ảnh
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setShowAvata(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // tao schema khi khong tao tk
  const withoutAccountSchema = Yup.object().shape({
    name_jp: Yup.string().required(t('This value is required')),
    name_en: Yup.string().required(t('This value is required')),
  });
  const withAccountSchema = Yup.object().shape({
    name_jp: Yup.string().required(t('This value is required')),
    name_en: Yup.string().required(t('This value is required')),
    password: Yup.string()
      .min(6, t('Password must be at least 6 characters long'))
      .required(t('This value is required')),
    username: Yup.string().required(t('This value is required')),
  });

  const getValidationSchema = () => {
    if (isLogin) {
      return withAccountSchema;
    } else {
      return withoutAccountSchema;
    }
  };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      avata: 'avatar-null.png',
      name_jp: '',
      name_en: '',
      code: '',
      description: '',
      username: getUsernameRandom(),
      password: process.env.REACT_APP_PASSWORD_DEFAULT,
    },
    validationSchema: getValidationSchema(),
    onSubmit: async (value) => {
      const data = { ...value, is_login: isLogin, addresses: listAddress };
      dispatch(setSyndication(data, selectedFile));
    },
  });

  useEffect(() => {
    if (errorInsert) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorInsert.mes,
        life: 5000,
      });
      dispatch(setDataNull());
    }
    if (dataInsert) {
      toast.current?.show({
        severity: 'success',
        summary: 'success',
        detail: 'Add syndication Success',
        life: 5000,
      });
      formik.resetForm();
      dispatch(setDataNull());
      setShowAvata(avata);
      onHide();
    }
    // eslint-disable-next-line
  }, [errorInsert, dataInsert]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        setShowAvata(avata);
        onHide();
      }}
      header={t('Add Syndication')}
      modal
      style={{ width: '90vw', height: '90vh' }}
      footer={
        <div>
          <Button
            label={t('Cancel')}
            onClick={() => {
              formik.resetForm();
              setShowAvata(avata);
              onHide();
            }}
            severity='secondary'
            className='p-button-text '
          />
          <Button
            type='submit'
            label={t('Save')}
            onClick={formik.handleSubmit}
          />
        </div>
      }
      pt={{
        content: 'surface-100 pt-3',
        footer: 'surface-100',
        header: 'py-3 pl-4',
      }}>
      <div className='flex flex-row gap-5 mb-5'>
        <div className='bg-white w-2 p-3 border-round-md'>
          <div className='flex justify-content-center	mb-3 border-round-md'>
            <div>
              <Image
                src={showAvata}
                alt='Image'
                width='93'
                height='93'
              />
              <div className='my-2'>
                <Button
                  name='icon'
                  type='file'
                  label={t('Upload logo')}
                  size='small'
                  severity='secondary'
                  outlined
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                />
                <input
                  onChange={handleFileChange}
                  multiple={false}
                  ref={fileInputRef}
                  type='file'
                  accept='image/*'
                  hidden
                  name='icon'
                  id='icon'
                />
              </div>
            </div>
          </div>
          <div>
            <div className='flex align-items-center mb-3'>
              <InputSwitch
                checked={isLogin}
                onChange={(e) => setIsLogin(e.value)}
              />
              <label className='font-bold ml-2'>{t('Allow login')}</label>
            </div>
            {isLogin && (
              <div>
                <InputText
                  name='username'
                  placeholder='Tên đăng nhập'
                  className='w-full mb-2'
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  invalid={
                    formik.touched.username && formik.errors.username
                      ? true
                      : false
                  }
                />
                <InputText
                  name='password'
                  placeholder='Mật khẩu'
                  className='w-full'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  invalid={
                    formik.touched.password && formik.errors.password
                      ? true
                      : false
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className='bg-white w-10 p-3 border-round-md'>
          <div className='flex flex-row mb-4 gap-3'>
            <div className='flex flex-column w-6'>
              <label className='mb-2'>{t('Syndication')}</label>
              <InputText
                id='name_jp'
                name='name_jp'
                value={formik.values.name_jp}
                onChange={formik.handleChange}
                invalid={
                  formik.touched.name_jp && formik.errors.name_jp ? true : false
                }
              />
              {formik.touched.name_jp && formik.errors.name_jp ? (
                <div style={{ color: 'red' }}>
                  {formik.errors.name_jp.toString()}
                </div>
              ) : null}
            </div>
            <div className='flex flex-column w-6'>
              <label className='mb-2'>{t('Syndication name (English)')}</label>
              <InputText
                id='name_en'
                name='name_en'
                value={formik.values.name_en}
                onChange={formik.handleChange}
                invalid={
                  formik.touched.name_en && formik.errors.name_en ? true : false
                }
              />
            </div>
          </div>
          <div className='flex flex-column mb-2'>
            <label className='mb-2'>{t('Syndication code')}</label>
            <InputText
              id='code'
              name='code'
              value={formik.values.code}
              onChange={formik.handleChange}
            />
          </div>
          <div className='flex flex-column mb-2'>
            <label className='mb-2'>{t('Description')}</label>
            <InputTextarea
              id='description'
              name='description'
              rows={3}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </div>
      {/* <AddressManage getListAddress={getListAddress} country_id={2} /> */}
      <AddressDetail
        object_id={null}
        object_type={ObjectType.SYNDICATION}
        callBackData={callBackData}
      />
    </Dialog>
  );
};

export default ModelAddSyndication;
