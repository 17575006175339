import { takeEvery, takeLatest, put, call } from "redux-saga/effects";
import {
  GET_VIOLATE_BY_ID,
  GET_VIOLATE_BY_USER_ID,
  SET_VIOLATE,
  UPDATE_VIOLATE,
  DELETE_VIOLATE,
  HANDLE_VIOLATE,
} from "./actionTypes";
import {
  getViolateByIdSuccess,
  getViolateByIdFail,
  getViolateByUserIdSuccess,
  getViolateByUserIdFail,
  setViolateSuccess,
  setViolateFail,
  updateViolateSuccess,
  updateViolateFail,
  deleteViolateSuccess,
  deleteViolateFail,
  handleViolateSuccess,
  handleViolateFail,
} from "./actions";

import {
  getViolateDataById,
  getViolateDataByUserId,
  deleteDataViolate,
} from "../../api/backend_helper";

import * as URL_API from "../../api/url_helper";
import axios from "axios";

function* fetViolateDataId({ payload: query }) {
  try {
    const response = yield call(getViolateDataById, query);
    yield put(getViolateByIdSuccess(response));
  } catch (error) {
    yield put(getViolateByIdFail(error.response.data));
  }
}

function* fetViolateDataByUserId({ payload: query }) {
  try {
    const response = yield call(getViolateDataByUserId, query);
    yield put(getViolateByUserIdSuccess(response));
  } catch (error) {
    yield put(getViolateByUserIdFail(error.response.data));
  }
}

function* onAddNewViolate(action) {
  try {
    const values = action.payload;
    const files = action.files;
    const formData = new FormData();

    formData.append("violate_date", values.violate_date);
    formData.append("violate_type_id", values.violate_type_id);
    formData.append("violate_address", values.violate_address);
    formData.append("description", values.description);
    formData.append("violate_status", values.violate_status);
    formData.append("list_intern", JSON.stringify(values.list_intern));

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }

    const response = yield call(
      axios.post,
      URL_API.SET_VIOLATE,
      formData,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };

    // const response = yield call(addNewDataViolate, formData);
    console.log("dataUpdate", dataUpdate);
    yield put(setViolateSuccess(dataUpdate));
  } catch (error) {
    yield put(setViolateFail(error.response.data));
  }
}

function* onHandleViolate(action) {
  try {
    const values = action.payload;
    const files = action.files;
    const formData = new FormData();

    formData.append("violate_list_id", values.violate_list_id);
    formData.append("handler", values.handler);
    formData.append("handle_date", values.handle_date);
    formData.append("handle_content", values.handle_content);
    formData.append("description", values.description);
    formData.append("violate_status", values.violate_status);

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }

    const response = yield call(
      axios.post,
      URL_API.HANDLE_VIOLATE,
      formData,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const dataUpdate = response.data;
    yield put(handleViolateSuccess(dataUpdate));
  } catch (error) {
    yield put(handleViolateFail(error.response.data));
  }
}

function* onUpdateViolate(action) {
  try {
    const values = action.payload;
    const files = action.files;
    const formData = new FormData();

    formData.append("id", values.id);
    formData.append("violate_date", values.violate_date);
    formData.append("violate_type_id", values.violate_type_id);
    formData.append("violate_address", values.violate_address);
    formData.append("description", values.description);
    formData.append("violate_status", values.violate_status);
    formData.append("list_intern", JSON.stringify(values.list_intern));

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }

    const response = yield call(
      axios.put,
      URL_API.UPDATE_VIOLATE,
      formData,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };

    // const response = yield call(updateDataViolate, data);
    yield put(updateViolateSuccess(dataUpdate));
  } catch (error) {
    yield put(updateViolateFail(error.response.data));
  }
}

function* onDeleteViolate({ payload: id }) {
  try {
    const response = yield call(deleteDataViolate, id);
    yield put(deleteViolateSuccess(response));
  } catch (error) {
    yield put(deleteViolateFail(error.response.data));
  }
}

function* ViolateSaga() {
  yield takeEvery(GET_VIOLATE_BY_ID, fetViolateDataId);
  yield takeEvery(GET_VIOLATE_BY_USER_ID, fetViolateDataByUserId);
  yield takeLatest(SET_VIOLATE, onAddNewViolate);
  yield takeLatest(UPDATE_VIOLATE, onUpdateViolate);
  yield takeEvery(DELETE_VIOLATE, onDeleteViolate);
  yield takeLatest(HANDLE_VIOLATE, onHandleViolate);
}

export default ViolateSaga;
