import React, { useState, useEffect, useRef } from 'react';
import './ViolateDetail.scss';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import UploadFile from '../../components/UploadFile';
import { handleViolate, setDataNull } from '../../store/violate/actions';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { useFormik } from 'formik';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { ViolateStatus } from '../../components/Interface';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function ModelAddViolate({ visible, onHide, dataById }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [clearFiles, setClearFiles] = useState(false);
  const dispatch = useDispatch();
  const toast = useRef(null);

  const { t } = useTranslation();

  const { dataHandle, errorHandle } = useSelector(
    (state) => ({
      dataHandle: state.Violate.dataHandle,
      errorHandle: state.Violate.errorHandle,
    }),
    shallowEqual
  );

  const onUpload = () => {};

  const getFiles = (files) => {
    setSelectedFiles(files);
  };

  const validationSchema = Yup.object().shape({
    violate_status: Yup.string().required('Trạng thái là bắt buộc'),
    handle_date: Yup.date()
      .max(new Date(), 'Violate list date must be before current date')
      .required('Violate list date is required'),
    handler: Yup.string().required('Vui lòng nhập người xử lý'),
    handle_content: Yup.string().required('Vui lòng nhập nội dung xử lý'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      violate_list_id: dataById.id,
      violate_status: dataById.violate_status || '',
      handler: '',
      handle_date: '',
      handle_content: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (dataById.id) {
        const dataHandle = {
          ...values,
          violate_list_id: dataById.id,
          handle_date: moment(values.handle_date).format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch(handleViolate(dataHandle, Array.from(selectedFiles)));
      } else {
        console.log('error');
      }
    },
  });

  useEffect(() => {
    if (errorHandle) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error Message',
        detail: errorHandle.message,
        life: 5000,
      });
      dispatch(setDataNull());
    }
    if (dataHandle) {
      toast.current?.show({
        severity: 'success',
        summary: 'Success Message',
        detail: 'Thêm thành công',
        life: 5000,
      });
      dispatch(setDataNull());
      onHide();
      formik.resetForm();
      setClearFiles(!clearFiles);
    }
  }, [errorHandle, dataHandle, dataById]);
  console.log('dataById', dataById);

  const header = (
    <p className='text-xl font-bold mb-4 ml-4'>
      {t('Add violation handling process')}
    </p>
  );

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        onHide();
      }}
      style={{ width: '88%', height: '90%' }}
      header={header}>
      <form
        onSubmit={formik.handleSubmit}
        className='px-4'>
        <div className='custom-form-grid pt-5 pb-5'>
          <div className='form-field'>
            <label htmlFor='violate_status'>{t('Status')}</label>
            <Dropdown
              options={Object.values(ViolateStatus)}
              id='violate_status'
              className='text-base text-color surface-overlay border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full'
              value={formik.values.violate_status}
              onChange={(e) => formik.setFieldValue('violate_status', e.value)}
            />
            {formik.touched.violate_status && formik.errors.violate_status ? (
              <small className='p-error'>{formik.errors.violate_status}</small>
            ) : null}
          </div>
          <div className='form-field'>
            <label htmlFor='handler'>{t('Handler')}</label>
            <InputText
              id='handler'
              type='text'
              className={`text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full ${
                formik.touched.handler && formik.errors.handler
                  ? 'p-invalid'
                  : ''
              }`}
              value={formik.values.handler}
              onChange={formik.handleChange}
              invalid={formik.touched.handler && formik.errors.handler}
            />
            {formik.touched.handler && formik.errors.handler ? (
              <small className='p-error'>{formik.errors.handler}</small>
            ) : null}
          </div>
          <div className='form-field'>
            <label htmlFor='handle_date'>{t('Handling date')}</label>
            <Calendar
              id='handle_date'
              className={`text-base text-color surface-overlay border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full ${
                formik.touched.handle_date && formik.errors.handle_date
                  ? 'p-invalid'
                  : ''
              }`}
              value={formik.values.handle_date}
              onChange={(e) => formik.setFieldValue('handle_date', e.value)}
              dateFormat='yy/mm/dd'
              showIcon
              invalid={formik.touched.handle_date && formik.errors.handle_date}
            />
            {formik.touched.handle_date && formik.errors.handle_date ? (
              <small className='p-error'>{formik.errors.handle_date}</small>
            ) : null}
          </div>
        </div>
        <div className='field'>
          <label htmlFor='handle_content'>{t('Content')}</label>
          <textarea
            id='handle_content'
            rows='14'
            className='text-base text-color surface-overlay p-3 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full'
            placeholder='Nội dung xử lý'
            value={formik.values.handle_content}
            onChange={formik.handleChange}
            invalid={
              formik.touched.handle_content && formik.errors.handle_content
            }></textarea>
          {formik.touched.handle_content && formik.errors.handle_content ? (
            <small className='p-error'>{formik.errors.handle_content}</small>
          ) : null}
        </div>
        <div>
          <UploadFile
            onUpload={onUpload}
            getFiles={getFiles}
            clearFiles={clearFiles}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '40px',
            gap: '1rem',
          }}>
          <div className='card'>
            <Button
              label={t('Cancel')}
              className='px-6 py-2 bg-white border-none text-400'
              severity='secondary'
              onClick={() => {
                onHide();
                formik.resetForm();
              }}
            />
          </div>
          <div className='card'>
            <Button
              label={t('Save')}
              type='submit'
              className='px-6 py-2'
              severity='info'
              style={{ backgroundColor: '#059669' }}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
}

export default ModelAddViolate;
