import {
  GET_CAREER_BY_ID,
  GET_CAREER_BY_ID_SUCCESS,
  GET_CAREER_BY_ID_FAIL,
  GET_CAREER_BY_USER_ID,
  GET_CAREER_BY_USER_ID_SUCCESS,
  GET_CAREER_BY_USER_ID_FAIL,
  SET_CAREER,
  SET_CAREER_SUCCESS,
  SET_CAREER_FAIL,
  UPDATE_CAREER,
  UPDATE_CAREER_SUCCESS,
  UPDATE_CAREER_FAIL,
  DELETE_CAREER,
  DELETE_CAREER_SUCCESS,
  DELETE_CAREER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  dataById: null,
  dataByUserId: null,
  dataInsert: null,
  dataUpdate: null,
  dataDelete: null,
  error: null,
  loading: false,
  loadingInsert: false,
  loadingUpdate: false,
  loadingDelete: false,
}

const Career = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAREER_BY_ID:
      return {
        ...state,
        loading: true
      }
    case GET_CAREER_BY_ID_SUCCESS:
      return {
        ...state,
        dataById: action.payload,
        loading: false
      }
    case GET_CAREER_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_CAREER_BY_USER_ID:
      return {
        ...state,
        loading: true
      }
    case GET_CAREER_BY_USER_ID_SUCCESS:
      return {
        ...state,
        dataByUserId: action.payload,
        loading: false
      }
    case GET_CAREER_BY_USER_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case SET_CAREER:
      return {
        ...state,
        data: action.payload,
        loadingInsert: true
      }
    case SET_CAREER_SUCCESS:
      return {
        ...state,
        dataInsert: action.payload,
        error: null,
        loadingInsert: false
      }

    case SET_CAREER_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingInsert: false
      }
    case UPDATE_CAREER:
      return {
        ...state,
        loadingUpdate: true,
        data: action.payload,
      }
    case UPDATE_CAREER_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        dataUpdate: action.payload,
      }
    case UPDATE_CAREER_FAIL:
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload,
      }
    case DELETE_CAREER:
      return {
        ...state,
        dataDelete: action.payload,
        loadingDelete: true
      }
    case DELETE_CAREER_SUCCESS:
      return {
        ...state,
        dataDelete: action.payload,
        loadingDelete: false
      }
    case DELETE_CAREER_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingDelete: false
      }

    default:
      return state
  }
}

export default Career
