import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import {
  GET_DISPATCHING_COMPANY_BY_ID,
  GET_DISPATCHING_COMPANY_BY_USER_ID,
  GET_DISPATCHING_COMPANY_BY_KEY_ID,
  SET_DISPATCHING_COMPANY,
  UPDATE_DISPATCHING_COMPANY,
  DELETE_DISPATCHING_COMPANY,
  GET_DISPATCHING_COMPANY_LIST,
} from "./actionTypes";
import {
  getDispatchingCompanyByIdSuccess,
  getDispatchingCompanyByIdFail,
  getDispatchingCompanyByUserIdSuccess,
  getDispatchingCompanyByUserIdFail,
  getDispatchingCompanyByKeyIdSuccess,
  getDispatchingCompanyByKeyIdFail,
  setDispatchingCompanySuccess,
  setDispatchingCompanyFail,
  updateDispatchingCompanySuccess,
  updateDispatchingCompanyFail,
  deleteDispatchingCompanySuccess,
  deleteDispatchingCompanyFail,
  getDispatchingCompanyGetListSuccess,
  getDispatchingCompanyGetListFail,
} from "./actions";

import {
  getDispatchingCompanyDataById,
  getDispatchingCompanyDataByUserId,
  getDispatchingCompanyDataByKeyId,
  deleteDataDispatchingCompany,
  getDispatchingCompanyDataList,
} from "../../api/backend_helper";
import axios from "axios";
import * as URL_API from "../../api/url_helper";

function* fetDispatchingCompanyDataId({ payload: id }) {
  try {
    const response = yield call(getDispatchingCompanyDataById, id);
    yield put(getDispatchingCompanyByIdSuccess(response));
  } catch (error) {
    yield put(getDispatchingCompanyByIdFail(error.response.data));
  }
}

function* fetDispatchingCompanyDataByUserId() {
  try {
    const response = yield call(getDispatchingCompanyDataByUserId);
    yield put(getDispatchingCompanyByUserIdSuccess(response));
  } catch (error) {
    yield put(getDispatchingCompanyByUserIdFail(error.response.data));
  }
}
function* fetDispatchingCompanyDataByKeyId() {
  try {
    const response = yield call(getDispatchingCompanyDataByKeyId);
    yield put(getDispatchingCompanyByKeyIdSuccess(response));
  } catch (error) {
    yield put(getDispatchingCompanyByKeyIdFail(error.response.data));
  }
}

// list
function* fetDispatchingCompanyDataGetList() {
  try {
    const response = yield call(getDispatchingCompanyDataList);
    yield put(getDispatchingCompanyGetListSuccess(response));
  } catch (error) {
    yield put(getDispatchingCompanyGetListFail(error.response.data));
  }
}

function* onAddNewDispatchingCompany(action) {
  try {
    const { values, file } = action.payload;

    const dispatching_company = new FormData();
    dispatching_company.append("name_jp", values.name_jp);
    dispatching_company.append("name_en", values.name_en);
    dispatching_company.append("description", values.description);
    dispatching_company.append("addresses", JSON.stringify(values.addresses));
    dispatching_company.append("country", values.country);
    dispatching_company.append(
      "date_of_joining_syndication",
      values.date_of_joining_syndication
    );
    dispatching_company.append("username", values.username);
    dispatching_company.append("password", values.password);
    dispatching_company.append("is_login", values.is_login);

    if (file) {
      dispatching_company.append("file", file);
    }

    const response = yield call(
      axios.post,
      URL_API.SET_DISPATCHING_COMPANY,
      dispatching_company,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // Convert Axios headers to a plain object
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };

    yield put(setDispatchingCompanySuccess(dataUpdate));
  } catch (error) {
    yield put(setDispatchingCompanyFail(error.response.data));
  }
}

function* onUpdateDispatchingCompany(action) {
  try {
    const { values, file } = action.payload;

    const dispatching_company = new FormData();
    dispatching_company.append("id", values.id);
    dispatching_company.append("name_jp", values.name_jp);
    dispatching_company.append("name_en", values.name_en);
    dispatching_company.append("description", values.description);
    dispatching_company.append("addresses", JSON.stringify(values.addresses));
    dispatching_company.append("country", values.country);
    dispatching_company.append(
      "date_of_joining_syndication",
      values.date_of_joining_syndication
    );
    dispatching_company.append("username", values.username);
    dispatching_company.append("password", values.password);
    dispatching_company.append("is_login", values.is_login);
    dispatching_company.append("type", values.type);

    if (file) {
      dispatching_company.append("file", file);
    }

    const response = yield call(
      axios.put,
      URL_API.UPDATE_DISPATCHING_COMPANY,
      dispatching_company,
      { withCredentials: true },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // Convert Axios headers to a plain object
    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };
    yield put(updateDispatchingCompanySuccess(dataUpdate));
  } catch (error) {
    yield put(updateDispatchingCompanyFail(error.response.data));
  }
}

function* onDeleteDispatchingCompany({ payload: id }) {
  try {
    const response = yield call(deleteDataDispatchingCompany, id);
    yield put(deleteDispatchingCompanySuccess(response));
  } catch (error) {
    yield put(deleteDispatchingCompanyFail(error.response.data));
  }
}

function* DispatchingCompanySaga() {
  yield takeEvery(GET_DISPATCHING_COMPANY_BY_ID, fetDispatchingCompanyDataId);
  yield takeEvery(
    GET_DISPATCHING_COMPANY_BY_USER_ID,
    fetDispatchingCompanyDataByUserId
  );
  yield takeEvery(
    GET_DISPATCHING_COMPANY_BY_KEY_ID,
    fetDispatchingCompanyDataByKeyId
  );
  yield takeEvery(
    GET_DISPATCHING_COMPANY_LIST,
    fetDispatchingCompanyDataGetList
  );
  yield takeLatest(SET_DISPATCHING_COMPANY, onAddNewDispatchingCompany);
  yield takeEvery(UPDATE_DISPATCHING_COMPANY, onUpdateDispatchingCompany);
  yield takeEvery(DELETE_DISPATCHING_COMPANY, onDeleteDispatchingCompany);
}

export default DispatchingCompanySaga;
