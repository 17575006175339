import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './Dashboard.scss';
import { getColorForCountry } from './colors';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const DonutChart = ({ dataIntern }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  if (!dataIntern || !dataIntern.countries) {
    return <div>{t('No data available')}</div>;
  }

  const data = Object.keys(dataIntern?.totalAllInternsCountries).map(
    (country) => ({
      name: t(country),
      value: dataIntern?.totalAllInternsCountries[country],
      color: getColorForCountry(country),
    })
  );

  const options = {
    borderWidth: 0,
    legend: {
      show: false,
    },
    series: data.map((e) => e.value),
    colors: data.map((e) => e.color),
    dataLabels: { enabled: false },
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          labels: {
            show: false,
            total: {
              showAlways: false,
              show: false,
            },
          },
        },
        startAngle: 0,
        endAngle: 360,
      },
    },
    labels: data.map((e) => e.name),
  };

  return (
    <div className='p-3'>
      <div className='flex justify-content-between mb-3'>
        <div className='flex gap-2'>
          <p className='text-xl font-bold'>{t('Total Intern')}:</p>
          <p className='text-xl font-bold'>{dataIntern.totalAllInterns}</p>
        </div>
        <a
          onClick={() => {
            navigate('/intern', { state: { sumary: 1 } });
          }}
          style={{ color: '#0C4DA2', cursor: 'pointer' }}>
          {t('View All')}
        </a>
      </div>
      <ReactApexChart
        options={options}
        series={options.series}
        type='donut'
        height={'200px'}
      />
      <div className='w-4 m-auto'>
        {data &&
          data.map((item) => (
            <div
              key={item.name}
              className='flex align-items-center'
              style={{ alignItems: 'center' }}>
              <div
                className='dot'
                style={{ backgroundColor: `${item.color}` }}></div>
              <p className='col-8'>{item.name}</p>
              <p className='col-3 text-center'>{item.value}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DonutChart;
