import { del, get, post, put, getAddress } from "./api_helper";
import * as url from "./url_helper";

let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

let config_upload_file = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

// nation
export const getCountryDataAll = () => get(url.GET_COUNTRY_ALL, config);
export const getCountryDataId = (id) =>
  get(`${url.GET_COUNTRY_ID}/${id}`, { params: { id } }, config);
export const addNewDataCountry = (data) => post(url.SET_COUNTRY, data, config);
export const updateDataCountry = (data) =>
  put(url.UPDATE_COUNTRY, data, config);
export const deleteDataCountry = (id) =>
  del(`${url.DELETE_COUNTRY}/${id}`, { params: { id } }, config);

// state
export const getStateDataAll = () => getAddress(url.GET_STATE_ALL);
export const getStateDataId = (id) =>
  getAddress(`${url.GET_STATE_ID}/${id}`, config);
export const getStateByCountryDataId = (id) =>
  getAddress(`${url.GET_STATE_BY_COUNTRY_ID}/${id}`, config);
export const addNewDataState = (data) => post(url.SET_STATE, data, config);
export const updateDataState = (data) => put(url.UPDATE_STATE, data, config);
export const deleteDataState = (id) =>
  del(`${url.DELETE_STATE}/${id}`, { params: { id } }, config);

// district
export const getDistrictDataAll = () => get(url.GET_DISTRICT_ALL);
export const getDistrictDataId = (id) =>
  get(`${url.GET_DISTRICT_ID}/${id}`, { params: { id } }, config);
export const getDistrictByStateDataId = (id) =>
  get(`${url.GET_DISTRICT_BY_STATE_ID}/${id}`, { params: { id } }, config);
export const addNewDataDistrict = (data) =>
  post(url.SET_DISTRICT, data, config);
export const updateDataDistrict = (data) =>
  put(url.UPDATE_DISTRICT, data, config);
export const deleteDataDistrict = (id) =>
  del(`${url.DELETE_DISTRICT}/${id}`, { params: { id } }, config);

// commune
export const getWardDataAll = () => get(url.GET_WARD_ALL);
export const getWardDataId = (id) =>
  get(`${url.GET_WARD_ID}/${id}`, { params: { id } }, config);
export const getWardByDistrictDataId = (id) =>
  get(`${url.GET_WARD_BY_DISTRICT_ID}/${id}`, { params: { id } }, config);
export const addNewDataWard = (data) => post(url.SET_WARD, data, config);
export const updateDataWard = (data) => put(url.UPDATE_WARD, data, config);
export const deleteDataWard = (id) =>
  del(`${url.DELETE_WARD}/${id}`, { params: { id } }, config);

// career
export const getCareerDataById = (id) =>
  get(`${url.GET_CAREER_BY_ID}/${id}`, config);
export const getCareerDataByUserId = () =>
  get(`${url.GET_CAREER_BY_USER_ID}`, config);
export const addNewDataCareer = (data) => post(url.SET_CAREER, data, config);
export const updateDataCareer = (data) => put(url.UPDATE_CAREER, data, config);
export const deleteDataCareer = (id) =>
  del(`${url.DELETE_CAREER}/${id}`, config);

// users
export const login = (data) => post(url.LOGIN, data, config);
export const getUserDataById = (id) =>
  get(`${url.GET_USER_BY_ID}/${id}`, config);
export const getUserDataByUserId = () =>
  get(`${url.GET_USER_BY_USER_ID}`, config);
export const registerAdmin = (data) => post(url.REGISTER_ADMIN, data, config);
export const registerUser = (data) => post(url.REGISTER_USER, data, config);
export const verifyKey = (data) => post(url.VERIFY_KEY, data, config);
export const verifyKeyLicense = (data) =>
  post(url.VERIFY_KEY_LICENSE, data, config);
export const verifyEmail = (data) => post(url.VERIFY_EMAIL, data, config);
export const changePassword = (data) => put(url.CHANGE_PASSWORD, data, config);
export const deleteDataUser = (id) => del(`${url.DELETE_USER}/${id}`, config);
export const getInfor = () => get(url.GET_INFOR, config);

// status
export const getStatusDataById = (id) =>
  get(`${url.GET_STATUS_BY_ID}/${id}`, config);
export const getStatusDataByUserId = () =>
  get(`${url.GET_STATUS_BY_USER_ID}`, config);
export const addNewDataStatus = (data) => post(url.SET_STATUS, data, config);
export const updateDataStatus = (data) => put(url.UPDATE_STATUS, data, config);
export const deleteDataStatus = (id) =>
  del(`${url.DELETE_STATUS}/${id}`, config);

// violate_type
export const getViolateTypeDataById = (id) =>
  get(`${url.GET_VIOLATE_TYPE_BY_ID}/${id}`, config);
export const getViolateTypeDataByUserId = () =>
  get(`${url.GET_VIOLATE_TYPE_BY_USER_ID}`, config);
export const addNewDataViolateType = (data) =>
  post(url.SET_VIOLATE_TYPE, data, config);
export const updateDataViolateType = (data) =>
  put(url.UPDATE_VIOLATE_TYPE, data, config);
export const deleteDataViolateType = (id) =>
  del(`${url.DELETE_VIOLATE_TYPE}/${id}`, config);

// syndication
export const getSyndicationDataById = (id) =>
  get(`${url.GET_SYNDICATION_BY_ID}/${id}`, config);
export const getSyndicationDataByUserId = () =>
  get(`${url.GET_SYNDICATION_BY_USER_ID}`, config);
export const getSyndicationDataByKeyId = () =>
  get(`${url.GET_SYNDICATION_BY_KEY_ID}`, config);
export const addNewDataSyndication = (data) =>
  post(url.SET_SYNDICATION, data, config_upload_file);
export const updateDataSyndication = (data) =>
  put(url.UPDATE_SYNDICATION, data, config);
export const deleteDataSyndication = (id) =>
  del(`${url.DELETE_SYNDICATION}/${id}`, config);
export const getSyndicationDataList = () =>
  get(`${url.GET_LIST_SYNDICATION}`, config);

// receving_factory
export const getReceivingFactoryDataById = (id) =>
  get(`${url.GET_RECEIVING_FACTORY_BY_ID}/${id}`, config);
export const getReceivingFactoryDataByUserId = () =>
  get(`${url.GET_RECEIVING_FACTORY_BY_USER_ID}`, config);
export const getReceivingFactoryDataByKeyId = () =>
  get(`${url.GET_RECEIVING_FACTORY_BY_KEY_ID}`, config);
export const addNewDataReceivingFactory = (data) =>
  post(url.SET_RECEIVING_FACTORY, data, config_upload_file);
export const updateDataReceivingFactory = (data) =>
  put(url.UPDATE_RECEIVING_FACTORY, data, config);
export const deleteDataReceivingFactory = (id) =>
  del(`${url.DELETE_RECEIVING_FACTORY}/${id}`, config);
export const getReceivingFactoryDataList = () =>
  get(`${url.GET_LIST_RECEIVING_FACTORY}`, config);

// dispatching_company
export const getDispatchingCompanyDataById = (id) =>
  get(`${url.GET_DISPATCHING_COMPANY_BY_ID}/${id}`, config);
export const getDispatchingCompanyDataByUserId = () =>
  get(`${url.GET_DISPATCHING_COMPANY_BY_USER_ID}`, config);
export const getDispatchingCompanyDataByKeyId = () =>
  get(`${url.GET_DISPATCHING_COMPANY_BY_KEY_ID}`, config);
export const addNewDataDispatchingCompany = (data) =>
  post(url.SET_DISPATCHING_COMPANY, data, config_upload_file);
export const updateDataDispatchingCompany = (data) =>
  put(url.UPDATE_DISPATCHING_COMPANY, data, config);
export const deleteDataDispatchingCompany = (id) =>
  del(`${url.DELETE_DISPATCHING_COMPANY}/${id}`, config);
export const getDispatchingCompanyDataList = () =>
  get(`${url.GET_LIST_DISPATCHING_COMPANY}`, config);

// intern
export const getInternById = (id) =>
  get(`${url.GET_INTERN_BY_ID}/${id}`, config);
export const getInternDataByUserId = (data) =>
  get(url.GET_INTERN_BY_USER_ID,{params: data}, config);
export const getInternDataByKeyId = () =>
  get(`${url.GET_INTERN_BY_KEY_ID}`, config);
export const addNewDataIntern = (data) =>
  post(url.SET_INTERN, data, config_upload_file);
export const updateDataIntern = (data) => put(url.UPDATE_INTERN, data, config);
export const deleteDataIntern = (id) =>
  del(`${url.DELETE_INTERN}/${id}`, config);
export const searchIntern = (
  s_country,
  s_receiving_factory_id,
  s_dispatching_company_id,
  s_syndication_id,
  s_status_of_residence,
  s_ages
) =>
  get(
    `${url.SEARCH_INTERN}`,
    {
      params: {
        s_country,
        s_receiving_factory_id,
        s_dispatching_company_id,
        s_syndication_id,
        s_status_of_residence,
        s_ages,
      },
    },
    config
  );

export const getTimeLine = (query) =>
  get(`${url.GET_TIME_LINE}`, { params: query }, config);

export const getListCard = (query) =>
  get(`${url.GET_LIST_CARD}`, { params: query }, config);

// address
export const getBranch = (data) => post(url.GET_BRANCH, data, config);
export const getBranchFull = (data) => post(url.GET_BRANCH_FULL, data, config);

// violate
export const getViolateDataById = (query) =>
  get(`${url.GET_VIOLATE_BY_ID}`, { params: query }, config);
export const getViolateDataByUserId = (query) =>
  get(`${url.GET_VIOLATE_BY_USER_ID}`, { params: query }, config);
export const addNewDataViolate = (data) =>
  post(url.SET_VIOLATE, data, config_upload_file);
export const updateDataViolate = (data) =>
  put(url.UPDATE_VIOLATE, data, config);
export const deleteDataViolate = (id) =>
  del(`${url.DELETE_VIOLATE}/${id}`, config);
export const handleViolate = (data) =>
  post(url.HANDLE_VIOLATE, data, config_upload_file);

// ticket

export const getTicketList = (query) =>
  get(`${url.GET_TICKET_LIST}`, { params: query }, config);
export const getTicketDetailList = (query) =>
  get(`${url.GET_TICKET_DETAIL_LIST}`, { params: query }, config);

export const addNewDataTicket = (data) => post(url.SET_TICKET, data, config);
export const updateDataTicket = (data) => put(url.UPDATE_TICKET, data, config);

export const replyTicket = (data) => post(url.SET_REPLY_TICKET, data, config);
export const translateTicket = (data) =>
  post(url.TRANSLATE_TICKET, data, config);
export const getListUser = (key) =>
  get(`${url.GET_LIST_USER}?s_object_type=${key}`, config);

export const closeTicket = (data) => put(url.CLOSE_TICKET, data, config);

//Announced
export const setAnnounced = (data) =>
  post(url.SET_ANNOUNCED, data, config_upload_file);
export const getAnnounced = (query) =>
  get(url.GET_ANNOUNCED, { params: query }, config);
export const getListTarget = (query) =>
  get(url.GET_LIST_TARGET, { params: query }, config);
export const getListInternTarget = (data) =>
  post(url.GET_LIST_INTERN_TARGET, data, config);

//dashboard

export const getDashboardData = () => get(url.GET_DASHBOARD_DATA, config);

export const getNewInterns = (query) =>
  get(`${url.GET_NEW_INTERN}`, { params: query }, config);

export const getNotification = (query) =>
  get(`${url.GET_NOTIFICATION}`, { params: query }, config);
