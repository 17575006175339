import {
  GET_STATE_ALL,
  GET_STATE_ALL_SUCCESS,
  GET_STATE_ALL_FAIL,
  GET_STATE_ID,
  GET_STATE_ID_SUCCESS,
  GET_STATE_ID_FAIL,
  GET_STATE_BY_COUNTRY_ID,
  GET_STATE_BY_COUNTRY_ID_SUCCESS,
  GET_STATE_BY_COUNTRY_ID_FAIL,
  SET_STATE,
  SET_STATE_SUCCESS,
  SET_STATE_FAIL,
  UPDATE_STATE,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAIL,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAIL
  } from "./actionTypes";
  
  const INIT_STATE = {
    datas: [],
    dataId: null,
    dataByCountryId: [],
    data: null,
    loading: false
  };
  
  const State = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_STATE_ALL:
        return {
          ...state,
          loading: true,
        };
      case GET_STATE_ALL_SUCCESS:
        return {
          ...state,
          datas: action.payload,
          loading: false
        };
  
      case GET_STATE_ALL_FAIL:
        return {
          ...state,
          error: action.payload,
        };
      case GET_STATE_ID:
        return {
          ...state,
          loading: true,
        };
      case GET_STATE_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          dataId: action.payload,
        };
      case GET_STATE_ID_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case GET_STATE_BY_COUNTRY_ID:
        return {
          ...state,
          loading: true,
        };
      case GET_STATE_BY_COUNTRY_ID_SUCCESS:
        return {
          ...state,
          dataByCountryId: action.payload,
          loading: false
        };
      case GET_STATE_BY_COUNTRY_ID_FAIL:
        return {
          ...state,
          error: action.payload,
        };
      case SET_STATE:
        return {
          ...state,
          data: action.payload,
        };
      case SET_STATE_SUCCESS:
        return {
          ...state,
          data: action.payload,
        };
  
      case SET_STATE_FAIL:
        return {
          ...state,
          error: action.payload,
        };
      case UPDATE_STATE:
        return {
          ...state,
          data: action.payload,
        };
      case UPDATE_STATE_SUCCESS:
        return {
          ...state,
          data: action.payload,
        };
      case UPDATE_STATE_FAIL:
        return {
          ...state,
          error: action.payload,
        };
      case DELETE_STATE_SUCCESS:
        return {
          ...state,
          data: action.payload,
        };
      case DELETE_STATE_FAIL:
        return {
          ...state,
          error: action.payload,
        };

      default:
        return state;
    }
  };
  
  export default State;