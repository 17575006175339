import React, { useState, useRef, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { TabMenu } from "primereact/tabmenu";
import ModelAddSyndication from "./ModelAddSyndication";
import ModelEditSyndication from "./ModelEditSyndication";
import ActionMenu from "../../components/ActionMenu";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { deleteSyndication, getSyndicationByUserId, setDataNull } from "../../store/syndication/actions";
import { site } from "../../api/url_helper";
import { Avatar } from "primereact/avatar";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import ModelDelete from "../../components/ModalDelete";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import ButtonImport from "../../components/Excel/ButtonImport";
import ImportExcelDialog from "../../components/Excel/ImportExcel";
import axios from "axios";
import { DataContext } from "../../contexts/data/DataProvider";
import DownloadExportExcel from "../../components/Excel/DownloadExportExcel";
import { useLocation } from "react-router-dom";
import { ConvertLinkGoogleDrive } from "../../ultis/ConvertLinkGoogleDrive";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Syndication() {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogEditVisible, setDialogEditVisible] = useState(false);
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [modalDelete, setModalDelete] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [rowData, setRowData] = useState();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [dialogExcelVisible, setDialogExcelVisible] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [loadingExcel, setLoadingExcel] = useState(true);
  const { selectedLang } = useContext(DataContext);

  let query = useQuery();
  // const id = query.get('id');
  // const name = query.get('name');
  const { t } = useTranslation();

  const { dataSyndication, loadingInsert, loadingUpdate, loadingDelete, dataDelete, errorDelete } = useSelector(
    (state) => ({
      dataSyndication: state.Syndication.dataByUserId,
      dataInsert: state.Syndication.dataInsert,
      loadingInsert: state.Syndication.loadingInsert,
      loadingUpdate: state.Syndication.loadingUpdate,
      loadingDelete: state.Syndication.loadingDelete,
      dataDelete: state.Syndication.dataDelete,
      errorDelete: state.Syndication.errorDelete,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getSyndicationByUserId());
  }, [dispatch, loadingInsert, loadingUpdate, loadingDelete]);

  //Sửa dữ liệu
  const handleEdit = (rowData) => {
    setRowData(rowData);
    setDialogEditVisible(true);
  };

  //Xóa dữ liệu
  const handleDelete = (data) => {
    setModalDelete(true);
    setRowData(data);
  };

  // download file mẫu
  useEffect(() => {
    if (dialogExcelVisible) {
      downloadFileHandle();
    }
  }, [dialogExcelVisible]);

  const downloadFileHandle = async () => {
    try {
      const response = await axios.post(
        `${site}/api/users/dashboard/excel/sample`,
        { object_type: "syndication" },
        { withCredentials: true }
      );

      if (response && response.data) {
        setLoadingExcel(false);
        setFileUrl(`${site}/${response.data.file}`);
      }
    } catch (error) {
      console.error("Error while sending POST request:", error);
    }
  };

  // export Excle
  const exportExcelHandle = () => {
    DownloadExportExcel(
      "syndication",
      selectedLang,
      dataTable.map((e) => e.info.id)
    );
  };

  useEffect(() => {
    if (errorDelete) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: errorDelete.mes,
        life: 3000,
      });
      dispatch(setDataNull());
    }
    if (dataDelete) {
      toast.current?.show({
        severity: "success",
        summary: "success",
        detail: "Delete Syndication success",
        life: 3000,
      });
      dispatch(setDataNull());
    }
    // eslint-disable-next-line
  }, [errorDelete, dataDelete]);

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.info.id;

      if (id !== undefined) {
        dispatch(deleteSyndication(id));
      }
      setModalDelete(false);
    }
  };

  //Menu hành động
  const actionBodyTemplate = (rowData) => {
    return <ActionMenu onEdit={() => handleEdit(rowData)} onDelete={() => handleDelete(rowData)} />;
  };

  //Templete tên nghiệp đoàn
  const headerTemplate = (data) => {
    return (
      <div className="surface-100 flex align-items-center justify-content-between py-1">
        <div className="flex align-items-center">
          <Avatar
            image={ConvertLinkGoogleDrive(data.info?.avata)}
            className="flex align-items-center justify-content-center mx-3 my-1"
            shape="circle"
            size="large"
          />
          <div className="flex flex-column">
            <span className="font-bold">{data.info?.name_jp}</span>
            <span className="mt-1 opacity-70">{data.info?.name_en}</span>
          </div>
        </div>
        {actionBodyTemplate(data)}
      </div>
    );
  };

  useEffect(() => {
    const updateDataTable = () => {
      if (dataSyndication) {
        const newData = dataSyndication[tabMenuActive]?.data || [];
        setDataTable(newData);
      }
    };
    updateDataTable();
  }, [tabMenuActive, dataSyndication]);

  // Lọc dữ liệu
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name_jp: { value: null, matchMode: FilterMatchMode.CONTAINS },
    phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    address: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const role = JSON.parse(localStorage.getItem("role"));

  return (
    <div style={{ width: "calc(100vw - 315px)", minWidth: "1200px" }}>
      <Toast ref={toast} />
      <div className="flex flex-row justify-content-between mb-3">
        <Button label={t("Add New")} size="small" onClick={() => setDialogVisible(true)} disabled={!role} />
        <div className="flex flex-row gap-3">
          <IconField iconPosition="left" className="w-6">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              v-model="value1"
              placeholder={t("Search")}
              className="w-full"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
            />
          </IconField>
          <ButtonImport
            onClick={() => {
              setDialogExcelVisible(true);
            }}
            exportClick={exportExcelHandle}
          />
          {dialogExcelVisible && (
            <ImportExcelDialog
              visible={dialogExcelVisible}
              onHide={() => {
                setDialogExcelVisible(false);
              }}
              fileUrl={fileUrl}
              type="syndication"
              loadingExcel={loadingExcel}
            />
          )}
        </div>
      </div>
      <div className="bg-white border-round-md shadow-1 px-3">
        <div>
          <TabMenu
            model={dataSyndication?.map((item, index) => ({
              ...item,
              template: () => tabMenuTemplate(item, index, tabMenuActive, setTabmenuActive),
            }))}
            activeIndex={tabMenuActive}
            onTabChange={(e) => setTabmenuActive(e.index)}
            pt={{ menuitem: "p-0" }}
          />
        </div>
        <div>
          <DataTable
            value={dataTable}
            rowGroupMode="subheader"
            groupRowsBy="info.name_jp"
            rowGroupHeaderTemplate={headerTemplate}
            paginator
            emptyMessage={t("No data available")}
            filters={filters}
            globalFilterFields={["branch", "info.name_en", "info.name_jp", "address", "email", "phone"]}
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            size="small"
          >
            <Column
              header={t("Name")}
              body={(rowData) => (
                <div className="ml-7 py-3">
                  <span className="pi pi-map-marker text-primary mr-2"></span>
                  {rowData.branch}
                </div>
              )}
              style={{ width: "25%" }}
            ></Column>
            <Column field="address" header={t("Address Information")} style={{ width: "40%" }}></Column>
            <Column field="phone" header={t("Phone number")} style={{ width: "15%" }}></Column>
            <Column field="email" header={t("Email")} style={{ width: "15%" }}></Column>
          </DataTable>
        </div>
      </div>
      <ModelAddSyndication visible={dialogVisible} onHide={() => setDialogVisible(false)} toast={toast} />
      <ModelEditSyndication
        visible={dialogEditVisible}
        onHide={() => setDialogEditVisible(false)}
        toast={toast}
        rowData={rowData}
      />
      <ModelDelete visible={modalDelete} setVisible={setModalDelete} accept={acceptDelete} />
    </div>
  );
}

export default Syndication;
